import React, { useEffect, useState } from "react";
import { Button, Alert, Stack, Container, InputGroup, Form, ButtonGroup } from "react-bootstrap";
import Table from "../../Table/Table";
import axios from "axios";
import toast from "react-hot-toast";
import ButtonSure from "../../Helper/ButtonSure";
// import Container from "../../Layout/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import isadmin from "../../../utils/isadmin";

function Dmrids({ dmrids, setDmrids, user }) {
  const [dmrid, setDmrid] = useState();
  const [callsign, setCallsign] = useState();
  const [dmridError, setDmridError] = useState('');
  const [RIC, _setRIC] = useState([]);

  const deleteDMRIDButton = (id) => {
    let deleteDmrid =
      axios.delete(`${process.env.REACT_APP_API}/api/v1/dmrid/${id}.json`)
        .then(setDmrids(dmrids.filter(dmrid => dmrid.id !== id)))
    toast.promise(deleteDmrid, {
      loading: "Deleting...",
      success: "DMRID Deleted",
      error: "Failed to Delete DMRID",
    });
  }

  const sendReminderEmail = (id) => {
    let reminderEmail =
      axios.get(`${process.env.REACT_APP_API}/api/v1/user/${id}/nodmrid_reminder.json`)
        .then(({ data }) => {
          console.log("data", data);
        })
    toast.promise(reminderEmail, {
      loading: "Sending Reminder E-Mail...",
      success: "Reminder E-Mail Sent",
      error: "Could Not Send Reminder E-Mail",
    })
  }


  const createDmridButton = () => {
    if (dmrid && dmrid.length === 7 && callsign) {
      let createDmrid =
        axios.post(`${process.env.REACT_APP_API}/api/v1/dmrids.json`, {
          dmrid: {
            user_id: user.id,
            dmrid: dmrid,
            callsign: callsign,
          }
        })
          .then(({ data }) => {
            if (data) {
              setDmrids([...dmrids, data])
              setDmrid('');
              setCallsign('');
              setDmridError('');
            }
          })
          .catch(error => {
            setDmridError(error.response.data.error)
            return Promise.error(error)
          });
      toast.promise(createDmrid, {
        loading: "Adding DMRID...",
        success: "DMRID Added",
        error: "Could not add DMRID",
      });
    } else {
      if (!dmrid || dmrid.length !== 7) {
        setDmridError('DMRID must be 7 digits')
      }
      if (!callsign) {
        setDmridError('You need to Enter the matching Callsign')
      }
    }
  }

  const gridOptions = {
    getRowId(data) {
      return data.data.id;
    },
    paginationPageSize: 15,
    columnDefs: [
      {
        headerName: "DMRID",
        field: "id",
      }, {
        headerName: "Callsign",
        field: "callsign",
      }, {
        headerName: "RIC",
        field: "ric",
      }, {
        headerName: "Action",
        cellRenderer: function (params) {
          return (<ButtonSure variant="danger"
            onClick={() => deleteDMRIDButton(params.data.id)}
            actionName={<FontAwesomeIcon fixedWidth icon={faTrash} />}
            tooltip="Remove DMRID"
          >
            <p>Deleting this DMRID will also delete any associated Hotspots.</p>
            <p>Any further traffic from this DMRID will be rejected.</p>
          </ButtonSure>)
        }
      }
    ]
  }

  return (
    <Container>
      <p>Listed here are the DMRIDs you have registered with the service; these are required to connect your peers and make radio calls.</p>
      <p>You can and should add all your DMRIDs to your account, this will prevent others sniping them. The DMRID and callsign you enter will be checked against the official DMRID database, if it doesn't match then it will not be added. Each DMRID can only appear once, ie no sharing DMRIDs.</p>
      <p>If you don't have a DMRID then you will need to request one from <a href="https://radioid.net" target="_blank" rel="noopener noreferrer">RadioID.net</a>. You will need to create an account and upload a copy of your license. The DMRID database can be downloaded and added to your radio. The radio will then be able to show callsign, name and location information.</p>
      {(isadmin() && dmrids.length === 0) && <p>
        This user currently does not have any DMRIDs registered. <Button size="sm" variant="danger" onClick={(e) => sendReminderEmail(user.id)}>Send mail</Button> to user asking to add their IDs
      </p>
      }
      <br />
      <div className="center">
      {dmridError ? <Alert variant="warning">{dmridError}</Alert> : ''}
      <InputGroup>
        <input className="form_field_black" name="dmrid" placeholder="7 digit DMRID" type="number" pattern="[0-9]7" inputMode="numeric" value={dmrid} onChange={(e) => { setDmrid(e.target.value) }} />{' '}
        <input className="form_field_black" name="callsign" placeholder="Callsign" value={callsign} onChange={(e) => { setCallsign(e.target.value.toUpperCase()) }} />{' '}
        <Button onClick={() => createDmridButton() } disabled={!(dmrid && callsign)}>Add DMRID</Button>
      </InputGroup>
      </div>
      <br />
      <Table
        gridOptions={gridOptions}
        rowData={dmrids}
      />
    </Container>
  )
}


export default Dmrids;

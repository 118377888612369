import React, { useState } from "react";
import { Container, Button, Form, Row, Col } from "react-bootstrap";
import axios from "axios";

import Table from "../../Table/Table";
import isadminfor from "../../../utils/isadminfor";

import toast from "react-hot-toast";

function Acls({ acls, talkgroup, setAcls }) {
  const [aclEmails, setAclEmails] = useState('');

  const addAclEmailButton = () => {
    let addAclEmail =
      axios.post(`${process.env.REACT_APP_API}/api/v1/acl.json`, {
        acl: {
          email: aclEmails,
          talkgroup_id: talkgroup.id,
        }
      })
        .then(({ data }) => {
          setAclEmails('');
          setAcls([...acls, data])
        })
    toast.promise(addAclEmail, {
      loading: "Adding ACL for User...",
      success: "ACL Added",
      error: "Failed to Add User ACL",
    });
  }

  const updateAclButton = (aclobj, index, acl = {}) => {
    let updateAcl =
      axios.post(`${process.env.REACT_APP_API}/api/v1/acl/${aclobj.id}.json`, {
        acl
      })
        .then(({ data }) => {
          let tmpacls = [...acls];
          tmpacls[index] = data;
          setAcls(tmpacls);
        })
    toast.promise(updateAcl, {
      loading: "Sending update...",
      success: "ACL Updated",
      error: "Could not update ACL",
    });
  }

  const deleteAclButton = (id) => {
    let deleteAcl =
      axios.delete(`${process.env.REACT_APP_API}/api/v1/acl/${id}.json`)
        .then(() => { setAcls(acls.filter(acl => acl.id !== id)) })
    toast.promise(deleteAcl, {
      loading: "Deleting ACL for User...",
      success: "ACL Deleted",
      error: "Failed to Delete User ACL",
    });
  }

  function txrxbuttons(props) {
    return (
      <div className="btn-group">
        <Button size="sm" className={`btn btn-xs btn-${props.data.tx ? 'success' : 'danger'}`}
          disabled={!isadminfor(talkgroup.raynet_group.id)}
          onClick={() => updateAclButton(props.data, props.rowIndex, { tx: !props.data.tx })}
        >TX</Button>
        <Button size="sm" className={`btn btn-xs btn-${props.data.rx ? 'success' : 'danger'}`}
          disabled={!isadminfor(talkgroup.raynet_group.id)}
          onClick={() => updateAclButton(props.data, props.rowIndex, { rx: !props.data.rx })}
        >RX</Button>
      </div>
    )
  }
  const gridOptions = {

    getRowId(data) {
      return data.data.id;
    },
    paginationPageSize: 15,
    rowHeight: 50,
    columnDefs: [
      {
        headerName: "User",
        width: 250,
        field: "user",
        sort: "desc",
        cellRenderer: function (params) {
          return <a href={`/user/${params.value.id}`}>{params.value.name}</a>
        }
      }, {
        headerName: "ACL",
        cellRenderer: txrxbuttons,

      }, {
        headerName: "Action",
        field: "id",
        width: 250,
        cellRenderer: function (params) {
          return <Button size="sm" variant="danger" onClick={() => deleteAclButton(params.value)}>Remove</Button>
        }
      }
    ]
  }
  return (
    <div>
      <p>ACL (Access Control List) for this talkgroup</p>
      <br />
      {isadminfor(talkgroup.raynet_group.id) &&
          <Container>
            <Row>
              <Col>
                <Form.Control as="textarea"
                  id="talkgroupacls"
                  name="talkgroupacls"
                  placeholder="Enter email addresses..."
                  value={aclEmails}
                  onChange={(e) => setAclEmails(e.target.value)}
                  rows="5"
                />
                <Button size="sm" onClick={() => addAclEmailButton()}>Add ACL</Button>
              </Col>
              <Col>
              <p>ACLS control who can transmit and receive on this talkgroup.</p>
              <p>By default any member of RAYNET is able to access this talkgroup via dynamic (outside of the group you can't set it as a static but can set the radio to the talkgroup and press the PTT). </p>
              <p>If you want to restrict access for everyone you can use the "Default ACL" to disable access. If you want to restrict for a few users then you can add the users e-mail addresses and then set the ACL for them specifically. </p>
              </Col>
            </Row>
          </Container>
      }
      <Container>
        <Table
          gridOptions={gridOptions}
          rowData={acls}
        />
      </Container>


    </div>
  )
}


export default Acls;

import React, { useState, useEffect } from "react";
import axios from "axios";

import DisplayGroupname from "../../components/Helper/DisplayGroupname";
import LoadingSpinner from "../../components/Helper/LoadingSpinner";
import ToggleSwitch from "../../components/ToggleSwitch/ToggleSwitch";
import Table from "../../components/Table/Table";
import Panel from "../../components/Panel/Panel";
import moment from "moment";
import toast from "react-hot-toast";
import isadmin from "../../utils/isadmin";
import { Button, Badge, InputGroup } from "react-bootstrap";
import Tippy from "@tippyjs/react";

function RegisteredUsers({ users, setUsers }) {
  const [showDisabled, setShowDisabled] = useState(false)

  const updateUser = (userobj, index, user = {}) => {
    let updateUserPost =
      axios.post(`${process.env.REACT_APP_API}/api/v1/user/${userobj.id}.json`, { user })
        .then(({ data }) => {
          let tmpusers = [...users];
          let mi = tmpusers.findIndex(l => l.id === data.user.id);
          tmpusers[mi] = data.user;
          setUsers(tmpusers);
        })
    toast.promise(updateUserPost, {
      loading: "Sending update...",
      success: "User Updated",
      error: "Could not update User",
    });
  }

  const sendReminderEmail = (id) => {
    let reminderEmail =
    axios.get(`${process.env.REACT_APP_API}/api/v1/user/${id}/nodmrid_reminder.json`)
    .then (({ data }) => {
      console.log("data", data);
    })
    toast.promise(reminderEmail, {
      loading: "Sending Reminder E-Mail...",
      success: "Reminder E-Mail Sent",
      error: "Could Not Send Reminder E-Mail",
    })
  }

  if (!users) { return (<LoadingSpinner title={`Raynet DMR | Users`} />); }

  const gridOptions = {
    getRowId(data) {
      return data.data.id;
    },
    paginationPageSize: 30,
    pagination: true,
    columnDefs: [
      {
        headerName: "Name",
        field: "name",
        cellRenderer: function (params) {
          return (<a href={`/user/${params.data.id}`}>{params.value}</a>)
        }
      }, {
        headerName: "Callsign",
        field: "callsigns",
        sortable: true,
        cellRenderer: function (params) {
          if (params.value.length > 0) {
            return (params.value.map((callsign) => callsign).join(', '))
          } else {
            return (
              <Tippy content="Send reminder e-mail">
                <Button size="sm" variant="danger" onClick={(e) => sendReminderEmail(params.data.id)}>None</Button>
              </Tippy>
            )
          }
        }
      }, {
        headerName: "E-Mail",
        width: 250,
        field: "email",
        cellRenderer: function (params) {
          return (<a href={`mailto: ${params.value}`}>{params.value}</a>)
        }
      }, {
        headerName: "Registration Date",
        field: "created_at",
        cellRenderer: function (params) {
          return (moment(params.value).format("DD MMM YYYY"))
        }
      }, {
        headerName: "RAYNET-UK Groups",
        field: "memberships",
        autoHeight: true,
        minWidth: 400,
        filter: false,
        sortable: false,
        cellRenderer: function (params) {
          return (
            params.value.map((membership, index) =>
              <React.Fragment key={index}><DisplayGroupname key={index} group={membership.raynet_group} />{' '}</React.Fragment>
            )
          )
        }
      }
    ]
  }

  return (
    <>
      <Panel>
      <InputGroup>
        <Button onClick={() => setShowDisabled(!showDisabled)} size="sm" variant={showDisabled ? "danger" : "success"}>{showDisabled ? "Disabled" : "Active"}</Button>
      </InputGroup>
        <Table
          gridOptions={gridOptions}
          rowData={users.filter(u => u.active !== showDisabled)}
        />
      </Panel>
    </>

  );

}

export default RegisteredUsers;
import React, { useState, useEffect } from 'react'
import { GoogleMap, useJsApiLoader, MarkerF } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '1000px'
};

const center = {
  lat: 53.633342,
  lng: -3.0
};

function Gmap({ gpslogs }) {
  const [isMounted, setIsMounted] = useState(false);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDeXhVDOBUtLZAd3L4wClrTk-RUfV757cQ"
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  useEffect(() => setIsMounted(true), []);

  if (!gpslogs) { return (<p>loading...</p>) }

  return isLoaded ? (
    <GoogleMap
      id="gmaps-gps"
      mapContainerStyle={containerStyle}
      center={center}
      zoom={7}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      
      {gpslogs.map((d, index) => (
        d.gps.length > 0
        ? <MarkerF key={`${d.callsign}-${index}`}
          position={d.gps[0].position}
          label={d.callsign}
        />
        : null
      ))
      }
    </GoogleMap>
  ) : null
}

export default React.memo(Gmap)
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Row, Col, Table, Tabs, Tab, Button, Form, Modal, InputGroup, ButtonGroup } from "react-bootstrap";
import axios from "axios";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../components/Helper/LoadingSpinner";
import isadmin from "../../utils/isadmin";
import moment from "moment-timezone";
import NiceDate from "../../components/Helper/NiceDate";
import Lastheard from "../../components/Pages/Users/Lastheard";
import Hotspots from "../../components/Pages/Users/Hotspots";
import Dmrids from "../../components/Pages/Users/Dmrids";
import Groups from "../../components/Pages/Users/Groups";
import ToggleSwitch from "../../components/ToggleSwitch/ToggleSwitch";
import toast from "react-hot-toast";
import Callogs from "../../components/Pages/Users/Callogs";
import Container from "../../components/Layout/Container";
import Panel from "../../components/Panel/Panel";
import GPSLogs from "../../components/Pages/Users/GPSLogs";
import Paging from "../../components/Pages/Users/Paging";
import ButtonSure from "../../components/Helper/ButtonSure";

function ShowUser({ setPageTitle }) {
  const [user, setUser] = useState(null);
  const [timeformats, setTimeformats] = useState();
  const [lastUpdated, setLastUpdated] = useState(moment());
  const [newtz, setNewtz] = useState();
  const [timeformat, setTimeformat] = useState();
  const [dmrids, setDmrids] = useState();
  const [email, setEmail] = useState();
  const [firstname, setFirstname] = useState();
  const [surname, setSurname] = useState();
  const [showName, setShowName] = useState(false);
  const [enableEditEmail, setEnableEditEmail] = useState(false);
  const [tabSelect, setTabSelect] = useState();

  const handleCloseName = () => setShowName(false);
  const handleShowName = () => {
    setShowName(true);
  }

  let { id, tab } = useParams();

  const getUser = () => {
    axios
      .get(`${process.env.REACT_APP_API}/api/v1/user/${id}.json`)
      .then(({ data }) => {
        setUser(data.user);
        !enableEditEmail && setEmail(data.user.email);
        if (data.user.timeformat !== timeformat) { setTimeformat(data.user.timeformat); }
        if (data.user.timezone !== newtz) { setNewtz(data.user.timezone); }
        setDmrids(data.user.dmrids);
        setPageTitle(data.user.firstName)
      })
      .then(() => setLastUpdated(moment()))
      .catch((error) => console.error(error));
  }

  const setHotspots = (theseHotspots) => {
    let tmpuser = JSON.parse(JSON.stringify(user));
    tmpuser.peers = theseHotspots;
    //    setUser(tmpuser);
    getUser()
  }

  const getTimeformats = () => {
    axios.get(`${process.env.REACT_APP_API}/api/v1/timeformats.json`)
      .then(({ data }) => { setTimeformats(data.timeformats); })
  }

  const newapitoken = () => {
    let apitoken = axios
      .get(`${process.env.REACT_APP_API}/api/v1/user/${id}/updateapi.json`)
      .then(({ data }) => {
        console.log("approve", data)
        setUser(data.user);
      })
      .catch((error) => console.error(error));
    toast.promise(apitoken, {
      loading: "Generating Token...",
      success: "Token Created",
      error: "Failed to Generate token",
    });
  }

  const copyAPIToClipboard = (key) => {
    navigator.clipboard.writeText(key);
    toast.success("Key Copied to clipboard");
  }

  const updateUser = (u, user = {}) => {
    let updateUserRecord =
      axios.post(`${process.env.REACT_APP_API}/api/v1/user/${u.id}.json`, { user })
        .then(({ data }) => {
          setUser(data.user);
          setTimeformat(data.user.timeformat);
          setNewtz(data.user.timezone);
          setDmrids(data.user.dmrids);
          !enableEditEmail && setEmail(data.user.email);
          setShowName(false);
          if (data.user.id === JSON.parse(localStorage.getItem('user')).id) {
            localStorage.setItem("user", JSON.stringify({
              id: data.user.id,
              firstName: data.user.firstName,
              surname: data.user.surname,
              email: data.user.email,
              repeaterkeeper: data.user.repeaterkeeper,
              timezone: data.user.timezone,
              timeformat: data.user.timeformat
            }))
          }
        })
    toast.promise(updateUserRecord, {
      loading: "Sending update...",
      success: "User Updated",
      error: "Could not update User",
    });
  }

  useEffect(() => {
    getUser();
    getTimeformats();
  }, []);

  useEffect(() => {
    const checkTimeSinceLastUpdate = () => {
      let now = moment();
      let duration = moment.duration(now.diff(lastUpdated));
      return parseInt(duration.asSeconds());
    };
    let refreshInterval = setInterval(() => {
      if (checkTimeSinceLastUpdate() >= 30) {
        getUser();
      }
    }, 10000);
    return () => clearInterval(refreshInterval);;
  });

  function cancelEmailEdit() {
    setEnableEditEmail(false)
    setEmail(user.email)
  }

  function saveEmailEdit() {
    updateUser(user, { email: email })
    setEnableEditEmail(false)
  }


  if (!user) { return (<LoadingSpinner title={`Raynet DMR | User - ${id}`} />); }

  return (
    <div>
      <Container>
        <Helmet title="Raynet DMR | User" />
        <h1>
          {user.firstName} {user.surname}{' '}<ButtonGroup>{!user.locked || isadmin() ? <Button onClick={handleShowName} size="sm" variant="danger">Edit</Button> : ''}
            {isadmin() ? <Button onClick={() => updateUser(user, { locked: !user.locked })} variant={user.locked ? 'danger' : 'success'} size="sm">{user.locked ? 'Locked' : 'Unlocked'}</Button> : ''}
          </ButtonGroup>
        </h1>
        <Row>
          <Col>
            <Table borderless>
              <thead>
                <tr>
                  <th colSpan="2" className="text-center">User settings</th>
                </tr>
              </thead>
              <tbody>
                <tr><td align="right"><strong>E-Mail</strong></td><td><Col md={10}> {isadmin() ?
                  <InputGroup><Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} disabled={!enableEditEmail} />
                    {enableEditEmail ?
                      <>
                        <Button onClick={(e) => saveEmailEdit()} size="sm" variant="warning">Save</Button>
                        <Button onClick={(e) => cancelEmailEdit()} size="sm" variant="primary">Cancel</Button>
                      </>
                      : <Button onClick={(e) => setEnableEditEmail(true)} size="sm" variant="danger">Edit</Button>}</InputGroup>
                  : <a href={`mailto=${user.email}`}>{user.email}</a>
                }</Col></td></tr>
                <tr><td align="right"><strong>Timezone</strong></td><td><Col md={10}><Form.Select aria-label="Timezone" onChange={(e) => updateUser(user, { timezone: e.target.value })} value={newtz}>
                  {moment.tz.names().map((tz, index) => (<option key={index} value={tz}>{tz}</option>))}
                </Form.Select></Col></td></tr>
                {timeformats
                  ? <tr><td align="right"><strong>Time Format</strong></td><td><Col md={10}><Form.Select aria-label="Timeformat" onChange={(e) => updateUser(user, { timeformat: e.target.value })} value={timeformat}>
                    {timeformats.map((f, index) => (<option key={index} value={f.format}>{moment.utc(new Date()).format(f.format)}</option>))}
                  </Form.Select></Col></td></tr>
                  : null}
                <tr><td align="right"><strong>API Key </strong></td><td><div className="btn-group">{user.apitoken &&
                  <Button onClick={() => { copyAPIToClipboard(user.apitoken) }} size="sm" variant="success">Copy</Button>
                }
                  <ButtonSure onClick={() => newapitoken()} size="sm" variant="danger" actionName="New Key">Creating a new key will invalidate the previous one</ButtonSure>
                </div></td></tr>
                {isadmin() &&
                  <tr><td align="right"><strong>Active</strong></td><td>
                    <ToggleSwitch checked={user.active} id={`active`} onChange={(e) => updateUser(user, { active: e })} />
                  </td></tr>
                }
                {isadmin() && user.isrepeaterkeeper &&
                  <tr><td align="right"><strong>Repeater Keeper</strong></td><td><ToggleSwitch checked={user.repeaterkeeper} id={`repeaterkeeper`} onChange={(e) => updateUser(user, { repeaterkeeper: e })} /> </td></tr>
                }
              </tbody>
            </Table>
          </Col>
          <Col>
            <Table borderless>
              <thead><tr><th colSpan="2" className="text-center">Account Statistics</th></tr></thead>
              <tbody>
                <tr><td align="right"><strong>Account Created</strong></td><td><NiceDate date={user.created_at} /></td></tr>
                <tr><td align="right"><strong>Signin Count</strong></td><td>{user.sign_in_count}</td></tr>
                <tr><td align="right"><strong>Current Signin</strong></td><td><NiceDate date={user.current_sign_in_at} /></td></tr>
                <tr><td align="right"><strong>Current Signin IP</strong></td><td>{user.current_sign_in_ip}</td></tr>
                <tr><td align="right"><strong>Last Signin</strong></td><td><NiceDate date={user.last_sign_in_at} /></td></tr>
                <tr><td align="right"><strong>Last Signin IP</strong></td><td>{user.last_sign_in_ip}</td></tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
      <Container>
        <Tabs
          id="user-tab"
          activeKey={tabSelect ? tabSelect : tab ? tab : 'Lastheard'}
          onSelect={(k) => setTabSelect(k)}
        >
          <Tab eventKey="Lastheard" title="Lastheard"><Panel><Lastheard lastheard={user.lastheard} /></Panel></Tab>
          <Tab eventKey="Hotspots" title="Hotspots"><Panel><Hotspots hotspots={user.peers} memberships={user.memberships} setHotspots={setHotspots} getUser={getUser} repeaterkeeper={false} user={user} /></Panel></Tab>
          {user.repeaterkeeper ? <Tab eventKey="Repeaters" title="Repeaters">
            <Panel><Hotspots hotspots={user.peers} memberships={user.memberships} setHotspots={setHotspots} getUser={getUser} repeaterkeeper={true} user={user} /></Panel>
          </Tab> : ''}
          <Tab eventKey="DMRIDs" title="DMRIDs"><Panel><Dmrids dmrids={dmrids} setDmrids={setDmrids} user={user} getUser={getUser} /></Panel></Tab>
          <Tab eventKey="Groups" title="Groups"><Panel><Groups groups={user.memberships} user={user} setUser={setUser} /></Panel></Tab>
          <Tab eventKey="Calllog" title="Call Logs"><Panel><Callogs user_id={user.id} /></Panel></Tab>
          <Tab eventKey="GPSLogs" title="GPS Logs"><Panel><GPSLogs gps={user.gps} dmrids={user.dmrids} user_id={user.id} getUser={getUser} /></Panel></Tab>
          <Tab eventKey="Pages" title="Page Messages"><Panel><Paging pages={user.pages} user_id={user.id} groups={user.memberships} getUser={getUser} /></Panel></Tab>
        </Tabs>
        {user &&
          <>
            <Modal show={showName} onHide={handleCloseName}>
              <Modal.Header closeButton>
                <Modal.Title>Edit Name</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>You can change you personal details below. It is not possible to change your e-mail address. Should you need to, please contact an admin to discuss.</p>
                <input value={firstname ? firstname : user.firstName} onChange={(e) => setFirstname(e.target.value)} />
                <input value={surname ? surname : user.surname} onChange={(e) => setSurname(e.target.value)} />
                <Button onClick={() => updateUser(user, { firstName: firstname, surname: surname })} size="sm">Update Name</Button>
              </Modal.Body>
            </Modal>

          </>
        }
      </Container>
    </div>
  );
}

export default ShowUser;

import Table from "../../components/Table/Table";
import Panel from "../../components/Panel/Panel";
import { Container } from "react-bootstrap";

function Talkgroups({talkgroups}) {

  const gridOptions = {
    getRowId(data) {
      return data.data.id;
    },
    paginationPageSize: 30,
    columnDefs: [
      {
        headerName: "Name",
        field: "name",
        cellRenderer: function(params) {
          return (<a href={`/talkgroup/${params.data.id}`}>{params.value}</a>)
        }
      }, {
        headerName: "Number",
        field: "number",
      }
    ]
  }

  return (
    <Container>
      <Panel>
        <h1>Talkgroups</h1>
          <Table
            gridOptions={gridOptions}
            rowData={talkgroups}
          />
          </Panel>
    </Container>
  );
}

export default Talkgroups;
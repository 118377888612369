import React from "react";
import "./DisplayHotspotName.css";

function DisplayHotspotName({ hs }) {

  return (
    <div>
      {hs.peer ? <>
        {hs.peer.url
          ? <a href={hs.peer.url.startsWith('http') ? hs.peer.url : `https://${hs.peer.url}` } target="_blank" rel="noopener noreferrer"><strong>{hs.peer.callsign}</strong></a>
          : <strong>{hs.peer.callsign}</strong>
        }{' '}({hs.peer.id}) {hs.peer.repeater ? <span className='badge bg-secondary badge-sm'>RPTR</span> : ''}
        <br />{hs.peer.location}
      </>
        : hs.openbridge ? <>
          {hs.openbridge.url
            ? <a href={hs.openbridge.url} target="_blank" rel="noopener noreferrer"><strong>{hs.openbridge.name}</strong></a>
            : <strong>{hs.openbridge.name}</strong>
          }{' '}({hs.openbridge.remotedmrid}) <span className='badge bg-secondary badge-sm'>BRIDGE</span>
          <br />{hs.openbridge.location}
        </>
          : <>
            <strong>{hs.radioid.callsign}</strong> ({hs.peer.id})
            {hs.radioid.repeater ? <span className='badge bg-secondary badge-sm'>RPTR</span> : ''}
          </>
      }
    </div>
  );

}

export default DisplayHotspotName;
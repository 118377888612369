import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Table, Tabs, Tab, Button, Modal, InputGroup, Form, Col } from "react-bootstrap";
import axios from "axios";
import { useParams } from "react-router-dom";
import DisplayGroupname from "../../components/Helper/DisplayGroupname";
import GroupTalkgroups from "./GroupTalkgroups";
import LoadingSpinner from "../../components/Helper/LoadingSpinner";
import Users from "./Users";
import toast from "react-hot-toast";
import moment from "moment";
import Container from "../../components/Layout/Container";
import Panel from "../../components/Panel/Panel";
import isadminfor from "../../utils/isadminfor";
import isadmin from "../../utils/isadmin";

function ShowGroup({ setPageTitle }) {
  const [group, setGroup] = useState(null);
  const [tokens, setTokens] = useState();
  const [talkgroups, setTalkgroups] = useState();
  const [show, setShow] = useState(false);
  const [users, setUsers] = useState();
  const [lastUpdated, setLastUpdated] = useState(moment());

  const [enableEditGroupName, setEnableEditGroupName] = useState(false);
  const [groupName, setGroupName] = useState();

  const [enableEditGroupPrefix, setEnableEditGroupPrefix] = useState(false);
  const [groupPrefix, setGroupPrefix] = useState();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let { id } = useParams();


  const updateUsers = (new_users) => {
    setUsers(new_users);
  }

  const getGroup = () => {
    axios
      .get(`${process.env.REACT_APP_API}/api/v1/raynet_group/${id}.json`)
      .then(({ data }) => {
        setGroup(data.raynet_group);
        setTalkgroups(data.raynet_group.talkgroups);
        setUsers(data.raynet_group.users);
        setPageTitle(data.raynet_group.name)
        !enableEditGroupName && setGroupName(data.raynet_group.name);
        !enableEditGroupPrefix && setGroupPrefix(data.raynet_group.prefix);
      })
      .then(() => setLastUpdated(moment()))
      .catch((error) => console.error(error));
  }

  const getTokens = () => {
    axios
      .get(`${process.env.REACT_APP_API}/api/v1/tokens.json?id=${id}`)
      .then(({ data }) => {
        setTokens(data.tokens);
      })
      .catch((error) => console.error(error));
  }


  const updateTalkgroupDetails = (new_talkgroups) => {
    setTalkgroups(new_talkgroups);
  }

  const updateGroup = (raynet_group = {}) => {
    setShow(false);
    let updatePrefix =
      axios.post(`${process.env.REACT_APP_API}/api/v1/raynet_group/${id}.json`, { raynet_group })
        .then(({ data }) => {
          getGroup();
        })
    toast.promise(updatePrefix, {
      loading: "Updating Group Details...",
      success: "Group Updated",
      error: "Could not update group details",
    });

  }

  useEffect(() => {
    getGroup();
    getTokens();
  }, []);

  useEffect(() => {
    const checkTimeSinceLastUpdate = () => {
      let now = moment();
      let duration = moment.duration(now.diff(lastUpdated));
      return parseInt(duration.asSeconds());
    };
    let refreshInterval = setInterval(() => {
      if (checkTimeSinceLastUpdate() >= 30) {
        getGroup();
        getTokens();
      }
    }, 10000);
    return () => clearInterval(refreshInterval);;
  });

  function cancelGroupNameEdit() {
    setEnableEditGroupName(false)
    setGroupName(group.name)
  }

  function saveGroupNameEdit() {
    updateGroup({ name: groupName })
    setEnableEditGroupName(false)
  }

  function cancelGroupPrefixEdit() {
    setEnableEditGroupPrefix(false)
    setGroupPrefix(group.prefix)
  }

  function saveGroupPrefixEdit() {
    updateGroup({ prefix: groupPrefix })
    setEnableEditGroupPrefix(false)
  }


  if (!group) { return (<LoadingSpinner title={`Raynet DMR | Group - ${id}`} />); }

  return (
    <div>
      <Container>
        <Panel>
          <Helmet title="Raynet DMR | Group" />
          <Table borderless >
            <thead><tr><th colSpan="2" className="text-center">RAYNET-UK Group Details</th></tr></thead>
            <tbody>
              <tr key="1" ><td align="right" valign="middle"><strong>RAYNET-UK Group</strong></td><td><Col md={4}>
                {isadmin() ?
                  <InputGroup><Form.Control type="text" placeholder="Group Name" value={groupName} onChange={(e) => setGroupName(e.target.value)} disabled={!enableEditGroupName} />
                    {enableEditGroupName ?
                      <>
                        <Button onClick={(e) => saveGroupNameEdit()} size="sm" variant="warning">Save</Button>
                        <Button onClick={(e) => cancelGroupNameEdit()} size="sm" variant="primary">Cancel</Button>
                      </>
                      : <Button onClick={(e) => setEnableEditGroupName(true)} size="sm" variant="danger">Edit</Button>
                    }
                  </InputGroup>
                  : group.name
                }
              </Col></td></tr>
              <tr key="2" ><td align="right"><strong>Group controller{group.group_admins.length > 1 ? 's' : ''}</strong></td><td>
                {group.group_admins && group.group_admins.map((admin, index) => (<React.Fragment key={index}>{index ? ', ' : ''}<a href={`mailto:${admin.email}`}>{admin.name}</a></React.Fragment>))}</td></tr>
              {group.zone && group.zone.id !== group.id ? <tr><td align="right"><strong>Zone</strong></td><td><DisplayGroupname group={group.zone} /></td></tr> : ''}
              {group.zones.length > 1
                ? <tr key="3" ><td align="right"><strong>Sub Groups</strong></td><td>{group.zones.map((z, index) => (<React.Fragment key={index}>{index ? ' ' : ''}<DisplayGroupname group={z.zone} /></React.Fragment>))}</td></tr>
                : ''}
              <tr key="4" ><td width="200" align="right"><strong>Groupcode</strong></td><td>{group.groupcode}</td></tr>
              <tr key="5" ><td align="right"><strong>Number</strong></td><td>{group.number}</td></tr>
              <tr key="6" ><td align="right"><strong>Talkgroup Numbers</strong></td><td>50{group.number}XX</td></tr>
              <tr key="7" ><td align="right" valign="middle"><strong>Talkgroup Prefix</strong></td><td><Col md={4}>
                {isadminfor(group.id) ?
                  <InputGroup>
                    <Form.Control type="text" placeholder="Talkgroup Prefix" value={groupPrefix} onChange={(e) => setGroupPrefix(e.target.value)} disabled={!enableEditGroupPrefix} />
                    {enableEditGroupPrefix ?
                      <>
                        <Button onClick={(e) => saveGroupPrefixEdit()} size="sm" variant="warning">Save</Button>
                        <Button onClick={(e) => cancelGroupPrefixEdit()} size="sm" variant="primary">Cancel</Button>
                      </>
                      : <Button onClick={(e) => setEnableEditGroupPrefix(true)} size="sm" variant="danger">Edit</Button>
                    }
                  </InputGroup>
                  : group.prefix
                }
              </Col></td></tr>
            </tbody>
          </Table>
        </Panel>
        <Panel>
          {isadminfor(group.id) ?
            <Tabs defaultActiveKey="Talkgroups" id="group-tab" className="">
              <Tab eventKey="Talkgroups" title="Talkgroups"><Panel><GroupTalkgroups talkgroups={talkgroups} groupid={group.id} updateTalkgroupDetails={updateTalkgroupDetails} /></Panel></Tab>
              <Tab eventKey="Users" title={`Users (${group.users.length})`}><Panel>
                <Users users={users}
                  groupid={group.id}
                  updateUsers={updateUsers}
                  tokens={tokens}
                  setTokens={setTokens}
                  getTokens={getTokens} />
              </Panel></Tab>
            </Tabs>
            : <Panel><GroupTalkgroups talkgroups={talkgroups} groupid={group.id} updateTalkgroupDetails={updateTalkgroupDetails} /></Panel>
          }
        </Panel>
      </Container>
    </div>
  );
}

export default ShowGroup;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import actionCable from 'actioncable'

const CableApp = {}
const root = ReactDOM.createRoot(document.getElementById('root'));

let cablehost = 'wss://raynet.m0guy.com/cable'
if (process.env.NODE_ENV === "development") {
  cablehost = 'ws://localhost:3000/cable'
} 

CableApp.cable = actionCable.createConsumer(cablehost)
root.render(
  <React.StrictMode>
    <App cable={CableApp.cable} />
  </React.StrictMode>
);


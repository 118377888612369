import React, { useState, useEffect } from "react";
import { Button, Row, Col, Container, Form } from "react-bootstrap"
import axios from "axios";
import toast from "react-hot-toast";
import ToastComponent from "../../components/Toast/ToastComponent";
import LoadingSpinner from "../../components/Helper/LoadingSpinner";
import bg from "../../images/RAYNET-login-bg.png";
import logo from "../../images/Raynet-logo.png";
import "./Register.css";
import PasswordStrengthBar from 'react-password-strength-bar';

const getTokenFromUrl = () => {
  const url = window.location.pathname;
  const lastIndexOf = url.lastIndexOf("/");
  return url.substring(lastIndexOf + 1);
};


function Register() {
  const token = getTokenFromUrl();
  const [isLoading, setIsLoading] = useState(true);
  const [firstname, setFirstname] = useState();
  const [surname, setSurname] = useState();
  const [password1, setPassword1] = useState();
  const [password2, setPassword2] = useState();
  const [notes, setNotes] = useState();
  const [isInvalidToken, setIsInvalidToken] = useState(false);
  const [email, setEmail] = useState(null);
  const [result, setResult] = useState();

  document.body.style.backgroundImage = `url(${bg}`;

  const getEmailFromToken = () => {
    axios
      .get(`${process.env.REACT_APP_API}/api/v1/register/${token}.json`)
      .then(({ data }) => {
        /**
         * If a status is returned, the token is invalid, else
         * set the email field to display
         */
        if (data.status === 'ok' && "email" in data) {
          setEmail(data.email);
        } else {
          setIsInvalidToken(true);
        }

        setIsLoading(false);
      })
      .catch((error) => {
        toast.error("Unable to validate token");
      });
  };

  const createAccount = () => {
    let senddata =
      axios.post(`${process.env.REACT_APP_API}/api/v1/register.json`, {
        token: token,
        additional_info: notes,
        user: {
          firstName: firstname,
          surname: surname,
          password: password1,
          password_confirmation: password2,
        }
      })
        .then(({ data }) => {
          console.log("data", data);
          setResult(data);
        })
        .catch((error) => {
          console.log("error data", error)
          setResult(error);
        })
    toast.promise(senddata, {
      loading: "Creating Account",
      success: "Account Created",
      error: "Could not create Account",
    })
  }

  function passwordok() {
    if (firstname && (firstname.length < 2 || firstname.length > 100)) {
      return 'Firstname must be between 2 and 100 characters in length'
    } else if (surname && (surname.length < 3 || surname.length > 100)) {
      return 'Surname must be between 3 and 100 characters in length'
    } else if (password1 && password2 && password1 !== password2) {
      return 'Passwords must match'
    } else if (password1 && password1.length < 10) {
      return 'Passwords need to be over 10 characters in length'
    } else {
      return ''
    }
  }

  function detailscheck() {
    let disabled = true;
    if (firstname && surname && password1 && password2) {
      if ((firstname.length > 1 && firstname.length < 100) && (surname.length > 2 && surname.length < 100) && password1 && password2 && password1 === password2 && password1.length > 9) {
        disabled = false;
      }
    }
    return disabled;
  }

  useEffect(getEmailFromToken, [token]);

  if (isLoading) { return <LoadingSpinner /> }
  if (isInvalidToken) { window.location = "/"; }

  if (result) {
    return (
      <div className="main-register">
        <Container>
          <img src={logo} className="border-radius-20 center-img" width="100" height="100" alt="Raynet-UK DMR Logo" />
          <p className="sign" align="center">Raynet-UK DMR</p>
          <p className="notes">Thank you {result.firstname}</p>
          <p className="notes"> Your account has been created</p>
          {result.active
            ? <><p className="notes">Please go ahead and login</p>
              <Button onClick={() => window.location = "/"}>Login</Button>
            </>
            : <><p className="notes">Your account is currently disabled.</p>
            <p className="notes">The Global Admins have been notifed. You will receive an e-mail once they have approved your account. </p>
            <p className="notes">You can now close this window.</p>
            </>
        }
        </Container>
      </div>
    )
  }

  return (
    <>
      <ToastComponent />
      <div className="main-register">
        <Container>
          <img src={logo} className="border-radius-20 center-img" width="100" height="100" alt="Raynet-UK DMR Logo" />
          <p className="sign" align="center">Raynet-UK DMR</p>
          <Form>
            <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
              <Form.Label column sm="2" className="Register-Label">
                Email
              </Form.Label>
              <Col sm="10">
                <p className="email">{email}</p>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
              <Form.Label column sm="2" className="Register-Label">
                Firstname
              </Form.Label>
              <Col sm="10">
                <Form.Control onChange={(e) => setFirstname(e.target.value)} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
              <Form.Label column sm="2" className="Register-Label">
                Surname
              </Form.Label>
              <Col sm="10">
                <Form.Control onChange={(e) => setSurname(e.target.value)} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
              <Form.Label column sm="2" className="Register-Label">
                Password
              </Form.Label>
              <Col sm="10">
                <Form.Control type="password" onChange={(e) => setPassword1(e.target.value)} />
                <PasswordStrengthBar password={password1} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
              <Form.Label column sm="2" className="Register-Label">
                Confirm Password
              </Form.Label>
              <Col sm="10">
                <Form.Control type="password" onChange={(e) => setPassword2(e.target.value)} />
              </Col>
            </Form.Group>
            <p className="error" align="center">{passwordok()} </p>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label className="Register-Label">Additional information, please include your callsign and anything else which might help register you correctly.
                {email.toLowerCase().includes('raynet-uk.net') ? null : ' This is specially important as you are not using a RAYNET-UK e-mail address'}</Form.Label>
              <Form.Control as="textarea" rows={5} onChange={(e) => setNotes(e.target.value)} />
            </Form.Group>
            <div align="right"><Button disabled={detailscheck()} onClick={() => createAccount()}>Register</Button></div>
          </Form>
        </Container>
      </div>
    </>
  )

}

export default Register;
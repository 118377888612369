import { Form, Row, Col, Button, Container, InputGroup } from "react-bootstrap";
import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";

function StaticTalkgroupList({ groups, peer, setPeer }) {
  const [group, setGroup] = useState();
  const [talkgroup, setTalkgroup] = useState();

  const addStaticTalkgroup = () => {
    let addStatic =
      axios.post(`${process.env.REACT_APP_API}/api/v1/static_groups.json`, { 
        static_group: {
          peer_id: peer.id,
          user_id: peer.user.id,
          talkgroup_id: talkgroup,
        }
      })
        .then(({ data }) => {
          setPeer(data)
        })
    toast.promise(addStatic, {
      loading: "Adding Static Talkgroup...",
      success: "Static Talkgroup added",
      error: "Could not set Static Talkgroup",
    });
  }

  return (
    <Container >
      <InputGroup>
        <Form.Select aria-label="Select Raynet Group" onChange={(e) => setGroup(parseInt(e.target.value))}>
          <option>-- Select RAYNET-UK Group --</option>
          {groups ? groups.map((group) => (<option key={group.raynet_group.id} value={group.raynet_group.id}>{group.raynet_group.name}</option>)) : ''}
        </Form.Select>
          <Form.Select aria-label="Select Raynet Group" onChange={(e) => setTalkgroup(e.target.value)}>
            <option>-- Select Talkgroup --</option>
            {groups && group ? groups.filter(m => m.raynet_group.id === group)[0].raynet_group.talkgroups.filter(t => !peer.static_talkgroups.map(ts => ts.talkgroup.id).includes(t.talkgroup.id)).map((tg) => (<option key={tg.talkgroup.id} value={tg.talkgroup.id}>{tg.talkgroup.name}</option>)) : ''}
          </Form.Select>
        <Button onClick={() => addStaticTalkgroup()}>Add Static Talkgroup</Button>
      </InputGroup>
    </Container>
  )
}

export default StaticTalkgroupList;
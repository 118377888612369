import React, { useState } from "react";
import { Badge, Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import axios from "axios";
import toast from "react-hot-toast";

import GroupListSelect from "../../Helper/GroupListSelect";

function RegisterByEmail({ groupid, getTokens }) {
  const [group, setGroup] = useState(groupid);
  const [emailsText, setEmailsText] = useState('');

  const addUserTokenButton = () => {
    if (!emailsText || !group) {
      if (!emailsText) { toast.error("Please enter a E-Mail Address.") }
      if (!group) { toast.error("Select a Group") }
    } else {      
      let addUserToken =
        axios.post(`${process.env.REACT_APP_API}/api/v1/tokens.json`, {
          token: {
            email: emailsText,
            group_id: group,
          }
        })
          .then(({ data }) => {
            getTokens()
            setEmailsText("");
          })
          .catch((error) => {
            console.error(error);
          })
      toast.promise(addUserToken, {
        loading: "Adding Token...",
        success: "Token Added",
        error: "Failed to Add Token",
      });
    }
  }

  const renderEmailPills = (text) => {
    const regex =
      /(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi;
    const found = text.match(regex);
    const deduplicated = [...new Set(found)];

    if (!deduplicated) {
      return (
        <div className="text-gray-600">
          Unable to find any email addresses in the provided text.
        </div>
      );
    }

    return deduplicated.map((email, index) => (
      <React.Fragment key={index}><Badge bg="success" size="sm">{email}</Badge><> </></React.Fragment>
    ));
  };


  return (
    <table>
      <thead>
        <tr><th className="text-center">Register user by E-mail</th></tr>
      </thead>
      <tbody>
        <tr><td>
          <p>You can add multiple e-mail address in the text area below. If the user already exists they will be added to the group. If they do not yet exist an e-mail will be sent allowing the user to register. If the E-mail address does not end with <strong>@raynet-uk.net</strong>, then the user will be allowed to register however their account will be disabled until a Global Admin authorise it.</p>
          <p>You can add as many e-mail addresses as you require.</p>
          <Form.Control as="textarea"
            id="enrolMembers"
            name="enrolMembers"
            placeholder="Enter email addresses..."
            value={emailsText}
            onChange={(e) => setEmailsText(e.target.value)}
            rows="5"
          />
          {emailsText && (
            <div className="flex flex-wrap mb-3">
              {renderEmailPills(emailsText)}
            </div>
          )}
          <br />
          <div style={{float: "right"}}>
          <InputGroup>
            {groupid ? null : <GroupListSelect setGroup={setGroup} />}
            <Button onClick={() => addUserTokenButton()} variant="primary" disabled={emailsText.length === 0}>Register</Button>
          </InputGroup>
          </div>
        </td></tr>
      </tbody>
    </table>
  )
}


export default RegisterByEmail;
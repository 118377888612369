
import moment from "moment";

function dur(start, end) {
  const startT = moment(start);
  const endT = moment(end);
  const duration = moment.utc(endT.diff(startT)).format("HH:mm:ss");
  return duration
}

export default dur;
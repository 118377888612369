import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import ismemberof from "../../utils/ismemberof";
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';
import { Badge } from "react-bootstrap";

function DisplayTalkgroup(props) {

  if (props.data.talkgroup) {
  return (
      <span>
        {props.data.talkgroup.closed && <FontAwesomeIcon fixedWidth icon={faLock} />}
        {props.data.talkgroup.name}
        <br />
        {ismemberof(props.data.talkgroup.raynet_group.id)
          ? <>
            <a href={`/group/${props.data.talkgroup.raynet_group.id}`}><Badge bg="primary">{props.data.talkgroup.raynet_group.name}</Badge></a>
            <a href={`/talkgroup/${props.data.talkgroup.id}`}><strong className="callsign"> {props.data.talkgroup.number}</strong></a>
          </>
          : <>
            <Badge bg="primary">{props.data.talkgroup.raynet_group.name}</Badge>
            <strong className="callsign"> {props.data.talkgroup.number}</strong>
          </>
        }
      </span>
  );
      } else { return (<span>no Talkgroup</span>)
    }
}

export default DisplayTalkgroup;
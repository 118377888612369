import moment from "moment";
import { Button, Container, InputGroup } from "react-bootstrap";
import Table from "../../components/Table/Table";
import DisplayTime from "../../components/CellRenderers/DisplayTime";
import SendPage from "../../components/SendPage/SendPage";
import { useState, useEffect } from "react";
import axios from "axios";
import LoadingSpinner from "../../components/Helper/LoadingSpinner";
import DisplayRICCallsign from "../../components/Helper/DisplayRICCallsign";

function Messaging({setPageTitle}) {
  const [showsent, setShowSent] = useState(false)
  const [pages, setPages] = useState()
  const [groups, setGroups] = useState()
  const [lastUpdated, setLastUpdated] = useState(moment());

  const getMessages = () => {
    axios.get(`${process.env.REACT_APP_API}/api/gui/v1/pages.json`)
      .then(({ data }) => {
        setPages(data.pages)
        setGroups(data.memberships)
      })
  }

  useEffect(() => {
    getMessages()
  }, [])

  useEffect(() => {
    const checkTimeSinceLastUpdate = () => {
      let now = moment();
      let duration = moment.duration(now.diff(lastUpdated));
      return parseInt(duration.asSeconds());
    };
    let refreshInterval = setInterval(() => {
      if (checkTimeSinceLastUpdate() >= 40) {
        getMessages()
      }
    }, 10000);
    return () => clearInterval(refreshInterval);;
  });


  const gridOptions = {
    getRowId(data) {
      return data.data.id;
    },
    paginationPageSize: 15,
    rowHeight: 50,
    columnDefs: [
      {
        headerName: `Sent (${moment.tz(JSON.parse(localStorage.getItem('user')).timezone).zoneAbbr()})`,
        field: "sent",
        sort: "desc",
        cellRenderer: DisplayTime
      }, {
        headerName: "From",
        width: 10,
        cellRenderer: function (params) {
          return `${params.data.user}`
        }
      }, {
        headerName: "To",
        field: "callsign",
        width: 20,
        cellRenderer: DisplayRICCallsign
      }, {
        headerName: "Via Groups", 
        cellRenderer: function (params) {
          return ( params.data.txgroups.map((f) => f.name).join(', ') )
        }
      }, {
        headerName: "Message",
        field: "message",
        width: 250,
      }
    ]
  }

  setPageTitle("Messaging")
  
  if (!pages) { return (<LoadingSpinner title={`Raynet DMR | Pages`} />); }

  return (
    <Container>
      <p></p>
      <SendPage groups={groups} />
      <InputGroup>
        <Button onClick={() => setShowSent(!showsent)} size="sm" variant={showsent ? "success" : "primary"}>{showsent ? "Sent" : "Received"}</Button>
      </InputGroup>
      <Table
        gridOptions={gridOptions}
        rowData={showsent ? pages.sent : pages.received}
      />
    </Container>
  )

}

export default Messaging;
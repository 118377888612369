import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import axios from "axios";
import { Button, Modal, Form } from "react-bootstrap";
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';
import isadmin from "../../utils/isadmin";

function DisplayCallsign(props) {
  const [reason, setReason] = useState('');
  const [showBlock, setShowBlock] = useState(false);
  const [membership, setMembership] = useState();

  const blockButton = () => {
    let block =
      axios.post(`${process.env.REACT_APP_API}/api/v1/blacklist.json`, {
        dmrid: props.value.id,
        reason: reason,
      })
        .then(({ data }) => {
          setShowBlock(false);
        })
    toast.promise(block, {
      loading: "Blocking user...",
      success: "User Blocked",
      error: "Failed to block user",
    });

  }

  const handleCloseBlock = () => setShowBlock(false);
  const handleShowBlock = () => setShowBlock(true);

  const getMembershipInfo = () => {
    axios
      .get(`${process.env.REACT_APP_API}/api/v1/membercheck/${props.value.callsign}.json`)
      .then(({ data }) => { setMembership(data); })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    if (!membership && isadmin() && props.value.nonmember) { getMembershipInfo(); }
  }, []);

  return (
    <div>
      <Tippy disabled={!(props.value.nonmember && isadmin()) ? true : false} content={<>
        {props.value.nonmember && membership && membership['Name'] ?
          <>
            Name {membership['Name']}<br />
            Status {membership['Membership status']}<br />
            Expires {membership['Membership expires']}<br />
            Group {membership['Registered to Group']}<br />
          </>
          : 'Not Found'
        }
      </>}><div>
        {localStorage.getItem('qrz')
          ? <><a href={`${localStorage.getItem('qrz')}${props.value.callsign}`} rel="noopener noreferrer" target="_blank"><strong className="callsign">{props.value.callsign}</strong></a>{' '}</>
          : <strong className="callsign">{props.value.callsign}</strong>
        }
        {props.value.nonmember && <Button
          variant="danger"
          className="btn btn-vsmall"
          onClick={() => handleShowBlock()}
          disabled={!localStorage.getItem('admin')}
        ><FontAwesomeIcon icon={faUser} /></Button>
        }
        <br />
        <span className="callsign">({props.value.id})</span>
        </div>
      </Tippy>
      <Modal show={showBlock} onHide={handleCloseBlock}>
        <Modal.Header closeButton>
          <Modal.Title>Block DMRID {props.value.id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><strong>Callsign</strong> {props.value.callsign}</p>
          <p><strong>Name</strong> {props.value.name}</p>
          <p>Please enter a brief comment as to why this DMRID should be blocked.</p>
          <Form.Control as="textarea" rows={3} onChange={(e) => setReason(e.target.value)} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => blockButton()} size="sm">Block</Button>
        </Modal.Footer>
      </Modal>

    </div>
  );

}

export default DisplayCallsign;

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import ToastComponent from "../../components/Toast/ToastComponent";
import { Tabs, Tab, Alert } from "react-bootstrap";
import "./Dashboard.css";
import axios from "axios";
import moment from "moment";
import Lastheard from "./Lastheard";
import ConnectedPeers from "./ConnectedPeers";
import OfflinePeers from "./OfflinePeers";
import Repeaters from "./Repeaters";
import OpenBridges from "./OpenBridges";
import Container from "../../components/Layout/Container";
import Panel from "../../components/Panel/Panel";

import dur from "../../utils/duration";

//import Lastheard from "./Lastheard";

function Dashboard({ cable }) {
  const [lastUpdated, setLastUpdated] = useState(moment());
  const [peers, setPeers] = useState();
  const [peersLastTime, setPeersLastTime] = useState();
  const [repeaters, setRepeaters] = useState(null);
  const [repeatersLastTime, setRepeatersLastTime] = useState();
  const [lastheard, _setLastheard] = useState();
  const [lastheardTime, setLastheardTime] = useState();
  const [alerts, setAlerts] = useState();
  const [showAlerts, setShowAlerts] = useState();

  const lastheardRef = React.createRef();

  const setLastheard = data => {
    lastheardRef.current = data;
    _setLastheard(data);
  };

  function getLastHeard() {
    axios
      .get(`${process.env.REACT_APP_API}/api/v1/dashboard/lastheard.json${lastheard && lastheardTime ? `?last_time=${lastheardTime}` : ''}`)
      .then(({ data }) => {
        if (lastheard) {
          updateLastheard(data.lastheard);
        } else {
          setLastheard(data.lastheard);
        }
        setLastheardTime(data.last_time)
      })
      .catch((error) => console.error(error));
  }


  function GetIndexOfLatestDate(lh) {
    if (lh === null || lh.length === 0) {
      return null;
    }
    return lh.reduce((accum, value, index) => {
      const newDate = value.starttime;
      return newDate < accum.maxDate ? { index, maxDate: newDate } : accum;
    }, { index: 0, maxDate: lh[0].starttime }).index;
  }

  function updateLastheard(messages) {
    if (lastheardRef.current || lastheard) {
      let tmplastheard = lastheardRef.current ? [...lastheardRef.current] : [...lastheard];
      for (let i = 0; i < messages.length; i++) {
        let mi = tmplastheard.findIndex(l => l.id === messages[i].id);
        if (mi === -1) {
          const last = GetIndexOfLatestDate(tmplastheard)
          tmplastheard[last] = messages[i];
        } else {
          tmplastheard[mi] = messages[i];
        }
      }
      setLastheard(tmplastheard);
    }
  }

  function refreshLastheardDuration(_lastheard) {
    if (_lastheard) {
      let tmplastheard = [..._lastheard];
      for (let i = 0; i < tmplastheard.length; i++) {
        if (!tmplastheard[i].endtime) {
          let entry = { ...tmplastheard[i] }
          entry.duration = dur(entry.starttime, new Date())
          tmplastheard[i] = entry
          setLastheard(tmplastheard);
        }
      }
    }
  };

  const getUserBrief = (alerts) => {
    axios
      .get(`${process.env.REACT_APP_API}/api/v1/user/brief.json`)
      .then(({ data }) => {
        usercheck(data.user, alerts)
      })
      .catch((error) => console.error(error));
  }


  const getPeers = () => {
    axios
      .get(`${process.env.REACT_APP_API}/api/v1/dashboard/peers.json${peers && peersLastTime ? `?last_time=${peersLastTime}` : ''}`)
      .then(({ data }) => {
        if (peersLastTime) {
          updatePeers(data.peers)
        } else {
          setPeers(data.peers);
        }
        setPeersLastTime(data.last_time);
      })
      .catch((error) => console.error(error));
  }

  function getRepeaters() {
    axios
      .get(`${process.env.REACT_APP_API}/api/v1/dashboard/repeaters.json${repeaters && repeatersLastTime ? `?last_time=${repeatersLastTime}` : ''}`)
      .then(({ data }) => {
        if (repeatersLastTime) {
          updateRepeaters(data.repeaters);
        } else {
          setRepeaters(data.repeaters);
        }
        setRepeatersLastTime(data.last_time);
      })
      .catch((error) => console.error(error));
  }

  function updatePeers(_peers) {
    if (peersLastTime && peers) {
      let tmpPeers = [...peers];
      for (let i = 0; i < _peers.length; i++) {
        let mi = tmpPeers.findIndex(l => l.peer.id === _peers[i].peer.id);
        tmpPeers[mi] = _peers[i];
      }
      setPeers(tmpPeers);;
    }
  }

  function updateRepeaters(_repeaters) {
    if (repeatersLastTime && repeaters) {
      let tmprepeaters = [...repeaters];
      for (let i = 0; i < _repeaters.length; i++) {
        let mi = tmprepeaters.findIndex(l => l.peer.id === _repeaters[i].peer.id);
        tmprepeaters[mi] = _repeaters[i];
      }
      setRepeaters(tmprepeaters);;
    }
  }

  function arrayEquals(a, b) {
    return Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index]);
  }

  function usercheck(_user, alerts) {
    let _alerts = []
    if (_user && _user.id !== 1) {
      if ( _user.dmrids.length === 0 )  { 
        _alerts.push(<>You do not have any DMRIDS assigned to your account. <a href={`/user/${JSON.parse(localStorage.getItem('user')).id}/DMRIDs`}>Add DMRIDs</a></>) 
      }
      if ( _user.peers.length === 0  )  { _alerts.push(<>You do not have any Hotspots defined. <a href={`/user/${JSON.parse(localStorage.getItem('user')).id}/Hotspots`}>Add Hotspots</a></>) }
      for ( let i = 0; i < _user.peers.length; i++ ) {
        if ( !_user.peers[i].online ) { _alerts.push(`${_user.peers[i].repeater ? 'Repeater' : 'Hotspot'} ${_user.peers[i].name ? `${_user.peers[i].name} -` : ''} ${_user.peers[i].callsign} (${_user.peers[i].id}) is currently OFFLINE`) }
      }
      if ( _alerts.length > 0 ) {
        if ( !arrayEquals(_alerts, alerts) ) {
          setAlerts(_alerts)
          setShowAlerts(true)
        }
      } else {
        setAlerts()
      }
    }
  }

  useEffect(() => {
    getLastHeard();
    cable.subscriptions.create({ channel: 'LastheardChannel' }, {
      received: (message) => updateLastheard([JSON.parse(message.l)])
    })
    getUserBrief(null);
    getPeers();
    getRepeaters();
    setLastUpdated(moment());
  }, [cable.subscriptions]);

  useEffect(() => {
    let refreshInterval = setInterval(() => {
      refreshLastheardDuration(lastheard);
    }, 1000);
    return () => clearInterval(refreshInterval);
  });

  useEffect(() => {
    const checkTimeSinceLastUpdate = () => {
      let now = moment();
      let duration = moment.duration(now.diff(lastUpdated));

      return parseInt(duration.asSeconds());
    };
    let refreshInterval = setInterval(() => {
      if (checkTimeSinceLastUpdate() >= 30) {
        getUserBrief(alerts ? [...alerts] : null);
        getLastHeard();
        getPeers();
        getRepeaters();
        setLastUpdated(moment());
      }
    }, 30000);
    return () => clearInterval(refreshInterval);;
  }, [lastUpdated]);


  function UserAlertShow() {
    if (alerts && showAlerts) {
      return (
        <Container>
          <Alert variant="danger" onClose={() => setShowAlerts(false)} dismissible>
            <Alert.Heading>You have some issues which might be preventing you from communicating via RAYNET DMR.</Alert.Heading>
            <ul>
              {alerts.map((al, index) => (
                <li key={`${index}`}>{al}</li>
              ))}
            </ul>
            <hr />
            <p>Please review your <a href={`/user/${JSON.parse(localStorage.getItem('user')).id}`}>Profile page</a>.</p>
          </Alert>
        </Container>
      )
    }
  }

  return (
    <div>
      <Container>
        <Helmet title="Raynet DMR | Dashboard" />
        <ToastComponent />
        <UserAlertShow />
        <Tabs defaultactivekey="Lastheard" id="dashboard-tab" className="">
          <Tab eventKey="Lastheard" title="Lastheard"><Panel><Lastheard lastheard={lastheard} /></Panel></Tab>
          <Tab eventKey="Connected_peers" title={`Connected Peers (${peers && peers.filter(p => p.peer.online === true).length})`}><Panel><ConnectedPeers onlinePeers={peers && peers.filter(p => p.peer.online === true)} /></Panel></Tab>
          {JSON.parse(localStorage.getItem('admin'))
            ? (<Tab eventKey="Offline_peers" title={`Offline Peers (${peers && peers.filter(p => p.peer.online === false).length})`}><Panel><OfflinePeers offlinePeers={peers && peers.filter(p => p.peer.online === false)} /></Panel></Tab>)
            : ''
          }
          <Tab eventKey="Repeaters" title={`Repeaters (${repeaters ? repeaters.length : '-'})`}><Panel><Repeaters repeaters={repeaters} /></Panel></Tab>
          <Tab eventKey="OpenBridges" title="OpenBridges"><Panel><OpenBridges /></Panel></Tab>
          <Tab eventKey="net_schedules" title="Net Schedules"><Panel></Panel></Tab>
        </Tabs>
      </Container>
    </div>
  );
}

export default Dashboard;

import React, { useEffect, useState } from "react";
import Panel from "../../components/Panel/Panel";
import Container from "../../components/Layout/Container";
import Table from "../../components/Table/Table";
import axios from "axios";
import LoadingSpinner from "../../components/Helper/LoadingSpinner";
import toast from "react-hot-toast";
import ButtonSure from "../../components/Helper/ButtonSure";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function BlockedUsers({ setPageTitle }) {
    setPageTitle("Blocked Users");
    const [blockedUsers, setBlockedUsers] = useState(null);

    const getBlockedUsers = () => {
        axios
            .get(`${process.env.REACT_APP_API}/api/v1/blacklists.json`)
            .then(({ data }) => {
                setBlockedUsers(data.blacklist);
            })
            .catch((error) => console.error(error));
    }

    const unblock = (id) => {
        let deleteBlock =
            axios.delete(`${process.env.REACT_APP_API}/api/v1/blacklist/${id}.json`)
            .then(({ data }) => {
                setBlockedUsers(blockedUsers.filter(s => s.id !== id));
              })
        toast.promise(deleteBlock, {
            loading: "Unblocking user...",
            success: "User unblocked",
            error: "Failed to unblock user",
        });

    }

    useEffect(() => {
        getBlockedUsers();
    }, []);

    if (!blockedUsers) { return (<LoadingSpinner title="Raynet DMR | Blocked Users" />); }

    const gridOptions = {
        getRowId(data) {
            return data.data.id;
        },
        paginationPageSize: 30,
        columnDefs: [
            {
                headerName: "DMRID",
                field: "dmrid",
            }, {
                headerName: "Callsign",
                field: "callsign",
            }, {
                headerName: "Name",
                field: "name",
            }, {
                headerName: "Reason",
                field: "reason",
                autoHeight: true,
            }, {
                headerName: "Blocked By",
                field: "user",
                cellRenderer: function (params) {
                    return (<a href={`/user/${params.data.user.id}`}>{params.data.user.name}</a>)
                }
            }, {
                headerName: "Action",
                cellRenderer: function (params) {
                    return (<ButtonSure onClick={() => unblock(params.data.id)}
                    actionName={<FontAwesomeIcon fixedWidth icon={faTrash} />}
                    tooltip="Unblock user"
                    >
                        <p>Once deleted this DMRID will be permitted to transmit via the system again.</p>
                    </ButtonSure>)
                }
            }
        ]
    }

    return (
        <Container>
            <Panel>
                <p>This is a list of DMRIDs which have been blocked from communication on this system.</p>
                <Table
                    gridOptions={gridOptions}
                    rowData={blockedUsers}
                />
            </Panel>
        </Container>
    );
}

export default BlockedUsers;

import React, { useState } from "react";
import { Container, Button } from "react-bootstrap";
import axios from "axios";
import toast from "react-hot-toast";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Table from "../../Table/Table";
import DisplayHotspotName from "../../CellRenderers/DisplayHotspotName";
import ButtonSure from "../../Helper/ButtonSure";

function StaticUsers({ staticUsers }) {
  const [tgstatic, setTgstatic] = useState(staticUsers);

  const deleteStaticButton = (id) => {
    let deleteStatic =
      axios.delete(`${process.env.REACT_APP_API}/api/v1/static_group/${id}.json`)
      .then(() => setTgstatic(tgstatic.filter(statictg => statictg.id !== id)) )
    toast.promise(deleteStatic, {
      loading: "Deleting...",
      success: "Static Talkgroup Deleted",
      error: "Failed to Delete Static Talkgroup",
    });
  }


  const gridOptions = {
    getRowId(data) {
      return data.data.id;
    },
    paginationPageSize: 15,
    rowHeight: 50,
    columnDefs: [
      {
        headerName: "User",
        width: 250,
        field: "user",
        cellRenderer: function(params) {
          return <a href={`/user/${params.value.id}`}>{params.value.name}</a>
        }
      }, {
        headerName: "Hotspot",
        field: "via",
        cellRenderer: DisplayHotspotName,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains'],
          textFormatter: (v) => {
            return v.peer ? `${v.peer.callsign} ${v.peer.location} ${v.peer.id}`.toString().toLowerCase() : v.toString().toLowerCase()
          },
        }
      }, {
        headerName: "Enabled",
        field: "active",
        cellRenderer: function(params) {
          return <Button size="sm" variant={params.value ? 'success' : 'danger'} disabled={true}>{params.value ? 'Active' : 'Disabled'}</Button>
        }
      }, {
        headerName: "Derived ACL",
        field: "",
        cellRenderer: function(params) {
          return <div className="btn-group">
            <Button size="sm" variant={params.data.tx ? 'success' : 'danger'} disabled={true}>TX</Button>
            <Button size="sm" variant={params.data.rx ? 'success' : 'danger'} disabled={true}>RX</Button>
            </div>
        }
      }, {
        headerName: "Status",
        field: "via",
        comparator: (valueA, valueB) => valueA.peer.online - valueB.peer.online,
        cellRenderer: function(params) {
          return (
            params.value.peer && `${params.value.peer.online ? 'Online' : 'Offline'}`
          )
        }
      }, {
        headerName: "Action",
        field: "id",
        width: 250,
        cellRenderer: function (params) {
          return <ButtonSure size="sm" 
          variant="danger" 
          onClick={() => deleteStaticButton(params.value)}
          tooltip="Remove Static user"
          actionName={<FontAwesomeIcon fixedWidth icon={faTrash} />}
          >
            Removing this static entry will stop sending traffic to this destination for this talkgroup
          </ButtonSure>
        }
      }
    ]
  }
  return (
    <div>
      <p>Users who have defined this talkgroup as a static for themselves.</p>
      <br/>
      <Container>
          <Table
            gridOptions={gridOptions}
            rowData={tgstatic}
          />
      </Container>


    </div>
  )
}


export default StaticUsers;

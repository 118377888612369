
import React, { useState, useEffect } from "react";
import axios from "axios";
import Container from "../../components/Layout/Container";
import LoadingSpinner from "../../components/Helper/LoadingSpinner";
import Panel from "../../components/Panel/Panel";
import { LineChart, Line, XAxis, YAxis, ZAxis, CartesianGrid, Tooltip, Legend, Label, ResponsiveContainer } from 'recharts';
import { PieChart, Pie, Sector, Cell, Treemap, ScatterChart, Scatter, BarChart, Bar } from 'recharts';
import Table from "../../components/Table/Table";
import { Col, Row } from "react-bootstrap";


function Stats({ setPageTitle }) {
  setPageTitle('Stats')
  const [stats, setStats] = useState();

  const getStats = () => {
    axios
      .get(`${process.env.REACT_APP_API}/api/v1/dashboard/stats.json`)
      .then(({ data }) => { setStats(data.stats); })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    getStats();
  }, []);

  if (!stats) { return (<LoadingSpinner />) }

  return (
    <>
      <Container>
        <Panel>
          <h1>DMR Server Usage Statistics</h1>
          <Row>
            <Col>
              <h3>Summary Statistics</h3>
              <ul>
                <li>{stats.user_count} Active Users</li>
                <li>{stats.admin_count} Group Admins</li>
                <li>{stats.group_count} Groups Registered</li>
                <li>{stats.talkgroup_count} Talkgroups</li>
                <li>{stats.hotspots_count} Hotspots registered</li>
                <li>{stats.dmrid_count} DMRIDs registered</li>
                <li>{stats.over_count} Overs for the last week</li>
              </ul>
            </Col>
            <Col>
              <h3>User Account Creation / Week</h3>
              <LineChart width={1000} height={400} data={stats.user_creation}>
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <XAxis dataKey="Week" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="count" stroke="#8884d8" activeDot={{ r: 8 }} />
              </LineChart>
            </Col>
          </Row>
        </Panel>
        <Panel>
          <h2>Hotspot Statistics</h2>
          <h3>Duplex / Simplex Hotspots</h3>
          <PieChart width={300} height={300}>
            <Pie dataKey="count"
              startAngle={180}
              endAngle={0}
              data={stats.hotspot.type}
              isAnimationActive={true}
              cx="50%"
              cy="50%"
              outerRadius={80}
              fill="#8884d8"
              label={({ cx,
                cy,
                midAngle,
                innerRadius,
                outerRadius,
                count,
                index }) => {
                const RADIAN = Math.PI / 180;
                // eslint-disable-next-line
                const radius = 25 + innerRadius + (outerRadius - innerRadius);
                // eslint-disable-next-line
                const x = cx + radius * Math.cos(-midAngle * RADIAN);
                // eslint-disable-next-line
                const y = cy + radius * Math.sin(-midAngle * RADIAN);

                return (<text x={x} y={y} fill="#8884d8"
                  textAnchor={x > cx ? "start" : "end"}
                  dominantBaseline="central"
                >{stats.hotspot.type[index].type} ({count})</text>)
              }}
            />
            <Legend />
          </PieChart>

          <Row>
            <Col>
              <h3>Peer Hardware</h3>
              <Table
                gridOptions={{
                  getRowId(data) {
                    return data.data.model;
                  },
                  paginationPageSize: 10,
                  columnDefs: [
                    {
                      headerName: "Model",
                      field: "model",
                    }, {
                      headerName: "Count",
                      field: "count",
                      sort: "desc",
                    }
                  ]

                }}
                rowData={stats.hotspot.models}
              />
            </Col>
            <Col>
              <h3>Peer Software</h3>
              <Table
                gridOptions={{
                  getRowId(data) {
                    return data.data.software;
                  },
                  paginationPageSize: 10,
                  columnDefs: [
                    {
                      headerName: "Software",
                      field: "software",
                    }, {
                      headerName: "Count",
                      field: "count",
                      sort: "desc",
                    }
                  ]

                }}
                rowData={stats.hotspot.software}
              />
            </Col>

          </Row>
          <Row>
          <h3>Frequencies</h3>
            <Col>
              <PieChart width={800} height={800}>
                <Pie
                  dataKey="count"
                  data={stats.hotspot.frequency}
                  isAnimationActive={true}
                  outerRadius={300}
                  fill="#8884d8"
                  cx="50%" cy="50%"
                  label={({ cx,
                    cy,
                    midAngle,
                    innerRadius,
                    outerRadius,
                    count,
                    index }) => {
                    const RADIAN = Math.PI / 180;
                    // eslint-disable-next-line
                    const radius = 25 + innerRadius + (outerRadius - innerRadius);
                    // eslint-disable-next-line
                    const x = cx + radius * Math.cos(-midAngle * RADIAN);
                    // eslint-disable-next-line
                    const y = cy + radius * Math.sin(-midAngle * RADIAN);

                    return (<text x={x} y={y} fill="#8884d8"
                      textAnchor={x > cx ? "start" : "end"}
                      dominantBaseline="central"
                    >{stats.hotspot.frequency[index].frequency} ({count})</text>)
                  }}
                />
                <Legend />
              </PieChart>
            </Col>
            <Col>
              <Table
                gridOptions={{
                  getRowId(data) {
                    return data.data.frequency;
                  },
                  paginationPageSize: 20,
                  columnDefs: [
                    {
                      headerName: "Frequency",
                      field: "frequency",
                    }, {
                      headerName: "Count",
                      field: "count",
                      sort: "desc",
                    }
                  ]

                }}
                rowData={stats.hotspot.frequency}
              />
            </Col>
          </Row>
        </Panel>
        <Panel>
          <h2>RAYNET-UK Call Statistics</h2>
          <Row>
            <Col>
              <h3>Traffic per month</h3>
              <LineChart width={800} height={400} data={stats.callog_month}>
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="count" stroke="#8884d8" activeDot={{ r: 8 }} />
              </LineChart>
            </Col><Col>
              <h3>Total Traffic for the week</h3>
              <LineChart width={800} height={400} data={stats.callog_week}>
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <XAxis dataKey="week" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="count" stroke="#8884d8" activeDot={{ r: 8 }} />
              </LineChart>
            </Col></Row>
        </Panel>
        <Panel>
          <h2>RAYNET-UK Group Statistics</h2>
          <Row>
            <Col>
              <h3>RAYNET-UK Zones User Count</h3>
              <BarChart width={730} height={250} data={stats.zone_user_count}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="zone" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="count" fill="#8884d8" />
              </BarChart>
            </Col>
            <Col>
              <h3>Talkgroups with highest static peers</h3>
              <BarChart width={730} height={250} data={stats.talkgoup_statics}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="talkgroup" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="count" fill="#8884d8" />
              </BarChart>
            </Col>
          </Row>
        </Panel>
      </Container>
    </>
  );

}

export default Stats;
import React, { useEffect, useState } from "react";
import Panel from "../../components/Panel/Panel";
import Container from "../../components/Layout/Container";
import Table from "../../components/Table/Table";
import moment from "moment";
import axios from "axios";
import LoadingSpinner from "../../components/Helper/LoadingSpinner";
import DisplayTime from "../../components/CellRenderers/DisplayTime";
import DisplayTalkgroup from "../../components/CellRenderers/DisplayTalkgroup";
import DisplayGroupname from "../../components/CellRenderers/DisplayGroupname";
import DisplayCallsign from "../../components/CellRenderers/DisplayCallsign";
import DisplayHotspotName from "../../components/CellRenderers/DisplayHotspotName";
import DisplayName from "../../components/CellRenderers/DisplayName";

import dur from "../../utils/duration";

function NonMembers({setPageTitle}) {
    setPageTitle("Non Members")
  const [nonmembers, setNonmembers] = useState(null);

  const getNonmembers = () => {
    axios
      .get(`${process.env.REACT_APP_API}/api/v1/users/nonmembers.json`)
      .then(({ data }) => {
        setNonmembers(data.nonmembers);
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    getNonmembers();
  }, []);

  if (!nonmembers) { return (<LoadingSpinner title="Raynet DMR | Non Members" />); }

  const gridOptions = {
    getRowId(data) {
      return data.data.id;
    },
    getRowStyle: data => {
      if (!data.data.endtime) {
        return { background: 'pink' }
      }
    },
    paginationPageSize: 30,
    rowHeight: 50,
    columnDefs: [
      {
        headerName: "Callsign (DMR ID)",
        field: "dmrid",
        sortable: false,
        cellRenderer: DisplayCallsign
      }, {
        headerName: "Name",
        field: "dmrid",
        sortable: false,
        cellRenderer: DisplayName
      }, {
        headerName: "Via",
        width: 250,
        field: "via",
        cellRenderer: DisplayHotspotName
      }, {
        headerName: "Talkgroup",
        field: "talkgroup",
        width: 250,
        cellRenderer: DisplayTalkgroup
      }, {
        headerName: "RAYNET-UK Group",
        field: "talkgroup.raynet_group",
        cellRenderer: DisplayGroupname
      }, {
        headerName: `Time (${moment.tz(JSON.parse(localStorage.getItem('user')).timezone).zoneAbbr()})`,
        field: "starttime",
        sort: "desc",
        cellRenderer: DisplayTime
      }, {
        headerName: "Duration",
        valueGetter: function (params) {
          return `
          ${params.data.endtime
              ? dur(params.data.starttime, params.data.endtime)
              : dur(params.data.starttime, new Date())
            }`
        }
      }
    ]
  }

  return (
    <Container>
      <Panel>
        <p>The Last 50 People who are not members of this system but have keyed up via public repeaters etc</p>
        <Table
          gridOptions={gridOptions}
          rowData={nonmembers}
        />
      </Panel>
    </Container>
  );
}

export default NonMembers;

import React from "react";

function DisplayName (props) {
  let name = '';
  if (props.value.name) {
    name = props.value.name;
  } else {
    name = props.value.trustee;
  }
  let user_id = props.value.user_id;

  return (
    JSON.parse(localStorage.getItem('admin')) && !props.value.nonmember ? <a href={`/user/${user_id}`}>{name}</a> : name 
  )
}

export default DisplayName;

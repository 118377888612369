import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserXmark,
} from "@fortawesome/free-solid-svg-icons";

function DisplayCallsign ({dmrid}) {

  return (
    <>
    { localStorage.getItem('qrz') 
    ? <a href={`${localStorage.getItem('qrz')}${dmrid.callsign}`} rel="noopener noreferrer" target="_blank"><strong>{dmrid.callsign}</strong></a>
    : <strong>{dmrid.callsign}</strong>
    }
    {dmrid.nonmember ? <> { localStorage.getItem('admin')
      ? <a href={`/blacklist/add/${dmrid.id}`}><sup><FontAwesomeIcon fixedWidth icon={faUserXmark} className="btn-danger"/></sup></a>
      : <sup><FontAwesomeIcon fixedWidth icon={faUserXmark} /></sup>
    } </>
    : ''
    }
    <br/>({dmrid.id})
    </>
  );
}

export default DisplayCallsign;

import React from "react";
import Table from "../../../components/Table/Table";
import moment from "moment";

import DisplayTime from "../../../components/CellRenderers/DisplayTime";
import DisplayCallsign from "../../../components/CellRenderers/DisplayCallsign";
import DisplayHotspotName from "../../../components/CellRenderers/DisplayHotspotName";
import DisplayName from "../../../components/CellRenderers/DisplayName";

import dur from "../../../utils/duration";

function Lastheard({lastheard}) {
  
  const gridOptions = {
    getRowId(data) {
      return data.data.id;
    },
    getRowStyle: data => {
      if (!data.data.endtime) {
        return { background: 'pink' }
      }
    },
    paginationPageSize: 30,
    rowHeight: 50,
    columnDefs: [
      {
        headerName: "Callsign (DMR ID)",
        field: "dmrid",
        sortable: false,
        cellRenderer: DisplayCallsign,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains'],
          textFormatter: (d) => {
            return d.callsign ? `${d.callsign} ${d.id}`.toString().toLowerCase() : d.toString().toLowerCase()
          },
        },
      }, {
        headerName: "Name",
        field: "dmrid",
        sortable: false,
        cellRenderer: DisplayName
      }, {
        headerName: "Via",
        width: 250,
        field: "via",
        cellRenderer: DisplayHotspotName,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains'],
          textFormatter: (v) => {
            return v.peer ? `${v.peer.callsign} ${v.peer.location} ${v.peer.id}`.toString().toLowerCase() : v.toString().toLowerCase()
          },
        }
      }, {
        headerName: `Time (${moment.tz(JSON.parse(localStorage.getItem('user')).timezone).zoneAbbr()})`,
        field: "starttime",
        sort: "desc",
        cellRenderer: DisplayTime
      }, {
        headerName: "Duration",
        cellRenderer: function (params) {
          return (
            params.data.endtime
              ? dur(params.data.starttime, params.data.endtime)
              : <strong>Talking<br />{params.data.duration}</strong>
          )
        }
      }
    ]
  }

  return (
    <div>
        <Table
          gridOptions={gridOptions}
          rowData={lastheard}
        />
    </div>
  );
}

export default Lastheard;

import React from "react";
import Container from "../../Layout/Container";
import Table from "../../Table/Table";
import DisplayTime from "../../CellRenderers/DisplayTime";
import DisplayTalkgroup from "../../CellRenderers/DisplayTalkgroup";
import DisplayCallsign from "../../CellRenderers/DisplayCallsign";
import DisplayHotspotName from "../../CellRenderers/DisplayHotspotName";
import moment from "moment";

import dur from "../../../utils/duration";

function Lastheard({ lastheard }) {

  const gridOptions = {
    getRowId(data) {
      return data.data.id;
    },
    paginationPageSize: 15,
    rowHeight: 50,
    columnDefs: [
      {
        headerName: "Via",
        width: 250,
        field: "via",
        cellRenderer: DisplayHotspotName
      }, {
        headerName: "Callsign (DMR ID)",
        field: "dmrid",
        cellRenderer: DisplayCallsign
      }, {
        headerName: "Talkgroup",
        field: "talkgroup",
        width: 250,
        comparator: (valueA, valueB) => valueA.number - valueB.number,
        filterParams: {
          filterOptions: ['contains'],
          textFormatter: (d) => {
            return d.number ? `${d.number} ${d.raynet_group.name} ${d.name}`.toString().toLowerCase() : d.toString().toLowerCase()
          },
        },
        cellRenderer: DisplayTalkgroup
      }, {
        headerName: "Slot",
        minWidth: 50,
        field: "timeslot"
      }, {
        headerName: `Time (${moment.tz(JSON.parse(localStorage.getItem('user')).timezone).zoneAbbr()})`,
        field: "starttime",
        cellRenderer: DisplayTime
      }, {
        headerName: "Duration",
        valueGetter: function (params) {
          return `
          ${params.data.endtime
              ? dur(params.data.starttime, params.data.endtime)
              : dur(params.data.starttime, new Date())
            }`
        }
      }
    ]
  }


  return (
    <Container>
      <p>Here you can see where you have last been heard on the system</p>
        <Table
          gridOptions={gridOptions}
          rowData={lastheard}
        />
      </Container>
  )
}


export default Lastheard;

import Table from "../../components/Table/Table";
import Panel from "../../components/Panel/Panel";
import { Container } from "react-bootstrap";

function Repeaters({repeaters}) {

  const gridOptions = {
    getRowId(data) {
      return data.id;
    },
    paginationPageSize: 30,
    rowHeight: 50,
    columnDefs: [
      {
        headerName: "ID",
        field: "id",
      }, {
        headerName: "Callsign",
        field: "callsign",
      }, {
        headerName: "Trustee",
        field: "trustee",
      }
    ]
  }

  return (
    <Container>
      <Panel>
        <h1>Repeaters</h1>
          <Table
            gridOptions={gridOptions}
            rowData={repeaters}
          />
          </Panel>
    </Container>
  );
}

export default Repeaters;

import React, { useState } from "react";
import { Button, Row, Col, Table, InputGroup, Form } from "react-bootstrap";
import axios from "axios";
import toast from "react-hot-toast";
import "./SendPage.css";


function SendPage(props) {
  const [input, setInput] = useState('');
  const [callsigns, setCallsigns] = useState([]);
  const [isKeyReleased, setIsKeyReleased] = useState(false);
  const [message, setMessage] = useState();
  const [txgroup, setTxgroup] = useState();

  const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();

    if ((key === ',' || key === ' ' || key === 'Enter') && trimmedInput.length && !callsigns.includes(trimmedInput)) {
      e.preventDefault();
      setCallsigns(prevState => [...prevState, trimmedInput]);
      setInput('');
    }

    if (key === "Backspace" && !input.length && callsigns.length && isKeyReleased) {
      const callsignsCopy = [...callsigns];
      const poppedTag = callsignsCopy.pop();
      e.preventDefault();
      setCallsigns(callsignsCopy);
      setInput(poppedTag);
    }

    setIsKeyReleased(false);
  };

  const onBlur = (e) => {
    const trimmedInput = input.trim();
    if (trimmedInput.length > 0 ) {
      setCallsigns(prevState => [...prevState, trimmedInput]);
      setInput('')
    }
  }

  const onKeyUp = () => {
    setIsKeyReleased(true);
  }

  const onChange = (e) => {
    const { value } = e.target;
    let _callsigns = value.toUpperCase().split(/[,\s\n]/gm)
    _callsigns = _callsigns.filter(callsign => {
      if (callsign == '') {
        return false
      }
      return true
    })
    if (_callsigns.length > 1) {
      setCallsigns(prevState => [...prevState, ..._callsigns])
      setInput('')
    } else {
      setInput(value.toUpperCase());
    }
  };
  const deleteTag = (index) => {
    setCallsigns(prevState => prevState.filter((callsign, i) => i !== index))
  }

  function sendMsgBack() {
    let sendMSG = axios
      .post(`${process.env.REACT_APP_API}/api/gui/v1/page.json`, {
        message: message,
        callsigns: callsigns,
        groups: [ parseInt(txgroup, 10) ]
      })
      .then(({ data }) => {
        if (data.status === 'ok') {
        } else {
          toast.error("Failed to send Page")
        }
      })
      .catch((error) => console.error(error));
    toast.promise(sendMSG, {
      loading: "Sending Page...",
      success: "Page Sent",
      error: "Failed to send Page",
    });
  }

  function browserPage() {
    sendMsgBack()
    setMessage("")
    setCallsigns([])
  }

  return (
    <Row>
      <Col>
        <Table borderless>
          <thead>
            <tr><th>Send Paging Message via Browser</th></tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <InputGroup>
                  <InputGroup>
                    <div className="input-container">
                      {callsigns.map((callsign, index) => <div key={index} className="input-callsign-tag">{callsign} <button onClick={() => deleteTag(index)}>x</button></div>)}
                      <input type="text"
                      className="input-container-input"
                        value={input}
                        placeholder="Callsigns"
                        onKeyDown={onKeyDown}
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    </div>
                    {props.groups ? <>
                      <Form.Select aria-label="Select TXGroup" onChange={(e) => setTxgroup(e.target.value)}>
                        <option key="00">-- Select Group --</option>
                        {props.groups ? props.groups.map((raynet_group, index) => (
                          <option key={index} value={raynet_group.raynet_group.id}>{raynet_group.raynet_group.name}</option>
                        )
                        )
                          : ''}
                      </Form.Select>
                    </>
                      : ''}
                  </InputGroup>
                  <Form.Control type="text" placeholder="Message" value={message} onChange={(e) => setMessage(e.target.value)} />
                  <Button size="sm" variant="success" onClick={() => browserPage()} disabled={!(message && callsigns)}>Send Page</Button>
                </InputGroup>
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  )

}

export default SendPage;
import axios from "axios";
import React, { useEffect, useState } from "react";
import LoadingSpinner from "../../components/Helper/LoadingSpinner";
import Container from "../../components/Layout/Container";
import Panel from "../../components/Panel/Panel";
import Table from "../../components/Table/Table";

function RepeaterTrustees ({setPageTitle}) {

  setPageTitle("Repeater Keepers")
  const [repeaters, setRepeaters] = useState(null);

  const getRepeaters = () => {
    axios
      .get(`${process.env.REACT_APP_API}/api/v1/user/repeatertrustees.json`)
      .then(({ data }) => {
        setRepeaters(data.repeaterkeepers);
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    getRepeaters();
  }, []);

  if (!repeaters) { return (<LoadingSpinner title="Raynet DMR | Repeater Trustees" />); }

  const gridOptions = {
    getRowId(data) {
      return data.data.id;
    },
    paginationPageSize: 30,
    columnDefs: [
      {
        headerName: "Callsign",
        field: "callsign",
        cellRenderer: function (params) {
          return (<a href={`${localStorage.getItem('qrz')}${params.value}`} rel="noopener noreferrer" target="_blank"><strong>{params.value}</strong></a>)
        }

      }, {
        headerName: "Name",
        field: "trustee.name",
        cellRenderer: function (params) {
          return (<a href={`/user/${params.data.trustee.id}`}>{params.value}</a>)
        }
      }, {
        headerName: "Registered",
        field: "registered"
      }, {
        headerName: "Repeater Keeper",
        field: "trustee.repeaterkeeper"
      }
    ]
  }

  return (
    <Container>
      <Panel>
        <p>Below are the RAYNET DMR members who are also Public Repeater Trustees.</p>
        <Table
          gridOptions={gridOptions}
          rowData={repeaters}
        />
      </Panel>
    </Container>
  );
}

export default RepeaterTrustees;
import React from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import DisplayGroupname from "../../CellRenderers/DisplayGroupname";
import NiceDate from "../../Helper/NiceDate";
import LoadingSpinner from "../../Helper/LoadingSpinner";
import toast from "react-hot-toast";
import Table from "../../Table/Table";
import ShortTimezone from "../../Helper/ShortTimezone";
import ButtonSure from "../../Helper/ButtonSure";
import Panel from "../../Panel/Panel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRotateBack,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Tippy from "@tippyjs/react";


function WaitingEnrolment({ tokens, groupid, setTokens }) {

  const deleteTokenButton = (id) => {
    let deleteToken =
      axios.delete(`${process.env.REACT_APP_API}/api/v1/token/${id}.json`)
        .then(setTokens(tokens.filter(token => token.id !== id)))
    toast.promise(deleteToken, {
      loading: "Deleting...",
      success: "Token Deleted",
      error: "Failed to Delete Token",
    });
  }

  const resendTokenMailButton = (id) => {
    let resendTokenMail =
      axios.get(`${process.env.REACT_APP_API}/api/v1/token/resend/${id}.json`)
        .then(({ data }) => {
          let tmptokens = [...tokens]
          let index = tmptokens.findIndex(t => t.id === id)
          tmptokens[index] = data
          //setTokens(tmptokens)
        })
    toast.promise(resendTokenMail, {
      loading: "Resending...",
      success: "Mail resent",
      error: "Could not resend mail",
    });

  }

  const gridOptions = {
    getRowId(data) {
      return `token-${data.data.id}`;
    },
    paginationPageSize: 30,
    columnDefs: [
      {
        headerName: "E-Mail",
        minWidth: 250,
        field: "email",
        cellRenderer: function (params) {
          return (<a href={`mailto:${params.value}`}>{params.value}</a>)
        }
      }, {
        headerName: "Token",
        minWidth: 200,
        field: "token",
      }, {
        headerName: "Group",
        field: "raynet_group",
        cellRenderer: DisplayGroupname,
      }, {
        headerName: `Created (${ShortTimezone()})`,
        field: "created_at",
        minWidth: 200,
        cellRenderer: function (params) {
          return <NiceDate date={params.value} />
        }
      }, {
        headerName: "Last Sent",
        field: "lastsent",
        minWidth: 200,
        cellRenderer: function (params) {
          return <NiceDate date={params.value} />
        }
      }, {
        headerName: "Sent Count",
        minWidth: 50,
        field: "count",
      }, {
        headerName: "By",
        field: "created_by.name",
        minWidth: 200,
      }, {
        headerName: "Action",
        field: "id",
        minWidth: 300,
        cellRenderer: function (params) {
          return (
            <div className="btn-group text-nowrap">
              <Tippy content="Resend Registration Mail">
                <Button variant="success" size="sm" onClick={() => resendTokenMailButton(params.value)}><FontAwesomeIcon fixedWidth icon={faArrowRotateBack} /></Button>
              </Tippy>
              <ButtonSure onClick={() => deleteTokenButton(params.value)}
                actionName={<FontAwesomeIcon fixedWidth icon={faTrash} />}
                tooltip="Remove registration"
              >
                <p>This will revoke the registration token and stop the user from registrating.</p>
              </ButtonSure>
            </div>
          )
        }
      }
    ]
  }

  if (!tokens) { return (<LoadingSpinner title={`Raynet DMR | Users`} />); }

  return (
    <Panel>
      <h3>Waiting Enrolment</h3>
      <p>These users have been sent a registration e-mail to the address listed, however they have yet to click on the link in the e-mail to complete the registration process.</p>
      <Table
        gridOptions={gridOptions}
        rowData={tokens}
      />
    </Panel>
  );

}


export default WaitingEnrolment;
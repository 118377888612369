import React from "react";
import Moment from 'react-moment';


function NiceDate({date, timeformat=false}) {

  if (date) {
    return (
      <Moment format={ timeformat ? timeformat : JSON.parse(localStorage.getItem('user')).timeformat } tz={JSON.parse(localStorage.getItem('user')).timezone}>{ date }</Moment>
    )
  }
}

export default NiceDate;
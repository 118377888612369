import React from "react";

function DisplayHotspotName(props) {

  return (
    <div>
      {props.value.peer ? <>
        {props.value.peer.url
          ? <a href={props.value.peer.url.startsWith('http') ? props.value.peer.url : `https://${props.value.peer.url}` } target="_blank" rel="noopener noreferrer"><strong className="callsign">{props.value.peer.callsign}</strong></a>
          : <strong className="callsign">{props.value.peer.callsign}</strong>
        }{' '}<span className="callsign">({props.value.peer.id})</span> {props.value.peer.repeater ? <span className='badge bg-secondary badge-sm'>RPTR</span> : ''}
        <br />{props.value.peer.location}
      </>
        : props.value.openbridge ? <>
          {props.value.openbridge.url
            ? <a href={props.value.openbridge.url} target="_blank" rel="noopener noreferrer"><strong>{props.value.openbridge.name}</strong></a>
            : <strong>{props.value.openbridge.name}</strong>
          }{' '}<span className="callsign">({props.value.openbridge.remotedmrid})</span> <span className='badge bg-secondary badge-sm'>BRIDGE</span>
          <br />{props.value.openbridge.location}
        </>
          : <>
            <strong className="callsign">{props.value.radioid.callsign}</strong> <span className="callsign">({props.value.radioid.id})</span>
            {props.value.radioid.repeater ? <span className='badge bg-secondary badge-sm'>RPTR</span> : ''}
          </>
      }
    </div>
  );

}

export default DisplayHotspotName;
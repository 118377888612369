import Table from "../../components/Table/Table";
import Panel from "../../components/Panel/Panel";
import { Container } from "react-bootstrap";
import DisplayGroupname from "../../components/Helper/DisplayGroupname";

function Users({users}) {

  const gridOptions = {
    getRowId(data) {
      return data.data.id;
    },
    paginationPageSize: 30,
    columnDefs: [
      {
        headerName: "Surname",
        field: "surname",
        cellRenderer: function (params) {
          return (<a href={`/user/${params.data.id}`}>{params.value}</a>)
        }
      }, {
        headerName: "Firstname",
        field: "firstname",
      }, {
        headerName: "E-Mail",
        field: "email",
      }, {
        headerName: "Active",
        field: "active",
      }, {
        headerName: "Groups",
        field: "memberships",
        autoHeight: true,
        width: 1000,
        cellRenderer: function(params) {
          return (
            params.value.map((membership, index) => 
              <><DisplayGroupname key={index} group={membership.raynet_group} />{' '}</>
              )
          )
        }
      }
    ]
  }

  return (
    <Container>
      <Panel>
        <h1>Registered Users</h1>
          <Table
            gridOptions={gridOptions}
            rowData={users}
          />
          </Panel>
    </Container>
  );
}

export default Users;
import React from "react";
import Table from "../../components/Table/Table";
import moment from "moment";
import "./Dashboard.css";
import DisplayName from "../../components/CellRenderers/DisplayName";
import DisplayTime from "../../components/CellRenderers/DisplayTime";
import DisplayCallsign from "../../components/CellRenderers/DisplayCallsign";
import LoadingSpinner from "../../components/Helper/LoadingSpinner";



function Repeaters({repeaters}) {

  if (!repeaters) { return ( <LoadingSpinner title={`Raynet DMR | Dashboard`} /> ); }

  const gridOptions = {
    getRowId(data) {
      return data.data.peer.id;
    },
    paginationPageSize: 30,
    columnDefs: [
      {
        headerName: "Callsign (DMR ID)",
        field: "peer",
        sortable: false,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains'],
          textFormatter: (d) => { 
            return d.callsign ? `${d.callsign} ${d.id}`.toString().toLowerCase() : d.toString().toLowerCase()
          },
        },
        cellRenderer: DisplayCallsign
      }, {
        headerName: "Trustee",
        field: "peer",
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains'],
          textFormatter: (d) => {
            return d.trustee ? d.trustee.toString().toLowerCase() : d
          },
        },
        cellRenderer: DisplayName
      }, {
        headerName: `Connected (${moment.tz(JSON.parse(localStorage.getItem('user')).timezone).zoneAbbr()})`,
        field: "peer.connected",
        sort: "desc",
        cellRenderer: DisplayTime
      }, {
        headerName: "Uptime",
        valueGetter: function (params) {
          return params.data.peer.connected ? `${moment.utc(params.data.peer.connected).fromNow()}` : 'Never connected'
        }
      }, {
        headerName: "Notified",
        field: "peer.notified",
        cellRenderer: function(params) {
          return (params.value ? 'YES' : 'NO')
        }
      }
    ]
  }

  return (
    <div>
          <Table
            gridOptions={gridOptions}
            rowData={repeaters}
          />
    </div>
  );

}

export default Repeaters;

import React from "react";
import { Spinner } from "react-bootstrap";
import logo from "../../images/Raynet-logo.png";

import "./LoadingSpinner.css";


function LoadingSpinner() {

  return (
    <>
    <div className="spinner-box">
      <div align="center">
      <img src={logo} className="border-radius-20 center-img" width="100" height="100" alt="Raynet-UK DMR Logo" />
        <p className="sign" align="center">Raynet-UK DMR</p>
    <Spinner animation="border" variant="light" >
      <span className="visually-hidden">Loading...</span>
    </Spinner>
    </div>
    <br/>
    <h2 align="center" className="loading-white">One moment</h2>
    <p align="center" className="loading-white">Loading Content</p>
    </div>
    </>
  )

}

export default LoadingSpinner;
import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import LoadingSpinner from "../../Helper/LoadingSpinner";
import { Container } from "react-bootstrap";
import Table from "../../Table/Table";
import DisplayTime from "../../CellRenderers/DisplayTime";
import DisplayTalkgroup from "../../CellRenderers/DisplayTalkgroup";
import DisplayCallsign from "../../CellRenderers/DisplayCallsign";
import DisplayHotspotName from "../../CellRenderers/DisplayHotspotName";
import dur from "../../../utils/duration";

function Callogs({ user_id }) {
  const [lastheard, setLastheard] = useState();
  const [lastUpdated, setLastUpdated] = useState(moment());

  const getCallog = () => {
    axios
      .get(`${process.env.REACT_APP_API}/api/v1/user/${user_id}/callog.json`)
      .then(({ data }) => { setLastheard(data.lastheard); })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    getCallog();
  }, []);

  useEffect(() => {
    const checkTimeSinceLastUpdate = () => {
      let now = moment();
      let duration = moment.duration(now.diff(lastUpdated));
      return parseInt(duration.asSeconds());
    };
    let refreshInterval = setInterval(() => {
      if (checkTimeSinceLastUpdate() >= 30) {
        getCallog();      }
    }, 30000);
    return () => clearInterval(refreshInterval);;
  });

  const gridOptions = {
    getRowId(data) {
      return data.data.id;
    },
    paginationPageSize: 15,
    rowHeight: 50,
    columnDefs: [
      {
        headerName: "Via",
        width: 250,
        field: "via",
        cellRenderer: DisplayHotspotName
      }, {
        headerName: "Callsign (DMR ID)",
        field: "dmrid",
        cellRenderer: DisplayCallsign
      }, {
        headerName: "Talkgroup",
        field: "talkgroup",
        width: 250,
        comparator: (valueA, valueB) => valueA.number - valueB.number,
        filterParams: {
          filterOptions: ['contains'],
          textFormatter: (d) => {
            return d.number ? `${d.number} ${d.raynet_group.name} ${d.name}`.toString().toLowerCase() : d.toString().toLowerCase()
          },
        },
        cellRenderer: DisplayTalkgroup
      }, {
        headerName: `Time (${moment.tz(JSON.parse(localStorage.getItem('user')).timezone).zoneAbbr()})`,
        field: "starttime",
        cellRenderer: DisplayTime
      }, {
        headerName: "Duration",
        valueGetter: function (params) {
          return `
          ${params.data.endtime
              ? dur(params.data.starttime, params.data.endtime)
              : dur(params.data.starttime, new Date())
            }`
        }
      }
    ]
  }

  if (!lastheard) { return (<LoadingSpinner title={`Raynet DMR | User - callog`} />); }

  return (
    <Container>
      <p>Your last 100 calls on the RAYNET-UK server</p>
        <Table
          gridOptions={gridOptions}
          rowData={lastheard}
        />
    </Container>
  )

}

export default Callogs;
import React from "react";
import { Container, Button } from "react-bootstrap";
import Table from "../../../Table/Table";
import axios from "axios";
import toast from "react-hot-toast";
import DisplayGroupname from "../../../CellRenderers/DisplayGroupname";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import ismemberof from "../../../../utils/ismemberof";
import DisplayTalkgroup from "../../../CellRenderers/DisplayTalkgroup";
import ButtonSure from "../../../Helper/ButtonSure";


function StaticTalkgroups({ static_talkgroups, setStatic_talkgroups, getUser, duplex }) {

  function DisplayTalkgroupName(props) {
    return (
      <>
        {props.value.closed ? <FontAwesomeIcon fixedWidth icon={faLock} /> : ''}
        {props.value.tg_full_name}
      </>
    );
  }

  function DisplayTalkgroupNumber(props) {
    return (
      <>
        {ismemberof(props.value.raynet_group.id)
          ? <a href={`/talkgroup/${props.value.id}`}><strong>{props.value.number}</strong></a>
          : <strong>{props.value.number}</strong>
        }
      </>
    )
  }

  function DisplayACLS(props) {
    return (
      <div className="btn-group">
        <Button disabled={true} size="sm" variant={props.data.talkgroup.tx ? 'success' : 'danger'}>TX</Button>
        <Button disabled={true} size="sm" variant={props.data.talkgroup.rx ? 'success' : 'danger'}>RX</Button>
      </div>
    )
  }

  function DisplaySlot(props) {
    return (
      <>
        <Button size="sm"
          variant={props.value === 'SLOT2' ? 'info' : 'secondary'}
          disabled={!duplex}
          onClick={() => updateStatic({ id: props.data.id, timeslot: props.value === 'SLOT2' ? 'SLOT1' : 'SLOT2' })}
        >{props.value}</Button>
      </>
    )
  }

  const deleteStaticTalkgroupButton = (id) => {
    let deleteDmrid =
      axios.delete(`${process.env.REACT_APP_API}/api/v1/static_group/${id}.json`)
        .then(({ data }) => {
          setStatic_talkgroups(static_talkgroups.filter(s => s.id !== id));
          getUser();
        })
    toast.promise(deleteDmrid, {
      loading: "Deleting...",
      success: "Static Talkgroup Deleted",
      error: "Failed to Delete Static Talkgroup",
    });
  }

  const updateStatic = (static_group = {}) => {
    let updateStaticRecord =
      axios.post(`${process.env.REACT_APP_API}/api/v1/static_group/${static_group.id}.json`, { static_group })
        .then(({ data }) => {
          let tmpstatic = [...static_talkgroups];
          let index = tmpstatic.findIndex(s => s.id === static_group.id);
          tmpstatic[index] = data.static_group;
          setStatic_talkgroups(tmpstatic);
          getUser();
        })
    toast.promise(updateStaticRecord, {
      loading: "Sending update...",
      success: "Hotspot Updated",
      error: "Could not update Hotspot",
    });
  }

  const gridOptions = {
    getRowId(data) {
      return data.data.id;
    },
    paginationPageSize: 15,
    rowHeight: 50,
    columnDefs: [
      {
        headerName: "Talkgroup",
        field: "talkgroup",
        comparator: (valueA, valueB) => valueA.number - valueB.number,
        filterParams: {
          filterOptions: ['contains'],
          textFormatter: (d) => {
            return d.number ? `${d.number} ${d.raynet_group.name} ${d.name}`.toString().toLowerCase() : d.toString().toLowerCase()
          },
        },
        cellRenderer: DisplayTalkgroup,
      }, {
        headerName: "ACL",
        cellRenderer: DisplayACLS,
      }, {
        headerName: "Timeslot",
        field: "timeslot",
        cellRenderer: DisplaySlot,
      }, {
        headerName: "Action",
        width: 250,
        cellRenderer: function (params) {
          return (<div className="btn-group">
            <Button variant={params.data.active ? 'success' : 'warning'} size="sm" onClick={() => updateStatic({ id: params.data.id, active: !params.data.active })}>{params.data.active ? 'Enabled' : 'Disabled'}</Button>
            <ButtonSure variant="danger"
              size="sm"
              onClick={() => deleteStaticTalkgroupButton(params.data.id)}
              actionName={<FontAwesomeIcon fixedWidth icon={faTrash} />}
              tooltip="Remove Static Talkgroup"
            >
              Removing this static talkgroup entry will stop traffic for this talkgroup from being sent to this device
            </ButtonSure>
          </div>)
        }
      }
    ]
  }

  return (
    <Container>
      <div width="300">
        <Table
          gridOptions={gridOptions}
          rowData={static_talkgroups}
        />
      </div>
    </Container>

  )

}

export default StaticTalkgroups
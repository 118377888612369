import React, { useState } from "react";
import { Container, Button } from "react-bootstrap";

import Table from "../../Table/Table";
import DisplayHotspotName from "../../CellRenderers/DisplayHotspotName";
import DisplayCallsign from "../../CellRenderers/DisplayCallsign";
import DisplayTime from "../../CellRenderers/DisplayTime";
import moment from "moment";
import toast from "react-hot-toast";
import axios from "axios";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonSure from "../../Helper/ButtonSure";


function DynamicUsers({ dynamicUsers }) {
  const [tgdynamic, setTgdynamic] = useState(dynamicUsers);

  const deleteDynamicButton = (id) => {
    let deleteDynamic =
      axios.delete(`${process.env.REACT_APP_API}/api/v1/dynamic/${id}.json`)
      .then(() => setTgdynamic(tgdynamic.filter(dynamictg => dynamictg.id !== id)) )
    toast.promise(deleteDynamic, {
      loading: "Deleting...",
      success: "Dynamic Talkgroup Deleted",
      error: "Failed to Delete Dynamic Talkgroup",
    });
  }

  const gridOptions = {
    getRowId(data) {
      return data.data.id;
    },
    paginationPageSize: 15,
    rowHeight: 50,
    columnDefs: [
      {
        headerName: "User",
        width: 250,
        field: "dmrid",
        cellRenderer: function(params) {
          return <a href={`/user/${params.value.user_id}`}>{params.value.name}</a>
        }
      }, {
        headerName: "Callsign (DMR ID)",
        field: "dmrid",
        cellRenderer: DisplayCallsign,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains'],
          textFormatter: (d) => {
            return d.callsign ? `${d.callsign} ${d.id}`.toString().toLowerCase() : d.toString().toLowerCase()
          },
        },
      }, {
        headerName: "Via",
        field: "via",
        cellRenderer: DisplayHotspotName,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains'],
          textFormatter: (v) => {
            return v.peer ? `${v.peer.callsign} ${v.peer.location} ${v.peer.id}`.toString().toLowerCase() : v.toString().toLowerCase()
          },
        }
      }, {
        headerName: "Slot",
        field: "slot",
        cellRenderer: function(params) {
          return params.value === 1 ? 'SLOT1' : 'SLOT2'
        }
      }, {
        headerName: `Time (${moment.tz(JSON.parse(localStorage.getItem('user')).timezone).zoneAbbr()})`,
        field: "updated_at",
        cellRenderer: DisplayTime,
      }, {
        headerName: "Status",
        field: "via",
        comparator: (valueA, valueB) => valueA.peer.online - valueB.peer.online,
        cellRenderer: function(params) {
          return (
            params.value.peer && `${params.value.peer.online ? 'Online' : 'Offline'}`
          )
        }
      }, {
        headerName: "Action",
        field: "",
        width: 250,
        cellRenderer: function (params) {
          return <ButtonSure 
          variant="danger" 
          onClick={() => deleteDynamicButton(params.data.id)} 
          size="sm"
          tooltip="Remove Dynamic User"
          actionName={<FontAwesomeIcon fixedWidth icon={faTrash} />}
          >
            Removing this dynamic entry will stop sending traffic to this destination for this talkgroup
          </ButtonSure>
        }
      }
    ]
  }
  return (
    <div>
      <p>Users who have defined this talkgroup as a static for themselves.</p>
      <br/>
      <Container>
          <Table
            gridOptions={gridOptions}
            rowData={tgdynamic}
          />
      </Container>


    </div>
  )
}


export default DynamicUsers;

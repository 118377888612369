import React, { useState, useEffect } from "react";
import { Modal, Form, Col, Row, Button, InputGroup } from "react-bootstrap";

import Panel from "../../components/Panel/Panel";
import Container from "../../components/Layout/Container";
import Table from "../../components/Table/Table";
import axios from "axios";
import toast from "react-hot-toast";

import DisplayHotspotName from "../../components/Helper/DisplayHotspotName";
import DisplayGroupname from "../../components/CellRenderers/DisplayGroupname";
import DisplayTalkgroup from "../../components/CellRenderers/DisplayTalkgroup";
import ButtonSure from "../../components/Helper/ButtonSure";
import ToggleSwitch from "../../components/ToggleSwitch/ToggleSwitch";
import {
  faCaretRight,
  faCaretDown,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function OpenBridges({ setPageTitle }) {
  setPageTitle('OpenBidges')
  const [openbridges, setOpenbridges] = useState(null);
  const [showCreateBridge, setShowCreateBridge] = useState(false);
  const [createUpdate, setCreateUpdate] = useState(true);
  const [name, setName] = useState();
  const [location, setLocation] = useState();
  const [url, setUrl] = useState();
  const [ip, setIp] = useState();
  const [port, setPort] = useState();
  const [remotedmrid, setRemotedmrid] = useState();
  const [localdmrid, setLocaldmrid] = useState();
  const [password, setPassword] = useState();
  const [active, setActive] = useState();
  const [bridgeid, setBridgeid] = useState();
  const [group, setGroup] = useState();
  const [groups, setGroups] = useState();
  const [talkgroups, setTalkgroups] = useState();
  const [talkgroup, setTalkgroup] = useState();
  const [remoteTalkgroup, setRemoteTalkgroup] = useState();

  // State variable to keep track of all the expanded rows
  // By default, nothing expanded. Hence initialized with empty array.
  const [expandedRows, setExpandedRows] = useState([]);
  // State variable to keep track which row is currently expanded.
  const [expandState, setExpandState] = useState({});
  const handleEpandRow = (event, userId) => {
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(userId);

    let obj = {};
    isRowExpanded ? (obj[userId] = false) : (obj[userId] = true);
    setExpandState(obj);

    // If the row is expanded, we are here to hide it. Hence remove
    // it from the state variable. Otherwise add to it.
    const newExpandedRows = isRowExpanded ?
      currentExpandedRows.filter(id => id !== userId) :
      currentExpandedRows.concat(userId);

    setExpandedRows(newExpandedRows);
  }

  const handleClose = () => setShowCreateBridge(false);
  const handleShow = () => {
    setName('');
    setLocation('');
    setUrl('');
    setIp('');
    setPort('');
    setRemotedmrid('');
    setLocaldmrid('');
    setPassword('');
    setActive('');
    setBridgeid('');
    setCreateUpdate(true);

    setShowCreateBridge(true);
  }

  const handleUpdateBridge = (ob) => {
    setName(ob.name);
    setLocation(ob.location);
    setUrl(ob.url);
    setIp(ob.ip);
    setPort(ob.port);
    setRemotedmrid(ob.remotedmrid);
    setLocaldmrid(ob.localdmrid);
    setPassword(ob.password);
    setActive(ob.active);
    setBridgeid(ob.id);
    setCreateUpdate(false);
    setShowCreateBridge(true);
  }
  const getOpenbridges = () => {
    axios
      .get(`${process.env.REACT_APP_API}/api/v1/openbridges.json`)
      .then(({ data }) => {
        setOpenbridges(data.openbridges);
      })
      .catch((error) => console.error(error));
  }

  const getGroups = () => {
    axios
      .get(`${process.env.REACT_APP_API}/api/v1/raynet_groups.json`)
      .then(({ data }) => {
        setGroups(data.raynet_groups);
      })
      .catch((error) => console.error(error));
  }

  const getTalkgroups = (group_id) => {
    axios
      .get(`${process.env.REACT_APP_API}/api/v1/talkgroups.json?group_id=${group_id}`)
      .then(({ data }) => {
        setTalkgroups(data.talkgroups);
      })
      .catch((error) => console.error(error));
  }

  const addTalkgroupToBridge = (ob_talkgroup = {}) => {
    let addTalkgroupRecord =
      axios.post(`${process.env.REACT_APP_API}/api/v1/ob_talkgroup.json`, { ob_talkgroup })
        .then(({ data }) => {
          let tmpopenbridges = [...openbridges]
          let ob_index = tmpopenbridges.findIndex(ob => ob.openbridge.id === ob_talkgroup.openbridge_id)
          let tmptgs = [...tmpopenbridges[ob_index].openbridge.ob_talkgroups]
          tmpopenbridges[ob_index].openbridge.ob_talkgroups = [...tmptgs, data]
          console.log("ob", tmpopenbridges)
          setOpenbridges(tmpopenbridges)
        });
    toast.promise(addTalkgroupRecord, {
      loading: "Sending update...",
      success: "Talkgroup Added",
      error: "Could not Add Talkgroup",
    });

  }

  const updateTalkgroup = (bridge, tg_id, ob_talkgroup = {}) => {
    let updateTalkgroupRecord =
      axios.post(`${process.env.REACT_APP_API}/api/v1/ob_talkgroup/${tg_id}.json`, { ob_talkgroup })
        .then(({ data }) => {
          let tmpopenbridges = [...openbridges]
          let ob_index = tmpopenbridges.findIndex(ob => ob.openbridge.id === bridge.id)
          let tmptgs = [...tmpopenbridges[ob_index].openbridge.ob_talkgroups]
          let tg_index = tmptgs.findIndex(tg => tg.id === data.id)
          tmptgs[tg_index] = data
          tmpopenbridges[ob_index].openbridge.ob_talkgroups = tmptgs
          setOpenbridges(tmpopenbridges)
        });
    toast.promise(updateTalkgroupRecord, {
      loading: "Sending update...",
      success: "Talkgroup Updated",
      error: "Could not update Talkgroup",
    });
  }

  const deleteTalkgroupFromBridge = (bridge, id) => {
    let deleteTalkgroup =
      axios.delete(`${process.env.REACT_APP_API}/api/v1/ob_talkgroup/${id}.json`)
        .then(({ data }) => {
          let tmpopenbridges = [...openbridges]
          let ob_index = tmpopenbridges.findIndex(ob => ob.openbridge.id === bridge.id)
          let tmptgs = [...tmpopenbridges[ob_index].openbridge.ob_talkgroups]
          let new_tgs = tmptgs.filter(t => t.id !== id)
          tmpopenbridges[ob_index].openbridge.ob_talkgroups = new_tgs
          setOpenbridges(tmpopenbridges)
        })
    toast.promise(deleteTalkgroup, {
      loading: "Removing...",
      success: "Talkgroup Removed",
      error: "Failed to Remove Talkgroup",
    });

  }

  useEffect(() => {
    getOpenbridges();
    getGroups();
  }, []);

  const createBridge = () => {
    axios
      .post(`${process.env.REACT_APP_API}/api/v1/openbridge.json`, {
        openbridge: {
          name: name,
          location: location,
          url: url,
          ip: ip,
          port: port,
          remotedmrid: remotedmrid,
          localdmrid: localdmrid,
          password: password,
          active: active
        }
      })
      .then(({ data }) => {
        setOpenbridges([...openbridges, data])
        handleClose();
      })
      .catch((error) => console.error(error));
  }

  const updateBridge = (id) => {
    axios
      .post(`${process.env.REACT_APP_API}/api/v1/openbridge/${id}.json`, {
        openbridge: {
          name: name,
          location: location,
          url: url,
          ip: ip,
          port: port,
          remotedmrid: remotedmrid,
          localdmrid: localdmrid,
          password: password,
          active: active
        }
      })
      .then(({ data }) => {
        let tmpob = [...openbridges]
        let index = tmpob.findIndex(p => p.openbridge.id === data.openbridge.id)
        tmpob[index] = data
        setOpenbridges(tmpob)
        handleClose();
      })
      .catch((error) => console.error(error));
  }

  const changeGroup = (id) => {
    setGroup(id);
    getTalkgroups(id);
  }

  const deleteOpenBridge = (id) => {
    axios
      .delete(`${process.env.REACT_APP_API}/api/v1/openbridge/${id}.json`)
      .then(({ data }) => {
        setOpenbridges(openbridges.filter(o => o.openbridge.id !== id))
      })
      .catch((error) => console.error(error));
  }

  return (
    <Container>
      <Panel>
        <Button onClick={() => handleShow()}>New Bridge</Button>
        <br />
        <table className="table" id="openbridges">
          <thead>
            <tr>
              <th></th>
              <th>Bridge</th>
              <th></th>
              <th></th>
              <th>Location</th>
              <th>URL</th>
              <th>Active</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {openbridges && openbridges.map((p, index) => (
                <React.Fragment key={index}>
                  <tr key={p.openbridge.id}>
                    <td><span variant="info" onClick={event => handleEpandRow(event, p.openbridge.id)} size="sm">{expandState[p.openbridge.id] ?
                      <FontAwesomeIcon
                        fixedWidth
                        icon={faCaretDown}
                        className="mr-2 text-gray-600"
                      />
                      : <FontAwesomeIcon
                        fixedWidth
                        icon={faCaretRight}
                        className="mr-2 text-gray-600"
                      />
                    }</span></td>
                    <td><strong><DisplayHotspotName hs={p} /></strong></td>
                    <td></td>
                    <td></td>
                    <td>{p.openbridge.location}</td>
                    <td><a href={p.openbridge.url} target="_blank" rel="noreferrer">{p.openbridge.url}</a></td>
                    <td>{p.openbridge.active ? 'Active' : 'Disabled'}</td>
                    <td>
                      <div className="btn-group">
                        <Button size="sm" onClick={() => handleUpdateBridge(p.openbridge)}>Edit</Button>
                        <ButtonSure
                          onClick={() => deleteOpenBridge(p.openbridge.id)}
                          actionName={<FontAwesomeIcon fixedWidth icon={faTrash} />}
                          tooltip="Delete Bridge"
                        >
                          <p>Deleting the bridge configuration will remove the link to this destination</p>
                        </ButtonSure>
                      </div>
                    </td>
                  </tr>
                  <>
                    {
                      expandedRows.includes(p.openbridge.id) &&
                          <tr key={p.openbridge.id}>
                            <td colSpan="8">
                              <Panel>
                                <InputGroup>
                                  <input placeholder="Remote Talkgroup" onChange={(e) => setRemoteTalkgroup(e.target.value)} />
                                    <Form.Select aria-label="Select Raynet Group" onChange={(e) => changeGroup(parseInt(e.target.value))}>
                                      <option>-- Select RAYNET-UK Group --</option>
                                      {groups ? groups.map((group) => (<option key={group.id} value={group.id}>{group.name}</option>)) : ''}
                                    </Form.Select>
                                    <Form.Select aria-label="Select Talkgroup Group" onChange={(e) => setTalkgroup(e.target.value)}>
                                      <option>-- Select Talkgroup --</option>
                                      {talkgroups ? talkgroups.filter(t => t.talkgroup.active === true).map((tg) => (<option key={tg.talkgroup.id} value={tg.talkgroup.id}>{tg.talkgroup.name}</option>)) : ''}
                                    </Form.Select>
                                  <Button size="sm" onClick={() => addTalkgroupToBridge({
                                    talkgroup_id: talkgroup,
                                    openbridge_id: p.openbridge.id,
                                    active: true,
                                    dest_talkgroup: remoteTalkgroup
                                  })}>Add</Button>
                                </InputGroup>
                                <Table
                                  gridOptions={{
                                    getRowId(data) {
                                      return data.data.id;
                                    },
                                    paginationPageSize: 15,
                                    rowHeight: 50,
                                    columnDefs: [
                                      {
                                        headerName: "Remote Talkgroup",
                                        field: "dest_talkgroup",
                                      }, {
                                        headerName: "Local Talkgroup",
                                        field: "talkgroup",
                                        sort: "desc",
                                        cellRenderer: DisplayTalkgroup
                                      }, {
                                        headerName: "Status",
                                        field: "active",
                                        width: "70",
                                        cellRenderer: function (params) {
                                          return (<ToggleSwitch checked={params.value} id={`active-${params.data.id}`} onChange={(e) => updateTalkgroup(p.openbridge, params.data.id, { active: e })} />)
                                        }
                                      }, {
                                        headerName: "Actions",
                                        cellRenderer: function (params) {
                                          return (
                                            <div className="btn-group">
                                              <ButtonSure variant="danger" size="sm" 
                                              onClick={() => deleteTalkgroupFromBridge(p.openbridge, params.data.id)}
                                              tooltip="Delete Talkgroup bridge"
                                              actionName={<FontAwesomeIcon fixedWidth icon={faTrash} />}
                                              >
                                                Delete this Talkgroup bridge link.
                                              </ButtonSure>
                                            </div>
                                          )
                                        }
                                      }
                                    ]
                                  }}
                                  rowData={p.openbridge.ob_talkgroups}
                                />
                              </Panel>

                            </td>
                          </tr>
                    }
                  </>
                </React.Fragment>
              ))
              }
          </tbody>
        </table>

        <Modal show={showCreateBridge} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{createUpdate ? 'Create' : 'Update'} OpenBridge</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group as={Row} className="mb-3" controlId="name">
                <Form.Label column sm="5" className="label-right">
                  <strong>Name</strong>
                </Form.Label>
                <Col sm="5">
                  <Form.Control plantext placeholder="Bridge Name" value={name} onChange={(e) => setName(e.target.value)} />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="Location">
                <Form.Label column sm="5" className="label-right">
                  <strong>Location</strong>
                </Form.Label>
                <Col sm="5">
                  <Form.Control plantext placeholder="London" value={location} onChange={(e) => setLocation(e.target.value)} />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="url">
                <Form.Label column sm="5" className="label-right">
                  <strong>URL</strong>
                </Form.Label>
                <Col sm="5">
                  <Form.Control plantext placeholder="https://" value={url} onChange={(e) => setUrl(e.target.value)} />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="IPAddress">
                <Form.Label column sm="5" className="label-right">
                  <strong>IP Address</strong>
                </Form.Label>
                <Col sm="5">
                  <Form.Control plantext placeholder="192.168.12.1" value={ip} onChange={(e) => setIp(e.target.value)} />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="Port">
                <Form.Label column sm="5" className="label-right">
                  <strong>Port</strong>
                </Form.Label>
                <Col sm="5">
                  <Form.Control plantext placeholder="62030" value={port} onChange={(e) => setPort(e.target.value)} />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="RemoteDMRID">
                <Form.Label column sm="5" className="label-right">
                  <strong>Remote DMRID</strong>
                </Form.Label>
                <Col sm="5">
                  <Form.Control plantext placeholder="1234567" value={remotedmrid} onChange={(e) => setRemotedmrid(e.target.value)} />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="LocalDMRID">
                <Form.Label column sm="5" className="label-right">
                  <strong>Local DMRID</strong>
                </Form.Label>
                <Col sm="5">
                  <Form.Control plantext placeholder="1234567" value={localdmrid} onChange={(e) => setLocaldmrid(e.target.value)} />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="password">
                <Form.Label column sm="5" className="label-right">
                  <strong>Password</strong>
                </Form.Label>
                <Col sm="5">
                  <Form.Control plantext placeholder="asdfasdf" value={password} onChange={(e) => setPassword(e.target.value)} />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="active">
                <Form.Label column sm="5" className="label-right">
                  <strong>Active</strong>
                </Form.Label>
                <Col sm="5">
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    defaultChecked={active}
                    onChange={(e) => setActive(e.target.checked)}
                  />

                </Col>
              </Form.Group>

            </Form>

          </Modal.Body>
          <Modal.Footer>
            {createUpdate
              ? <Button onClick={() => createBridge()} size="sm">Create</Button>
              : <Button onClick={() => updateBridge(bridgeid)} size="sm">Update</Button>
            }
          </Modal.Footer>

        </Modal>

      </Panel>
    </Container>

  )
}

export default OpenBridges;

import React from "react";
import { Container, Button } from "react-bootstrap";
import Table from "../../../Table/Table";
import axios from "axios";
import toast from "react-hot-toast";
import DisplayGroupname from "../../../CellRenderers/DisplayGroupname";
import DisplayCallsign from "../../../CellRenderers/DisplayCallsign";
import DisplayTime from "../../../CellRenderers/DisplayTime";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import ismemberof from "../../../../utils/ismemberof";
import DisplayTalkgroup from "../../../CellRenderers/DisplayTalkgroup";
import ButtonSure from "../../../Helper/ButtonSure";


function DynamicTalkgroups({ dynamic_talkgroups, setDynamic_talkgroups, getUser }) {

  function DisplayTalkgroupName(props) {
    return (
      <>
        {props.value.closed ? <FontAwesomeIcon fixedWidth icon={faLock} /> : ''}
        {props.value.tg_full_name}
      </>
    );
  }

  function DisplayTalkgroupNumber(props) {
    return (
      <>
        {ismemberof(props.value.raynet_group.id)
          ? <a href={`/talkgroup/${props.value.id}`}><strong>{props.value.number}</strong></a>
          : <strong>{props.value.number}</strong>
        }
      </>
    )
  }

  function DisplaySlot(props) {
    return (
      <>
        <Button size="sm" variant={props.value === 'SLOT2' ? 'info' : 'secondary'} disabled={true}>{props.value}</Button>
      </>
    )
  }

  const deleteDynamicButton = (id) => {
    let deleteDynamic =
      axios.delete(`${process.env.REACT_APP_API}/api/v1/dynamic/${id}.json`)
        .then(() => {
          setDynamic_talkgroups(dynamic_talkgroups.filter(dynamictg => dynamictg.id !== id))
          getUser()
        })
    toast.promise(deleteDynamic, {
      loading: "Deleting...",
      success: "Dynamic Talkgroup Deleted",
      error: "Failed to Delete Dynamic Talkgroup",
    });
  }

  const gridOptions = {
    getRowId(data) {
      return data.data.id;
    },
    paginationPageSize: 15,
    rowHeight: 50,
    columnDefs: [
      {
        headerName: "Callsign (DMR ID)",
        width: 250,
        field: "dmrid",
        cellRenderer: DisplayCallsign,
      }, {
        headerName: "Talkgroup",
        field: "talkgroup",
        width: 250,
        comparator: (valueA, valueB) => valueA.number - valueB.number,
        filterParams: {
          filterOptions: ['contains'],
          textFormatter: (d) => {
            return d.number ? `${d.number} ${d.raynet_group.name} ${d.name}`.toString().toLowerCase() : d.toString().toLowerCase()
          },
        },
        cellRenderer: DisplayTalkgroup
      }, {
        headerName: "slot",
        field: "slot",
        cellRenderer: DisplaySlot,
      }, {
        headerName: `Time (${moment.tz(JSON.parse(localStorage.getItem('user')).timezone).zoneAbbr()})`,
        field: "updated_at",
        cellRenderer: DisplayTime,
      }, {
        headerName: "Action",
        width: 250,
        cellRenderer: function (params) {
          return (<ButtonSure
            variant="danger"
            size="sm"
            onClick={() => deleteDynamicButton(params.data.id)}
            tooltip="Remove Dynamic Talkgroup"
            actionName={<FontAwesomeIcon fixedWidth icon={faTrash} />}
          >
            Removing this dynamic talkgroup entry will stop traffic for this talkgroup from being sent to this device
          </ButtonSure>)
        }
      }
    ]
  }

  return (
    <Container>
      <div width="300">
        <Table
          gridOptions={gridOptions}
          rowData={dynamic_talkgroups}
        />
      </div>
    </Container>

  )

}

export default DynamicTalkgroups
import React, { useState } from "react";
import { Container, Button, Stack, InputGroup, ButtonGroup } from "react-bootstrap";
import isadmin from "../../../utils/isadmin";
import Table from "../../Table/Table";
import toast from "react-hot-toast";
import axios from "axios";
import GroupListSelect from "../../Helper/GroupListSelect";
import ButtonSure from "../../Helper/ButtonSure";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash, faDownload,
} from "@fortawesome/free-solid-svg-icons";
import Tippy from "@tippyjs/react";


function Groups({ groups, user, setUser }) {
  const [group, setGroup] = useState();

  const groupAdminButton = (group_id) => {
    let groupAdmin =
      axios.post(`${process.env.REACT_APP_API}/api/v1/user/${user.id}/group_admin.json`, {
        user_id: user.id,
        group_id: group_id,
      })
        .then(({ data }) => {
          setUser(data.user)
        })
    toast.promise(groupAdmin, {
      loading: "Toggling Group Admin...",
      success: "Group Admin Set",
      error: "Could not set Group Admin",
    });
  }

  const groupEnableButton = (group_id) => {
    let groupEnable =
      axios.post(`${process.env.REACT_APP_API}/api/v1/user/${user.id}/group_toggle.json`, {
        group_id: group_id,
      })
        .then(({ data }) => {
          setUser(data.user)
        })
    toast.promise(groupEnable, {
      loading: "Toggling Group...",
      success: "Group Membership set",
      error: "Could not set Group Membership",
    });
  }

  const addGroupButton = (group_id) => {
    let addGroup =
      axios.post(`${process.env.REACT_APP_API}/api/v1/user/${user.id}/add_group.json`, {
        group_id: group_id,
      })
        .then(({ data }) => {
          setUser(data.user)
        })
    toast.promise(addGroup, {
      loading: "Toggling Group...",
      success: "Group Membership set",
      error: "Could not set Group Membership",
    });
  }


  const removeGroupButton = (group_id) => {
    let removeGroup =
      axios.post(`${process.env.REACT_APP_API}/api/v1/user/${user.id}/membership.json`, {
        group_id: group_id,
      })
        .then(({ data }) => {
          setUser(data.user)
        })
    toast.promise(removeGroup, {
      loading: "Removing Group...",
      success: "Group Membership Removed",
      error: "Could not remove Membership",
    });
  }

  function exportCSV(group_id) {
    let exportcsv = axios.get(`${process.env.REACT_APP_API}/api/v1/raynet_group/${group_id}/talkgroups/export.csv`)

  }

  const gridOptions = {
    getRowId(data) {
      return data.data.raynet_group.id;
    },
    paginationPageSize: 15,
    rowHeight: 50,
    columnDefs: [
      {
        headerName: "Name",
        width: 250,
        field: "raynet_group.name",
        cellRenderer: function (params) {
          return (<a href={`/group/${params.data.raynet_group.id}`}>{params.value}</a>)
        }
      }, {
        headerName: "Group Code",
        field: "raynet_group.groupcode",
      }, {
        headerName: "Admin",
        field: 'raynet_group.admin',
        cellRenderer: function (params) {
          return (
            <Button size="sm"
              disabled={isadmin() ? false : true}
              variant={params.value ? 'warning' : 'success'}
              onClick={(e) => groupAdminButton(params.data.raynet_group.id)}
            >
              {params.value ? 'Enabled' : 'Disabled'}
            </Button>
          )
        }
      }, {
        headerName: "Group Enabled",
        field: "raynet_group.active",
        cellRenderer: function (params) {
          return (<Button size="sm"
            disabled={isadmin() ? false : true}
            variant={params.value ? 'success' : 'danger'}
            onClick={(e) => groupEnableButton(params.data.raynet_group.id)}
          >{params.value ? 'Enabled' : 'Disabled'}</Button>)
        }
      }, {
        headerName: "Action",
        width: 250,
        cellRenderer: function (params) {
          return (<>
            <ButtonSure size="sm"
              disabled={isadmin() ? false : true}
              actionName={<FontAwesomeIcon fixedWidth icon={faTrash} />}
              onClick={(e) => removeGroupButton(params.data.raynet_group.id)}
              tooltip="Remove user from group"
            >
              <p>Once removed the user will no longer see Talkgroups for this group.</p>
              <p>Any associated static Talkgroups will be remove their their hotspots.</p>
            </ButtonSure>
            <Tippy content='Export the groups active Talkgroups as a CSV'>
              <a href={`${process.env.REACT_APP_API}/api/v1/raynet_group/${params.data.raynet_group.id}/talkgroups/export.csv`} >
                <Button size="sm" variant="success"><FontAwesomeIcon
                  icon={faDownload}
                /></Button>
              </a>
            </Tippy>
          </>
          )
        }
      }
    ]
  }


  return (
    <Container>
      <p>Listed here are all the RAYNET-UK groups you have been added to and your status wi thin  the group. If you feel you're missing some please contact  the group admin to discuss.</p>
      <br />
      {isadmin()
        ? <InputGroup><GroupListSelect setGroup={setGroup} />
          <Button size="sm"
            onClick={(e) => addGroupButton(group)}
          >Add Group</Button>
        </InputGroup>
        : ''}
      <br />
      <Table
        gridOptions={gridOptions}
        rowData={groups}
      />

    </Container>
  )
}


export default Groups;

import moment from "moment";
import { Button, Container, InputGroup } from "react-bootstrap";
import Table from "../../Table/Table";
import DisplayTime from "../../CellRenderers/DisplayTime";
import SendPage from "../../SendPage/SendPage";
import { useState } from "react";
import DisplayRICCallsign from "../../Helper/DisplayRICCallsign";

function Paging({pages, user_id, groups }) {
  const [showsent, setShowSent] = useState(false)

  function updateShowSent() {
    setShowSent(!showsent)
    var makeCol = gridOptions.columnApi.getColumn("Recipient")
    makeCol.colDef.headerName="New Header Name";
    gridOptions.api.refreshHeader();
  }

const gridOptions = {
    getRowId(data) {
      return data.data.id;
    },
    paginationPageSize: 15,
    rowHeight: 50,
    columnDefs: [
      {
        headerName: `Sent (${moment.tz(JSON.parse(localStorage.getItem('user')).timezone).zoneAbbr()})`,
        field: "sent",
        sort: "desc",
        cellRenderer: DisplayTime
      }, {
        headerName: "From",
        width: 10,
        cellRenderer: function (params) {
          return `${params.data.user}`
        }
      }, {
        headerName: "To",
        field: "callsign",
        width: 20,
        cellRenderer: DisplayRICCallsign
      }, {
        headerName: "Via Groups", 
        cellRenderer: function (params) {
          return ( params.data.txgroups.map((f) => f.name).join(', ') )
        }
      }, {
        headerName: "Message",
        field: "message",
        width: 250,
      }, 
    ]
  }

  return (
    <Container>
      <p></p>
      <SendPage groups={groups} />
      <InputGroup>
      <Button onClick={() => updateShowSent() }  size="sm" variant={showsent ? "success" : "primary"}>{showsent ? "Sent" : "Received"}</Button>
      </InputGroup>
        <Table
          gridOptions={gridOptions}
          rowData={showsent ? pages.sent : pages.received}
        />
    </Container>
  )

}

export default Paging;
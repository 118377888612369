import React, { useEffect, useState } from "react";
import Container from "../../components/Layout/Container";
import Panel from "../../components/Panel/Panel";
import Table from "../../components/Table/Table";
import Moment from "react-moment";
import axios from "axios";
import LoadingSpinner from "../../components/Helper/LoadingSpinner";
import { Button, InputGroup } from "react-bootstrap";

function Timeformats({ setPageTitle }) {
    setPageTitle("Time Formats")
    const [timeformats, setTimeformats] = useState(null);
    const [timeformat, setTimeformat] = useState();

    const getTimeformats = () => {
        axios
            .get(`${process.env.REACT_APP_API}/api/v1/timeformats.json`)
            .then(({ data }) => {
                setTimeformats(data.timeformats);
            })
            .catch((error) => console.error(error));
    }

    const addTimeformat = () => {
        axios
            .post(`${process.env.REACT_APP_API}/api/v1/timeformat.json`, {
                timeformat: {
                    format: timeformat,
                }
            })
            .then(({ data }) => {
                setTimeformats([...timeformats, data]);
            })
            .catch((error) => console.error(error));
    }

    const deleteTimeformat = (id) => {
        axios
            .delete(`${process.env.REACT_APP_API}/api/v1/timeformat/${id}.json`)
            .then(({ data }) => {
                setTimeformats(timeformats.filter(t => t.id !== id))
            })
            .catch((error) => console.error(error));
    }


    useEffect(() => {
        getTimeformats();
    }, []);

    if (!timeformats) { return (<LoadingSpinner title="Raynet DMR | Timeformats" />); }

    const gridOptions = {
        getRowId(data) {
            return data.data.id;
        },
        paginationPageSize: 30,
        columnDefs: [
            {
                headerName: "Format",
                field: "format",
                width: 50,
            }, {
                headerName: "Example",
                width: 50,
                field: "format",
                cellRenderer: function (params) {
                    return (<Moment format={params.value}>{new Date()}</Moment>)
                }
            }, {
                headerName: "Action",
                cellRenderer: function (params) {
                    return (
                        <>
                            <Button variant="danger" onClick={() => deleteTimeformat(params.data.id)}>Delete</Button>
                        </>
                    )
                }
            }
        ]
    }

    return (
        <Container>
            <Panel>
                <InputGroup>
                    <input type="text" placeholder="Enter Time format" onChange={(e) => setTimeformat(e.target.value)} width="300" />
                    <Button onClick={() => addTimeformat()}>Add</Button>
                </InputGroup>
                <p><Moment format={timeformat}>{new Date()}</Moment></p>
                <Table
                    gridOptions={gridOptions}
                    rowData={timeformats}
                />
            </Panel>
        </Container>
    );
}

export default Timeformats;

import React, { useEffect, useState } from "react";
import Container from "../../components/Layout/Container";
import Panel from "../../components/Panel/Panel";
import Table from "../../components/Table/Table";
import axios from "axios";
import LoadingSpinner from "../../components/Helper/LoadingSpinner";
import DisplayTime from "../../components/CellRenderers/DisplayTime";

function Logs({setPageTitle}) {
    setPageTitle("Logs")
  const [logs, setLogs] = useState(null);

  const getLogs = (q) => {
    axios
      .get(`${process.env.REACT_APP_API}/api/v1/info/logs.json${ q ? `?q=${q}` : null }`)
      .then(({ data }) => {
        setLogs(data.logs);
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    getLogs();
  }, []);

  if (!logs) { return (<LoadingSpinner title="Raynet DMR | Logs" />); }

function logsearch(q) {
  if (q.length > 4) {
      getLogs(q)
  }
  if (q.length === 0) {
      getLogs()
  }
}

  const gridOptions = {
    getRowId(data) {
      return data.data.id;
    },
    paginationPageSize: 30,
    columnDefs: [
      {
        headerName: "Date",
        field: "created_at",
        width: 50,
        cellRenderer: DisplayTime,
      }, {
        headerName: "User",
        field: "user.name",
        width: 50,
        cellRenderer: function (params) {
            if (params.value) {
              return (<a href={`/user/${params.data.user.id}`}>{params.value}</a>)
            }
        }
      }, {
        headerName: "Message",
        field: "msg",
        autoHeight: true,
      }
    ]
  }

  return (
    <Container>
      <Panel>
        <input type="text" placeholder="Log Search" onChange={(e) => logsearch(e.target.value)} />
        <Table
          gridOptions={gridOptions}
          rowData={logs}
        />
      </Panel>
    </Container>
  );
}

export default Logs;

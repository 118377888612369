import { Form } from "react-bootstrap";
import axios from "axios";
import React, { useState, useEffect } from "react";

function GroupListSelect({setGroup}) {
  const [groups, setGroups] = useState('');

  useEffect(() => {
    getGroups()
  }, []);

  const getGroups = () => {
    if (JSON.parse(localStorage.getItem('admin'))) {
      axios
        .get(`${process.env.REACT_APP_API}/api/v1/raynet_groups/selectgroup.json`)
        .then(({ data }) => {
          setGroups(data.raynet_groups);
        })
        .catch((error) => console.error(error));
    } else {
      setGroups(JSON.parse(localStorage.getItem('admin_groups')).map((d) => ({id: d.raynet_group.id, name: d.raynet_group.name} ) ));
    }
  }

  return (
    <div style={{width: '300px'}}>
    <Form.Select aria-label="Select Raynet Group" onChange={(e) => setGroup(e.target.value)}>
      <option key="00">-- Select RAYNET-UK Group --</option>
      {groups ? groups.map((group, index) => (<option key={group.id} value={group.id}>{group.name}</option>)) : ''}
    </Form.Select>
    </div>
  )
}

export default GroupListSelect;
import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import RegisteredUsers from "./RegisteredUsers";
import UserEnrolment from "./UserEnrolment";
import Container from "../../components/Layout/Container";
import Panel from "../../components/Panel/Panel";
import axios from "axios";
import moment from "moment";


function Users({ setPageTitle }) {
  setPageTitle('Users');
  const [users, setUsers] = useState(null);
  const [tokens, setTokens] = useState(null);
  const [groupid, setGroupid] = useState();
  const [lastUpdated, setLastUpdated] = useState(moment());
  const [usersLasttime, setUsersLasttime] = useState();

  const getUsers = () => {
    axios
      .get(`${process.env.REACT_APP_API}/api/v1/users.json${users && usersLasttime ? `?last_time=${usersLasttime}` : ''}`)
      .then(({ data }) => {
        if (users) {
          updateUsers(data.users);
        } else {
          setUsers(data.users);
        }
        setUsersLasttime(data.last_time);
      })
      .catch((error) => console.error(error));
  }

  function updateUsers(_users) {
    if (usersLasttime && users) {
      let tmpUsers = [...users];
      for (let i = 0; i < _users.length; i++) {
        let mi = tmpUsers.findIndex(l => l.id === _users[i].id);
        if (mi === -1) {
          tmpUsers.push(_users[i]);
        } else {
          tmpUsers[mi] = _users[i];
        }
      }
      setUsers(tmpUsers);;
    }
  }

  const getTokens = () => {
    axios
      .get(`${process.env.REACT_APP_API}/api/v1/tokens.json${groupid ? `?id=${groupid}` : ''}`)
      .then(({ data }) => {
        setTokens(data.tokens);
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    getUsers();
    getTokens();
  }, []);

  useEffect(() => {
    const checkTimeSinceLastUpdate = () => {
      let now = moment();
      let duration = moment.duration(now.diff(lastUpdated));
      return parseInt(duration.asSeconds());
    };
    let refreshInterval = setInterval(() => {
      if (checkTimeSinceLastUpdate() >= 30) {
        getUsers();
        getTokens();
      }
    }, 60000);
    return () => clearInterval(refreshInterval);;
  });


  return (
    <Container>
        <Tabs defaultactivekey="UserEnrolment" id="users-tab" className="">
          <Tab eventKey="UserEnrolment" title={`Users Enrolment (${tokens ? tokens.length : '-'})`}><Panel><UserEnrolment tokens={tokens} groupid={groupid} setTokens={setTokens} getTokens={getTokens} /></Panel></Tab>
          <Tab eventKey="RegisteredUsers" title={`Registered Users (${users ? users.length : '-'})`}><Panel><RegisteredUsers users={users} setUsers={setUsers} /></Panel></Tab>
        </Tabs>
    </Container>
  );

}

export default Users;
import React from "react";
import { Badge } from "react-bootstrap";
import ismemberof from "../../utils/ismemberof";

function DisplayGroupname(props) {

  return (
    <>
      {ismemberof(props.value.id)
        ? <a href={`/group/${props.value.id}`}><Badge bg="primary">{props.value.name}</Badge></a>
        : <Badge bg="primary">{props.value.name}</Badge>
      }
    </>
  );

}

export default DisplayGroupname;

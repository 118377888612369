import Table from "../../components/Table/Table";
import Panel from "../../components/Panel/Panel";
import { Container } from "react-bootstrap";

function Peers({peers}) {

  const gridOptions = {
    getRowId(data) {
      return data.data.id;
    },
    paginationPageSize: 30,
    columnDefs: [
      {
        headerName: "DMRID",
        field: "id",
      }, {
        headerName: "IP",
        field: "ip",
      }, {
        headerName: "Callsign",
        field: "callsign",
      }, {
        headerName: "Location",
        field: "location",
      }, {
        headerName: "User",
        field: "user",
        cellRenderer: function (params) {
          return(<a href={`/user/${params.value.id}`}>{params.value.name}</a>)
        }
      }
    ]
  }

  return (
    <Container>
      <Panel>
        <h1>Registered Peers</h1>
          <Table
            gridOptions={gridOptions}
            rowData={peers}
          />
          </Panel>
    </Container>
  );
}

export default Peers;
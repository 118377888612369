import React, { useState } from "react";
import { Button, Form, InputGroup, Badge } from "react-bootstrap";
import axios from "axios";
import GroupListSelect from "../../Helper/GroupListSelect";
import toast from "react-hot-toast";

function AddByCallsign({ groupid }) {
  const [group, setGroup] = useState(groupid);
  const [callsign, setCallsign] = useState('');

  const addUserTokenButton = () => {
    if (!callsign || !group) {
      if (!callsign) { toast.error("Please enter a callsign.") }
      if (!group) { toast.error("Select a Group") }
    } else {
      let addUserToken =
        axios.post(`${process.env.REACT_APP_API}/api/v1/tokens.json`, {
          token: {
            callsign: callsign,
            group_id: group,
          }
        })
      toast.promise(addUserToken, {
        loading: "Adding User...",
        success: "User Added",
        error: "Failed to Add User to Group",
      });
    }
  }

  const renderCallsignPills = (text) => {
    const regex =
      /(?:[A-Za-z0-9]{2,3}[A-Za-z]{2,3})/gi;
    const found = text.match(regex);
    const deduplicated = [...new Set(found)];

    if (!deduplicated) {
      return (
        <div className="text-gray-600">
          Unable to find any email addresses in the provided text.
        </div>
      );
    }

    return deduplicated.map((callsign, index) => (
      <React.Fragment key={index}><Badge bg="success" size="sm">{callsign.toUpperCase()}</Badge><> </></React.Fragment>
    ));
  };

  return (
    <table>
      <thead>
        <tr><th className="text-center">Add user by Callsign</th></tr>
      </thead>
      <tbody>
        <tr><td>
          <p>If the user is already registered on the RAYNET server then you can add them to this group by adding their callsign below.</p>
          <Form.Control as="textarea"
            id="callsignlist"
            name="callsignlist"
            placeholder="Enter Callsigns"
            value={callsign}
            onChange={(e) => setCallsign(e.target.value)}
            rows="5"
          />
          {callsign && (
            <div className="flex flex-wrap mb-3">
              {renderCallsignPills(callsign)}
            </div>
          )}

          <br />
          <div style={{ float: "right" }}>
            <InputGroup>
              {groupid ? null : <GroupListSelect setGroup={setGroup} />}
              <Button onClick={() => addUserTokenButton()} variant="primary" disabled={callsign.length === 0}>Add</Button>
            </InputGroup>
          </div>
        </td>
        </tr>
      </tbody >
    </table >

  )
}


export default AddByCallsign;
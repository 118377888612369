import React, { useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { Button, Col, Row } from "react-bootstrap"

function ForgotPassword({ setHasForgotPassword }) {
    const [emailAddress, setEmailAddress] = useState("");
    const [requested, setRequested] = useState(false);

    const submitForm = () => {
        let resetPromise = axios
            .post(`${process.env.REACT_APP_API}/api/v1/user/reset_password.json`, { user: { email: emailAddress } })
            .then(({ data }) => {
                console.log("data", data)
                setRequested(true);
                setHasForgotPassword(true);
            });
        toast.promise(resetPromise, {
            loading: "Sending reset email...",
            success: "Reset email sent",
            error: "Failed to send reset email",
        });
    };

    function validateEmail(email) {
        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regexp.test(email);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            submitForm();
        }
    }

        if (requested) {
            return (<>
                <p className="notes">Please check your e-mail for details of how to reset your password.</p>
                <p className="notes">You can now close this window.</p>
            </>
            )
        }

        return (
            <>
                <Row>
                    <p className="sign" align="center">Forgot Password</p>
                    <div className="rounded-md">
                        <input
                            aria-label="Email"
                            name="emailAddress"
                            type="text"
                            required=""
                            className="un-pass"
                            placeholder="E-Mail Address"
                            onChange={(e) => setEmailAddress(e.target.value)}
                            onKeyDown={(e) => handleKeyDown(e)}
                            value={emailAddress}
                        />
                    </div>
                </Row>
                <Row>
                    <Col><Button size="sm" className="left" variant="secondary" onClick={() => setHasForgotPassword(false)}>Cancel</Button></Col>
                    <Col><Button size="sm" className="right" variant="danger"  disabled={!(validateEmail(emailAddress))} onClick={() => submitForm()}>Send Password Reset Link</Button></Col>
                </Row>
            </>
        );
    }

    export default ForgotPassword;

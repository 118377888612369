import Table from "../../components/Table/Table";
import Panel from "../../components/Panel/Panel";
import { Container } from "react-bootstrap";

function Dmrids({dmrids}) {

  const gridOptions = {
    getRowId(data) {
      return data.data.id;
    },
    paginationPageSize: 30,
    columnDefs: [
      {
        headerName: "DMRID",
        field: "id",
        cellRenderer: function (params) {
          return (<a href={`/user/${params.data.user.id}`}>{params.value}</a>)
        }
      }, {
        headerName: "callsign",
        field: "callsign",
      }
    ]
  }

  return (
    <Container>
      <Panel>
        <h1>Registered DMRIDs</h1>
          <Table
            gridOptions={gridOptions}
            rowData={dmrids}
          />
          </Panel>
    </Container>
  );
}

export default Dmrids;
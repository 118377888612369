import React from "react";
import { Badge } from "react-bootstrap";
import ismemberof from "../../utils/ismemberof";

function DisplayGroupname({ group }) {

  return (
    <>
      {ismemberof(group.id)
        ? <a href={`/group/${group.id}`}><Badge bg="primary">{group.name}</Badge></a>
        : <Badge bg="primary">{group.name}</Badge>
      }
    </>
  );

}

export default DisplayGroupname;

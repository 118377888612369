import React from "react";

function GPSHeading(heading) {
  let direction = "T"
    if ((heading >= 0 && heading <= 11.25) || (heading >= 348.75 && heading <= 360)) {
      direction = "N"
    } else if (heading >= 11.25 && heading <= 33.75) {
      direction = "NNE"
    } else if (heading >= 33.75 && heading <= 56.25) {
      direction = "NE"
    } else if (heading >= 56.25 && heading <= 78.75) {
      direction = "ENE"
    } else if (heading >= 78.75 && heading <= 101.25) {
      direction = "E"
    } else if (heading >= 101.25 && heading <= 123.75) {
      direction = "ESE"
    } else if (heading >= 123.75 && heading <= 146.25) {
      direction = "SE"
    } else if (heading >= 146.25 && heading <= 168.75) {
      direction = "SSE"
    } else if (heading >= 168.75 && heading <= 191.25) {
      direction = "S"
    } else if (heading >= 191.25 && heading <= 213.75) {
      direction = "SSW"
    } else if (heading >= 213.75 && heading <= 236.25) {
      direction = "SW"
    } else if (heading >= 236.25 && heading <= 258.75) {
      direction = "WSW"
    } else if (heading >= 258.75 && heading <= 281.25) {
      direction = "W"
    } else if (heading >= 281.25 && heading <= 303.75) {
      direction = "WNW"
    } else if (heading >= 303.75 && heading <= 326.25) {
      direction = "NW"
    } else if (heading >= 326.25 && heading <= 348.75) {
      direction = "NNW"
    }
  return direction;
}

export default GPSHeading;
import React, { useState, useEffect } from "react";
import axios from "axios";
import './App.css';
import ToastComponent from "./components/Toast/ToastComponent";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import NavHeader from "./components/Layout/NavHeader";
import Login from "./pages/Login/Login";
// import 'bootstrap/dist/css/bootstrap.min.css';
import RaynetGroups from "./pages/RaynetGroups/RaynetGroups";
import ShowGroup from "./pages/RaynetGroups/ShowGroup";

import Talkgroups from "./pages/Talkgroups/Talkgroups";
import ShowTalkgroup from "./pages/Talkgroups/TalkgroupShow";

import Users from "./pages/Users/Users";
import ShowUser from "./pages/Users/ShowUser";

import Dashboard from "./pages/Dashboard/Dashboard";
import Announcements from "./pages/Info/Announcements";
import Logs from "./pages/Info/Logs";
import NonMembers from "./pages/Info/NonMembers";
import Timeformats from "./pages/Settings/Timeformats";
import BlockedUsers from "./pages/Users/BlockedUsers";
import OpenBridges from "./pages/Settings/OpenBridges";
import Register from "./pages/Users/Register";
import PasswordReset from "./pages/Users/PasswordReset";
import Search from "./pages/Search/Search";
import Stats from "./pages/Info/Stats";
import GPSLogs from "./pages/GPS/GPSLogs";
import RepeaterTrustees from "./pages/Info/RepeaterTrustees";
import Messaging from "./pages/Messaging/Messaging";

function App({ cable }) {
  const [page, setPage] = useState('Dashboard');

  const setPageTitle = (page) => {
    setPage(page);
  }

  useEffect(() => {
    // Add listener to update styles
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => onSelectMode(e.matches ? 'dark' : 'light'));

    // Setup dark/light mode for the first time
    onSelectMode(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')

    // Remove listener
    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', () => {
      });
    }
  }, []);

  const onSelectMode = (mode) => {
    if (mode === 'dark') {
      // document.body.classList.add('dark-mode')
      // document.body.classList.remove('light-mode')
    } else {
      document.body.classList.remove('dark-mode')
      document.body.classList.add('light-mode')
    }
  }


  axios.defaults.withCredentials = true;

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("admin_groups");
        localStorage.removeItem("admin");
        localStorage.removeItem("user");
        localStorage.removeItem("raynet_groups");
        if (window.location.pathname !== '/login') {
          window.location = "/login";
        }
      }
      return Promise.reject(error)
    }
  );

/**
 * Display the Register page if the URL contains /register/
 * TODO: Check if this is used anywhere else and make more robust
 */
if (window.location.pathname.includes("/register/")) {
  return <Register />;
}

if (window.location.pathname.includes("/user/update_password/")) {
  return <PasswordReset />;
}

if (window.location.pathname.includes("/user/new_password")) {
  return <PasswordReset />;
}


/**
 * Display the login page if the user has no token
 */
if (!JSON.parse(localStorage.getItem('user'))) {
  return <Login />;
}


return (
  <div className="font-body bg-gray-100 min-h-screen flex flex-col text-gray-900 pb-10">
    <Helmet
      titleTemplate={`%s`}
      defaultTitle={process.env.REACT_APP_NAME}
    />
    <ToastComponent />
    <NavHeader title={page} />
    <Router>
      <Routes>
        <Route path="/" element={<Dashboard setPageTitle={setPageTitle} cable={cable} />} />
        <Route path="/groups" element={<RaynetGroups setPageTitle={setPageTitle} />} />
        <Route path="/group/:id" element={<ShowGroup setPageTitle={setPageTitle} />} />
        <Route path="/talkgroups" element={<Talkgroups setPageTitle={setPageTitle} />} />
        <Route path="/talkgroup/:id" element={<ShowTalkgroup setPageTitle={setPageTitle} cable={cable} />} />

        <Route path="/users" element={<Users setPageTitle={setPageTitle} />} />
        <Route path="/users/:id" element={<ShowUser setPageTitle={setPageTitle} />} />
        <Route path="/user/:id" element={<ShowUser setPageTitle={setPageTitle} />} />
        <Route path="/user/:id/:tab" element={<ShowUser setPageTitle={setPageTitle} />} />
        <Route path="/users/blocked" element={<BlockedUsers setPageTitle={setPageTitle} />} />

        <Route path="/gps" element={<GPSLogs setPageTitle={setPageTitle} />} />
        <Route path="/messaging" element={<Messaging setPageTitle={setPageTitle} />} />

        <Route path="/search" element={<Search />} />

        <Route path="/info/announcements" element={<Announcements setPageTitle={setPageTitle} />} />
        <Route path="/info/logs" element={<Logs setPageTitle={setPageTitle} />} />
        <Route path="/info/nonmembers" element={<NonMembers setPageTitle={setPageTitle} />} />
        <Route path="/info/stats" element={<Stats setPageTitle={setPageTitle} />} />
        <Route path="/info/repeatertrustees" element={<RepeaterTrustees setPageTitle={setPageTitle} />} />

        <Route path="/settings/timeformats" element={<Timeformats setPageTitle={setPageTitle} />} />
        <Route path="/settings/openbridge" element={<OpenBridges setPageTitle={setPageTitle} />} />

      </Routes>
      {/* Fill remaining height */}
      <div className="flex flex-grow mt-4">
      </div>
    </Router>
  </div>
);
}

export default App;

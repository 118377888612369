import React, { useEffect, useState } from "react";
import Panel from "../../components/Panel/Panel";
import Container from "../../components/Layout/Container";
import Table from "../../components/Table/Table";
import Moment from "react-moment";
import axios from "axios";
import LoadingSpinner from "../../components/Helper/LoadingSpinner";

function Announcements({setPageTitle}) {
    setPageTitle("Announcements")
  const [messages, setMessages] = useState(null);

  const getMessages = () => {
    axios
      .get(`${process.env.REACT_APP_API}/api/v1/info/annoucements.json`)
      .then(({ data }) => {
        setMessages(data.announcements);
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    getMessages();
  }, []);

  if (!messages) { return (<LoadingSpinner title="Raynet DMR | Announcements" />); }

  const gridOptions = {
    getRowId(data) {
      return data.data.id;
    },
    paginationPageSize: 30,
    columnDefs: [
      {
        headerName: "Date",
        field: "date",
        width: 25,
        cellRenderer: function(params) {
          return(<Moment format="DD MMM YYYY" tz={JSON.parse(localStorage.getItem('user')).timezone}>{ params.value }</Moment>)
        },
      }, {
        headerName: "Message",
        field: "message",
        autoHeight: true,
        cellRenderer: function (params) {
            return (<div dangerouslySetInnerHTML={{ __html: params.value}}/>)
        }
      }
    ]
  }

  return (
    <Container>
      <Panel>
      <p>Log of all the development changes made to the system.</p>
        <Table
          gridOptions={gridOptions}
          rowData={messages}
        />
      </Panel>
    </Container>
  );
}

export default Announcements;

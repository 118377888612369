import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import Moment from 'react-moment';
import { Container } from "react-bootstrap";
import Table from "../../Table/Table";
import DisplayTime from "../../CellRenderers/DisplayTime";
import DisplayCallsign from "../../CellRenderers/DisplayCallsign";
import DisplayHotspotName from "../../CellRenderers/DisplayHotspotName";

import dur from "../../../utils/duration";

function Callogs({ id, msg }) {
  const [lastheard, setLastheard] = useState();
  const [lastUpdated, setLastUpdated] = useState(moment());

  const getCallog = () => {
    axios
      .get(`${process.env.REACT_APP_API}/api/v1/talkgroup/${id}/callog.json`)
      .then(({ data }) => { setLastheard(data.callogs); })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    getCallog();
  }, []);

  useEffect(() => {
    if (msg && lastheard) {
      let _lh = [...lastheard];
      for (let i = 0; i < msg.length; i++) {
        let _msg =  Object.assign({},  msg[i]);
        _msg.id = msg[i].log_id;
        let mi = _lh.findIndex(l => l.id === _msg.log_id)
        if (mi === -1) {
          _lh.push(_msg);
        }
        _lh[mi] = _msg;
      }
      setLastheard(_lh)
    }
  }, [msg])

  useEffect(() => {
    const checkTimeSinceLastUpdate = () => {
      let now = moment();
      let duration = moment.duration(now.diff(lastUpdated));
      return parseInt(duration.asSeconds());
    };
    let refreshInterval = setInterval(() => {
      if (checkTimeSinceLastUpdate() >= 30) {
        getCallog();
      }
    }, 30000);
    return () => clearInterval(refreshInterval);;
  });


  function refreshLastheardDuration(_lastheard) {
    if (_lastheard) {
      let tmplastheard = [..._lastheard];
      for (let i = 0; i < tmplastheard.length; i++) {
        if (!tmplastheard[i].endtime) {
          let entry = { ...tmplastheard[i] }
          entry.duration = dur(entry.starttime, new Date())
          tmplastheard[i] = entry
          setLastheard(tmplastheard);
        }
      }
    }
  };

  useEffect(() => {
    let refreshInterval = setInterval(() => {
      refreshLastheardDuration(lastheard);
    }, 1000);
    return () => clearInterval(refreshInterval);
  });

  const gridOptions = {
    getRowId(data) {
      return data.data.id;
    },
    getRowStyle: data => {
      if (!data.data.endtime) {
        return { background: 'pink' }
      }
    },
    paginationPageSize: 20,
    columnDefs: [
      {
        headerName: "Callsign",
        field: "dmrid",
        cellRenderer: DisplayCallsign,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains'],
          textFormatter: (d) => {
            return d.callsign ? `${d.callsign} ${d.id}`.toString().toLowerCase() : d.toString().toLowerCase()
          },
        },
      }, {
        headerName: "Name",
        field: "dmrid.name",
        cellRenderer: function (params) {
          return (<a href={`/user/${params.data.dmrid.user_id}`}>{params.value}</a>)
        }
      }, {
        headerName: "Via",
        width: 250,
        field: "via",
        cellRenderer: DisplayHotspotName,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains'],
          textFormatter: (v) => {
            return v.peer ? `${v.peer.callsign} ${v.peer.location} ${v.peer.id}`.toString().toLowerCase() : v.toString().toLowerCase()
          },
        }
      }, {
        headerName: `Time (${moment.tz(JSON.parse(localStorage.getItem('user')).timezone).zoneAbbr()})`,
        field: "starttime",
        filter: false,
        sort: "desc",
        cellRenderer: function (params) {
          return (
            <Moment format={`HH:mm:ss Do MMM`}
              tz={JSON.parse(localStorage.getItem('user')).timezone}>
              {params.value}
            </Moment>
          )
        }
      }, {
        headerName: "Duration",
        filter: false,
        sortable: false,
        cellRenderer: function (params) {
          return (
            params.data.endtime
              ? dur(params.data.starttime, params.data.endtime)
              : <strong>Talking<br />{params.data.duration}</strong>
          )
        }
      }
    ]
  }

  return (
    <Container>
      <p>Call log for this talkgroup</p>
      <Table
        gridOptions={gridOptions}
        rowData={lastheard}
      />
    </Container>
  )

}

export default Callogs;
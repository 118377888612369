import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from "../../images/Raynet-logo.png";
//import Container from "./Container";
import axios from "axios";

import { Nav, Navbar, NavDropdown, Container } from 'react-bootstrap';

function Navheader(props) {
    const [toggle, setToggle] = useState();

    function toggler() { setToggle(!toggle) }

    function logout() {
        axios.delete(`${process.env.REACT_APP_API}/users/sign_out.json`)
            .then(() => {
                localStorage.removeItem("admin_groups");
                localStorage.removeItem("admin");
                localStorage.removeItem("user");
                localStorage.removeItem("raynet_groups");
                window.location = "/";
            })
            .catch((error) => console.error(error));
    }

    return (
        <Navbar collapseOnSelect expand="sm" className="navbar-custom" variant="dark"  onToggle={toggler}>
            <Container >
                <Navbar.Brand href="/">
                    <img
                        alt=""
                        src={logo}
                        width="30"
                        height="30"
                        className="border-radius-20"
                    />{' '}
                    Raynet-UK DMR</Navbar.Brand>
                <Navbar.Toggle aria-controls="General-menu" />
                <Navbar.Collapse id="General-menu">
                    <Nav className="me-auto">
                        <Nav.Link href="/groups">Groups</Nav.Link>
                        {localStorage.getItem('admin') ?
                            <Nav.Link href="/users">Users</Nav.Link>
                            : null}
                        <Nav.Link href="/gps">GPS</Nav.Link>
                        <Nav.Link href="/messaging">Messaging</Nav.Link>
                    </Nav>
                    {!toggle &&
                        <Nav className="justify-content-center">
                            <Navbar.Text className="navtitle">
                                {props.title}
                            </Navbar.Text>
                        </Nav>
                    }
                    <Nav className="justify-content-end">
                        <form action="/search" className="d-flex">
                            <input name="q" className="form-control me-2" type="search" placeholder="Search" size="8" aria-label="Search" />
                        </form>

                        <NavDropdown title="Info" id="collasible-nav-info">
                            <NavDropdown.Item href="/ysfdb" target="_blank">YSF Dashboard</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="/info/announcements">Announcements</NavDropdown.Item>
                            <NavDropdown.Item href="/info/stats">Stats</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="/info/logs">Logs</NavDropdown.Item>
                            {localStorage.getItem('admin') && <>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="/info/nonmembers">Non Members</NavDropdown.Item>
                            </>
                            }
                            {localStorage.getItem('admin') && <>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="/info/repeatertrustees">Repeater Trustees</NavDropdown.Item>
                            </>
                            }

                        </NavDropdown>
                        {localStorage.getItem('admin') &&
                            <NavDropdown title="Settings" id="collasible-nav-settings">
                                <NavDropdown.Item href="/settings/openbridge">Open Bridges</NavDropdown.Item>
                                <NavDropdown.Item href="#">YSF Bridges</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#">Mailshots</NavDropdown.Item>
                                <NavDropdown.Item href="/settings/timeformats">Time Formats</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="/users/blocked">Blocked Users</NavDropdown.Item>
                            </NavDropdown>
                        }
                        <NavDropdown title="Help" id="collasible-nav-help">
                            <NavDropdown.Item href="https://help.m0guy.com" target="_blank">Documentation</NavDropdown.Item>
                            <NavDropdown.Item href="https://raynet-uk-dmr.boards.net/" target="_blank">Forum</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown style={{ right: 0 }} title={JSON.parse(localStorage.getItem('user')).firstName} id="collasible-nav-help">
                            <NavDropdown.Item href={`/user/${JSON.parse(localStorage.getItem('user')).id}`}>User Profile</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => logout()}>Logout</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );

}

export default Navheader;
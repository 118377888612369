import React from "react";

function DisplayRICCallsign(props) {
  return (<>
    <strong><span className="callsign">{props.value}</span></strong>
    <br />
    (<span className="callsign">{props.data.ric}</span>)
  </>
  )
}

export default DisplayRICCallsign;

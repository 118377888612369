import React from "react";

function GroupAdmins (props) {
  return (
    <div>{props.value.map((admin, index) =>  (<>{index ? ', ' : ''}<a href={`mailto:${admin.email}`}>{admin.name}</a></>))}</div>
  );

}

export default GroupAdmins;

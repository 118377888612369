import React, { useEffect, useState } from "react";
import { Button, Row, Col, Table, Modal, Form } from "react-bootstrap";
import Container from "../../components/Layout/Container";
import Panel from "../../components/Panel/Panel";
import AgTable from "../../components/Table/Table";
import axios from "axios";
import moment from "moment";
import {
  faCaretRight,
  faCaretDown
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DisplayCallsign from "../../components/Helper/DisplayCallsign";
import GPSHeading from "../../components/Helper/GPSHeading";
import DisplayCellTime from "../../components/CellRenderers/DisplayTime";
import NiceDate from "../../components/Helper/NiceDate";
import Gmap from "./GoogleMap";

function GPSLogs({ setPageTitle }) {
  const [gpslogs, setGpslogs] = useState();
  const [gpslogsUpdated, setgpslogsUpdated] = useState(moment());
  const [expandedRows, setExpandedRows] = useState([]);
  const [expandState, setExpandState] = useState({});
  const handleEpandRow = (event, userId) => {
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(userId);

    let obj = {};
    isRowExpanded ? (obj[userId] = false) : (obj[userId] = true);
    setExpandState(obj);

    // If the row is expanded, we are here to hide it. Hence remove
    // it from the state variable. Otherwise add to it.
    const newExpandedRows = isRowExpanded ?
      currentExpandedRows.filter(id => id !== userId) :
      currentExpandedRows.concat(userId);

    setExpandedRows(newExpandedRows);
  }

  setPageTitle("GPS");

  const getGPSLogs = () => {
    axios
      .get(`${process.env.REACT_APP_API}/api/v1/user/gpslogs.json`)
      .then(({ data }) => {
        setGpslogs(data.gps);
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    getGPSLogs();
  }, []);

  useEffect(() => {
    const checkTimeSinceLastUpdate = () => {
      let now = moment();
      let duration = moment.duration(now.diff(gpslogsUpdated));

      return parseInt(duration.asSeconds());
    };
    let refreshInterval = setInterval(() => {
      if (checkTimeSinceLastUpdate() >= 30) {
        getGPSLogs();
        setgpslogsUpdated(moment());
      }
    }, 30000);
    return () => clearInterval(refreshInterval);;
  }, [gpslogsUpdated]);


  return (
    <>
      <Container>
        <Panel>
          <Gmap gpslogs={gpslogs} />
        </Panel>
        <Panel>
          <Table responsive className="table" id="gpslogs">
            <thead><tr>
              <th></th>
              <th>Callsign (DMRID)</th>
              <th valign="middle">Time ({moment.tz(JSON.parse(localStorage.getItem('user')).timezone).zoneAbbr()})</th>
              <th valign="middle">Longitude</th>
              <th valign="middle">Latitude</th>
              <th valign="middle">Speed</th>
              <th valign="middle">Direction</th>
            </tr>
            </thead>
            <tbody>
              {gpslogs && gpslogs.length > 0
                ?
                <>{gpslogs.map((g, index) => (
                  <React.Fragment key={index}>
                    <tr key={index}>
                      <td><span size="sm" variant="info" onClick={event => handleEpandRow(event, g.id)}>{expandState[g.id] ?
                        <FontAwesomeIcon
                          fixedWidth
                          icon={faCaretDown}
                          className="mr-2 text-gray-600"
                        />
                        : <FontAwesomeIcon
                          fixedWidth
                          icon={faCaretRight}
                          className="mr-2 text-gray-600"
                        />
                      }</span></td>
                      <td><DisplayCallsign dmrid={g} /></td>
                      {g.gps.length > 0 ? <>
                        <td><NiceDate date={g.gps[0].created_at} /></td>
                        <td>{g.gps[0].position.lng} {g.gps[0].position.lng_dir}</td>
                        <td>{g.gps[0].position.lat} {g.gps[0].position.lat_dir}</td>
                        <td>{g.gps[0].speed}</td>
                        <td>{g.gps[0].direction} ({GPSHeading(g.gps[0].direction)})</td>
                      </>
                        : <td colSpan="5"><strong>No GPS Data Received</strong></td>
                      }

                    </tr>
                    <>
                      {
                        expandedRows.includes(g.id) ?
                          <>
                            <tr key={g.id}>
                              <td colSpan="7">
                                <Panel>
                                  <AgTable
                                    gridOptions={{
                                      getRowId(data) {
                                        return data.data.id;
                                      },
                                      paginationPageSize: 15,
                                      columnDefs: [
                                        {
                                          headerName: `Time (${moment.tz(JSON.parse(localStorage.getItem('user')).timezone).zoneAbbr()})`,
                                          field: "created_at",
                                          sort: "desc",
                                          cellRenderer: DisplayCellTime,
                                        }, {
                                          headerName: "Longitude",
                                          field: "position",
                                          cellRenderer: function (params) {
                                            return `${params.value.lng} ${params.data.position.lng_dir}`
                                          }
                                        }, {
                                          headerName: "Latitude",
                                          field: "position",
                                          cellRenderer: function (params) {
                                            return `${params.value.lat} ${params.data.position.lat_dir}`
                                          }

                                        }, {
                                          headerName: "Speed",
                                          field: "speed"
                                        }, {
                                          headerName: "Direction",
                                          field: "direction",
                                          cellRenderer: function (params) {
                                            return `${params.value} (${GPSHeading(params.value)})`
                                          }
                                        }
                                      ]
                                    }}
                                    rowData={g.gps}
                                  />
                                </Panel>
                              </td>
                            </tr>
                          </>
                          : null
                      }
                    </>
                  </React.Fragment>
                ))}
                </>
                : null
              }
            </tbody>
          </Table>
        </Panel>
      </Container>
    </>
  );
}

export default GPSLogs;
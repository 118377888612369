
import React, { useEffect, useState } from "react";
import axios from "axios";
import LoadingSpinner from "../../components/Helper/LoadingSpinner";
import Repeaters from "./Repeaters";
import Peers from "./Peers";
import Radioids from "./Radioids";
import Dmrids from "./Dmrids";
import Users from "./Users";
import Talkgroups from "./Talkgroups";


function Search() {
  const queryParams = new URLSearchParams(window.location.search);
  const q = queryParams.get('q');
  const [results, setResults] = useState();

  const search = () => {
    if (q.length > 3) {
      axios
        .post(`${process.env.REACT_APP_API}/api/v1/search.json`, { q: q })
        .then(({ data }) => {
          setResults(data.results)
        })
        .catch((error) => console.error(error));
    } else {
      window.location = "/";
    }
  }

  useEffect(() => {
    search();
  }, []);

  if (!results) { return (<LoadingSpinner />) }

  return (
    <>
      <h1>Search Results for {results.q}</h1>

      {results.users.length > 0 ? <Users users={results.users} /> : null}
      {results.dmrids.length > 0 ? <Dmrids dmrids={results.dmrids} /> : null}
      {results.radioids.length > 0 ? <Radioids radioids={results.radioids} /> : null}
      {results.repeaters.length > 0 ? <Repeaters repeaters={results.repeaters} /> : null}
      {results.peers.length > 0 ? <Peers peers={results.peers} /> : null}
      {results.talkgroups.length > 0 ? <Talkgroups talkgroups={results.talkgroups} /> : null}
    </>
  )
}

export default Search;
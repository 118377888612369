import React from "react";
import Table from "../../components/Table/Table";
import moment from "moment";
import "./Dashboard.css";
import LoadingSpinner from "../../components/Helper/LoadingSpinner";

import DisplayCallsign from "../../components/CellRenderers/DisplayCallsign";
import DisplayHotspotName from "../../components/CellRenderers/DisplayHotspotName";
import Moment from 'react-moment';
import DisplayTalkgroup from "../../components/CellRenderers/DisplayTalkgroup";

import dur from "../../utils/duration";


function Lastheard({ lastheard }) {
  const left_columns = [
    {
      headerName: "Callsign",
      field: "dmrid",
      sortable: false,
      minWidth: 120,
      maxWidth: 300,
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        textFormatter: (d) => {
          return d.callsign ? `${d.callsign} ${d.id}`.toString().toLowerCase() : d.toString().toLowerCase()
        },
      },
      cellRenderer: DisplayCallsign,
    }, {
      headerName: "Name",
      field: "dmrid.name",
      minWidth: 100,
      maxWidth: 300,
      cellRenderer: function (params) {
        return JSON.parse(localStorage.getItem('admin')) && !params.data.dmrid.nonmember
          ? <a href={`/user/${params.data.dmrid.user_id}`}>{params.data.dmrid.name}</a>
          : params.data.dmrid.name
      }
    }
  ]

  const via_column = {
    headerName: "Via",
    field: "via",
    sortable: false,
    wrapText: false,
    minWidth: 170,
    maxWidth: 300,
    cellRenderer: DisplayHotspotName,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      textFormatter: (v) => {
        return v.peer ? `${v.peer.callsign} ${v.peer.location} ${v.peer.id}`.toString().toLowerCase() : v.toString().toLowerCase()
      },
    }
  }

  const right_columns = [
    {
      headerName: `Talkgroup`,
      field: "talkgroup",
      minWidth: 190,
      wrapText: false,
      maxWidth: 500,
      comparator: (valueA, valueB) => valueA.number - valueB.number,
      filterParams: {
        filterOptions: ['contains'],
        textFormatter: (d) => {
          return d.number ? `${d.number} ${d.raynet_group.name} ${d.name}`.toString().toLowerCase() : d.toString().toLowerCase()
        },
      },
      cellRenderer: DisplayTalkgroup
    }, {
      headerName: `Time (${moment.tz(JSON.parse(localStorage.getItem('user')).timezone).zoneAbbr()})`,
      field: "starttime",
      filter: false,
      sort: "desc",
      minWidth: 170,
      maxWidth: 300,
      cellRenderer: function (params) {
        return (
          <Moment format={`HH:mm:ss Do MMM`}
            tz={JSON.parse(localStorage.getItem('user')).timezone}>
            {params.value}
          </Moment>

        )
      }
    }, {
      headerName: "Duration",
      filter: false,
      sortable: false,
      minWidth: 100,
      maxWidth: 150,
      cellRenderer: function (params) {
        return (
          params.data.endtime
            ? dur(params.data.starttime, params.data.endtime)
            : <strong>Talking<br />{params.data.duration}</strong>
        )
      }
    }
  ]

  if (!lastheard) { 
    return (<LoadingSpinner title="Raynet DMR | Dashboard" />); 
  }
  
  function gridcols() {
    if ( window && window.innerWidth > 800) {
      return [...left_columns, via_column, ...right_columns]
    } else {
      return [...left_columns, ...right_columns]
    }
  }

  let gridOptions = {
    getRowId(data) {
      return data.data.id;
    },
    getRowStyle: data => {
      if (!data.data.endtime) {
        return { background: 'pink' }
      }
    },
    columnDefs: gridcols()
  }

  return (
    <div>
      <Table responsive
        gridOptions={gridOptions}
        rowData={lastheard}
        pagination={false}
      />
    </div>
  );
}

export default Lastheard;


import React, { useEffect, useState } from "react";
import { Button, Row, Col, Table, InputGroup, FormControl, Form } from "react-bootstrap";
import axios from "axios";
import Panel from "../Panel/Panel";
import toast from "react-hot-toast";



function BrowserGPS(props) {
  const [BGPS, setBGPS] = useState({ long: "", lat: "" });
  const [DMRID, setDMRID] = useState();

  function sendGPSBack(gpslog) {
    let sendGPS = axios
      .post(`${process.env.REACT_APP_API}/api/v1/user/gpslog.json`, { gpslog })
      .then(({ data }) => {
        if ( data.status === 'ok') {
          
        } else {
          toast.error(data.error)
        }
      })
      .catch((error) => console.error(error));
    toast.promise(sendGPS, {
      loading: "Posting GPS...",
      success: "GPS Posted",
      error: "Failed to send GPS",
    });
  }

  function browserGPS() {
    if (DMRID || props.dmrid) {
      navigator.geolocation.getCurrentPosition(
        position => {
          sendGPSBack({ dmrid_id: DMRID ? DMRID : props.dmrid, long: position.coords.longitude, lat: position.coords.latitude })
          setBGPS({ long: position.coords.longitude, lat: position.coords.latitude })
        }
      )
    }
  }


  return (
    <Row>
      <Col>
        <Table borderless>
          <thead>
            <tr><th>Send GPS location via Browser</th></tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <InputGroup>
                { !props.dmrid ? <>
                  <Form.Select aria-label="Select Callsign" onChange={(e) => setDMRID(e.target.value)}>
                    <option key="00">-- Select your Callsign --</option>
                    {props.dmrids ? props.dmrids.map((dmrid, index) => (
                      <option key={index} value={dmrid.id}>{dmrid.callsign} ({dmrid.id})</option>
                    )
                    )
                      : ''}                      
                  </Form.Select>
                  </>
                  : '' }
                  <Button size="sm" variant="success" onClick={() => browserGPS()} disabled={!DMRID}>Send GPS</Button>
                </InputGroup>
              </td>
            </tr><tr>
              <td>
                <p><strong>Longitude</strong> {BGPS.long && BGPS.long.toPrecision(6)}</p>
                <p><strong>Latitude</strong> {BGPS.lat && BGPS.lat.toPrecision(6)}</p>
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  )

}

export default BrowserGPS;
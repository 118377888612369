import React, { useState } from "react";
import { Helmet } from "react-helmet";
import logo from "../../images/Raynet-logo.png";
import LoginForm from "../../components/Pages/Login/LoginForm";
import ToastComponent from "../../components/Toast/ToastComponent";
import ForgotPassword from "../../components/Pages/Login/ForgotPassword";
import bg from "../../images/RAYNET-login-bg.png";
import { Button, Row, Col } from "react-bootstrap";
import "./Login.css";

function Login({ setToken }) {
  const [hasForgotPassword, setHasForgotPassword] = useState(false);

  document.body.style.backgroundImage = `url(${bg}`;
  //document.body.style.backgroundColor = "green";
  return (
    <>
      <Helmet title="Raynet DMR | Log in" />
      <ToastComponent />

      <div className="main">
          <img src={logo} className="border-radius-20 center-img" width="100" height="100" alt="Raynet-UK DMR Logo" />
          <p className="sign" align="center">Raynet-UK DMR</p>

          {hasForgotPassword
            ? <ForgotPassword setHasForgotPassword={setHasForgotPassword} />
            : <LoginForm setHasForgotPassword={setHasForgotPassword} />
          }

          <Row>
            <Col><Button size="sm" className="left"  variant="warning" onClick={() => window.open("mailto:ost@raynet-uk.net?subject=[RAYNET DMR] Request Access&body=Hello Technical,\n\nPlease can you create an account for me to access the RAYNET DMR service.\n\n\nMy Callsign is: \n\nI'm a member of RAYNET Group: \n\n\nThanks", "_blank")}>Request Access</Button></Col>
            <Col><Button size="sm" className="right" variant="info"    onClick={() => window.open("https://help.m0guy.com", "_blank")}>Help?</Button></Col>
          </Row>
      </div>
    </>
  );
}

export default Login;

import React, { useEffect, useState } from "react";
import Panel from "../../components/Panel/Panel";
import { Button } from "react-bootstrap";
import Table from "../../components/Table/Table";
import "./Dashboard.css";
import axios from "axios";

import DisplayHotspotName from "../../components/Helper/DisplayHotspotName";
import DisplayGroupname from "../../components/CellRenderers/DisplayGroupname";
import DisplayTalkgroup from "../../components/CellRenderers/DisplayTalkgroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretRight,
  faCaretDown
} from "@fortawesome/free-solid-svg-icons";


function OpenBridges() {
  const [openbridges, setOpenbridges] = useState(null);

  // State variable to keep track of all the expanded rows
  // By default, nothing expanded. Hence initialized with empty array.
  const [expandedRows, setExpandedRows] = useState([]);
  // State variable to keep track which row is currently expanded.
  const [expandState, setExpandState] = useState({});
  const handleEpandRow = (event, userId) => {
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(userId);

    let obj = {};
    isRowExpanded ? (obj[userId] = false) : (obj[userId] = true);
    setExpandState(obj);

    // If the row is expanded, we are here to hide it. Hence remove
    // it from the state variable. Otherwise add to it.
    const newExpandedRows = isRowExpanded ?
      currentExpandedRows.filter(id => id !== userId) :
      currentExpandedRows.concat(userId);

    setExpandedRows(newExpandedRows);
  }

  const getOpenbridges = () => {
    axios
      .get(`${process.env.REACT_APP_API}/api/v1/dashboard/openbridges.json`)
      .then(({ data }) => {
        setOpenbridges(data.openbridges);
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    getOpenbridges();
  }, []);

  return (
    <div>
      <table className="table" key="openbridges">
        <thead>
          <tr>
            <th></th>
            <th>Bridge</th>
            <th></th>
            <th></th>
            <th>Location</th>
            <th>URL</th>
            <th>Active</th>
          </tr>
        </thead>
        <tbody>
          {openbridges
            ? <>{openbridges.map((p, index) => (
              <React.Fragment key={index}>
                <tr key={`bridge-${p.openbridge.id}`} id={`bridge-${p.openbridge.id}`}>
                  <td><span variant="info" onClick={event => handleEpandRow(event, p.openbridge.id)} size="sm">{expandState[p.openbridge.id]
                    ?
                    <FontAwesomeIcon
                      fixedWidth
                      icon={faCaretDown}
                      className="mr-2 text-gray-600"
                    />
                    : <FontAwesomeIcon
                      fixedWidth
                      icon={faCaretRight}
                      className="mr-2 text-gray-600"
                    />
                  }</span></td>
                  <td><strong><DisplayHotspotName hs={p} /></strong></td>
                  <td></td>
                  <td></td>
                  <td>{p.openbridge.location}</td>
                  <td><a href={p.openbridge.url} target="_blank" rel="noreferrer" >{p.openbridge.url}</a></td>
                  <td>{p.openbridge.active ? 'Active' : 'Disabled'}</td>
                </tr>
                {
                  expandedRows.includes(p.openbridge.id) ?
                    <tr key={`bridge-talkgroups-${p.openbridge.id}`} id={`bridge-talkgroups-${p.openbridge.id}`}>
                      <td colSpan="7">
                        <Panel>
                          <Table
                            gridOptions={{
                              getRowId(data) {
                                return `bridge-${p.openbridge.id}-${data.data.id}`;
                              },
                              paginationPageSize: 15,
                              columnDefs: [
                                {
                                  headerName: "Remote Talkgroup",
                                  field: "dest_talkgroup",
                                }, {
                                  headerName: "Local Talkgroup",
                                  field: "talkgroup",
                                  sort: "desc",
                                  comparator: (valueA, valueB) => valueA.number - valueB.number,
                                  filterParams: {
                                    filterOptions: ['contains'],
                                    textFormatter: (d) => { 
                                      return d.number ? `${d.number} ${d.name}`.toString().toLowerCase() : d.toString().toLowerCase()
                                    },
                                  },                          
                                  cellRenderer: DisplayTalkgroup,
                                }, {
                                  headerName: "Status",
                                  field: "active",
                                  cellRenderer: function (params) {
                                    return (params.value ? "Active" : "Disabled")
                                  }
                                }
                              ]
                            }}
                            rowData={p.ob_talkgroups}
                          />
                        </Panel>
                      </td>
                    </tr>
                    : null
                }
              </React.Fragment>
            ))
            }</>
            : null}
        </tbody>
      </table>
    </div>

  )
}

export default OpenBridges;

import React, { useEffect, useState } from "react";
import { Button, Row, Col, Table, InputGroup, FormControl, Form, Container } from "react-bootstrap";
import ButtonSure from "../../Helper/ButtonSure";
import AgTable from "../../Table/Table";
import Panel from "../../Panel/Panel";
import DisplayCallsign from "../../../components/Helper/DisplayCallsign";
import DisplayCellTime from "../../../components/CellRenderers/DisplayTime";
import moment from "moment";
import axios from "axios";
import GPSHeading from "../../../components/Helper/GPSHeading";
import NiceDate from "../../../components/Helper/NiceDate";
import {
  faCaretRight,
  faCaretDown,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast from "react-hot-toast";
import BrowserGPS from "../../BrowserGPS/BrowserGPS";

function GPSLogs({ gps, dmrids, user_id, getUser }) {
  const [whoacl, setWhoacl] = useState();
  const [whouser, setWhouser] = useState();
  const [userid, setUserid] = useState();
  const [expandedRows, setExpandedRows] = useState([]);
  const [expandState, setExpandState] = useState({});
  const handleEpandRow = (event, userId) => {
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(userId);

    let obj = {};
    isRowExpanded ? (obj[userId] = false) : (obj[userId] = true);
    setExpandState(obj);

    // If the row is expanded, we are here to hide it. Hence remove
    // it from the state variable. Otherwise add to it.
    const newExpandedRows = isRowExpanded ?
      currentExpandedRows.filter(id => id !== userId) :
      currentExpandedRows.concat(userId);

    setExpandedRows(newExpandedRows);
  }

  function deletegpsentry(id) {
    let deletegps = axios
      .delete(`${process.env.REACT_APP_API}/api/v1/user/gpslog/${id}.json`)
      .then(({ data }) => {
        console.log("approve", data)
        getUser();
      })
      .catch((error) => console.error(error));
    toast.promise(deletegps, {
      loading: "Deleting Entry...",
      success: "GPS log entry deleted",
      error: "Failed to delete entry",
    });
  }

  function deleteacl(id) {
    let deletegpsacl = axios
      .delete(`${process.env.REACT_APP_API}/api/v1/user/gpsacl/${id}.json`)
      .then(({ data }) => {
        console.log("approve", data)
        getUser();
      })
      .catch((error) => console.error(error));
    toast.promise(deletegpsacl, {
      loading: "Deleting Entry...",
      success: "GPS ACL entry deleted",
      error: "Failed to delete ACL",
    });
  }

  function createacl(gpsacl = {}) {
    let createaclentry = axios
      .post(`${process.env.REACT_APP_API}/api/v1/user/gpsacl.json`, { gpsacl })
      .then(({ data }) => {
        console.log("createacl", data)
        getUser();
      })
      .catch((error) => console.error(error));
    toast.promise(createaclentry, {
      loading: "Creating ACL Entry...",
      success: "GPS ACL entry added",
      error: "Failed to add ACL",
    });
  }

  function approveacl(id) {
    let approveaclgps = axios
      .get(`${process.env.REACT_APP_API}/api/v1/user/gpsacl/approve/${id}.json`)
      .then(({ data }) => {
        console.log("approve", data)
        getUser();
      })
      .catch((error) => console.error(error));
    toast.promise(approveaclgps, {
      loading: "Approving ACL...",
      success: "ACL Approved",
      error: "Failed to approve ACL",
    });
  }

  function GpsForDmrid({ dmrid }) {
    return (
      <AgTable
        gridOptions={{
          getRowId(data) {
            return data.data.id;
          },
          paginationPageSize: 15,
          columnDefs: [
            {
              headerName: `Time (${moment.tz(JSON.parse(localStorage.getItem('user')).timezone).zoneAbbr()})`,
              field: "created_at",
              sort: "desc",
              cellRenderer: DisplayCellTime,
            }, {
              headerName: "Longitude",
              field: "position",
              cellRenderer: function (params) {
                return `${params.value.lng} ${params.data.position.lng_dir}`
              }
            }, {
              headerName: "Latitude",
              field: "position",
              cellRenderer: function (params) {
                return `${params.value.lat} ${params.data.position.lat_dir}`
              }

            }, {
              headerName: "Speed",
              field: "speed"
            }, {
              headerName: "Direction",
              field: "direction",
              cellRenderer: function (params) {
                return `${params.value} (${GPSHeading(params.value)})`
              }
            }, {
              headerName: "",
              cellRenderer: function (params) {
                return <ButtonSure
                  actionName={<FontAwesomeIcon fixedWidth icon={faTrash} />}
                  tooltip="Delete GPS Entry"
                  onClick={() => deletegpsentry(params.data.id)}>
                  Do you want to remove this GPS log entry
                </ButtonSure>

              }
            }
          ]
        }}
        rowData={dmrid.gps}
      />
    )
  }

  return (
    <>
      <Panel>
        <h1>GPS Access Control</h1>
        <p>Here you can see who can see your GPS locations, and whose you can see. Users can request to see your GPS location but until you accept it here they will not be permitted.</p>
        <p>The Map will show all the users who have permitted you to see their locations.</p>
        <Row>
          <Col>
            <Table borderless>
              <thead>
                <tr>
                  <th className="text-center">People who can see your GPS information</th>
                </tr>
              </thead>
              <tbody>
                <tr><td>
                  <p>If you add them they are automatically approved. If they have made the request then you must approve it first.</p>
                  <AgTable
                    gridOptions={{
                      getRowId(data) {
                        return data.data.id;
                      },
                      paginationPageSize: 10,
                      columnDefs: [
                        {
                          headerName: "User",
                          field: "user.name",
                        }, {
                          headerName: "Action",
                          cellRenderer: function (params) {
                            return (
                              <div className="btn-group">
                                {!params.data.allowed
                                  ? <ButtonSure
                                    actionName="Approve"
                                    variant="warning"
                                    tooltip="Approve this request"
                                    onClick={() => approveacl(params.data.id)}>
                                    Approving this request will grant the user access to your GPS location information. You can later delete this approval.
                                  </ButtonSure>
                                  : null
                                }
                                <ButtonSure
                                  actionName={<FontAwesomeIcon fixedWidth icon={faTrash} />}
                                  tooltip="Remove Access"
                                  onClick={() => deleteacl(params.data.id)}>
                                  Deleting this entry will prevent this user from seeing all your GPS information
                                </ButtonSure>
                              </div>
                            )
                          }
                        }
                      ]

                    }}
                    rowData={gps.acls}
                  />
                </td></tr>
                <tr><td>
                  <div style={{ float: "right" }}>
                    <InputGroup>
                      <input type="text" placeholder="Callsign or E-Mail" onChange={(e) => setWhoacl(e.target.value)} value={whoacl} />
                      <Button onClick={() => createacl({ user_id: user_id, who: whoacl })}>Add</Button>
                    </InputGroup>
                  </div>
                </td></tr>
              </tbody>
            </Table>
          </Col>
          <Col>
            <Table borderless>
              <thead>
                <tr><th className="text-center">People whose GPS information you are permitted to see.</th></tr>
              </thead>
              <tbody>
                <tr><td>
                  <p>The user will need to approve your request before you can see their GPS information. The status of the request will be displayed below.</p>
                  <AgTable
                    gridOptions={{
                      getRowId(data) {
                        return data.data.id;
                      },
                      paginationPageSize: 10,
                      columnDefs: [
                        {
                          headerName: "User",
                          field: "user.name",
                        }, {
                          headerName: "Status",
                          field: "allowed",
                          cellRenderer: function (params) {
                            return (
                              params.value ? "Approved" : "Pending"
                            )
                          }
                        }, {
                          headerName: "Action",
                          cellRenderer: function (params) {
                            return (
                              <ButtonSure
                                actionName={<FontAwesomeIcon fixedWidth icon={faTrash} />}
                                tooltip="Remove access"
                                onClick={() => deleteacl(params.data.id)}>
                                Deleting this entry will prevent you from seeing this persons GPS information
                              </ButtonSure>
                            )
                          }
                        }
                      ]

                    }}
                    rowData={gps.permitted}
                  />
                </td></tr>
                <tr><td>
                  <div style={{ float: "right" }}>
                    <InputGroup>
                      <input type="text" placeholder="Callsign or E-Mail" onChange={(e) => setWhouser(e.target.value)} value={whouser} />
                      <Button onClick={() => createacl({ acl_user_id: user_id, who: whouser })}>Add</Button>
                    </InputGroup>
                  </div>
                </td></tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Panel>
      <Panel>
        <h1>Your GPS Information</h1>
        <Container fluid="md">
        <Row>
          <Col>
            <p>Here is a list of all the GPS log entries that you've made from your DMRIDs. You can delete any of your entries from the system via this page. </p>
            <p>To post your GPS location from your radio you will need to configure your radio to send APRS information to DMR Talkgroup <strong></strong>.</p>
          </Col>
          <Col xs={4}>
            <BrowserGPS dmrids={dmrids} />
          </Col>
        </Row>
        </Container>

        <Table responsive className="table" id="gpslogs">
          <thead><tr>
            <th></th>
            <th>Callsign (DMRID)</th>
            <th valign="middle">Time ({moment.tz(JSON.parse(localStorage.getItem('user')).timezone).zoneAbbr()})</th>
            <th valign="middle">Longitude</th>
            <th valign="middle">Latitude</th>
            <th valign="middle">Speed</th>
            <th valign="middle">Direction</th>
          </tr>
          </thead>
          <tbody>
            {dmrids
              ?
              <>{dmrids.map((g, index) => (
                <React.Fragment key={index}>
                  <tr key={index}>
                    <td><span size="sm" variant="info" onClick={event => handleEpandRow(event, g.id)}>{expandState[g.id] ?
                      <FontAwesomeIcon
                        fixedWidth
                        icon={faCaretDown}
                        className="mr-2 text-gray-600"
                      />
                      : <FontAwesomeIcon
                        fixedWidth
                        icon={faCaretRight}
                        className="mr-2 text-gray-600"
                      />
                    }</span></td>
                    <td><DisplayCallsign dmrid={g} /></td>
                    {g.gps.length > 0 ? <>
                      <td><NiceDate date={g.gps[0].created_at} /></td>
                      <td>{g.gps[0].position.lng} {g.gps[0].position.lng_dir}</td>
                      <td>{g.gps[0].position.lat} {g.gps[0].position.lat_dir}</td>
                      <td>{g.gps[0].speed}</td>
                      <td>{g.gps[0].direction} ({GPSHeading(g.gps[0].direction)})</td>
                    </>
                      : <td colSpan="5"><strong>No GPS Data Received</strong></td>
                    }
                  </tr>
                  <>
                    {
                      expandedRows.includes(g.id) ?
                        <>
                          <tr key={g.id}>
                            <td colSpan="7"><GpsForDmrid dmrid={g} /></td>
                          </tr>
                        </>
                        : null
                    }
                  </>
                </React.Fragment>
              ))}
              </>
              : null
            }
          </tbody>
        </Table>

      </Panel>
    </>
  )
}

export default GPSLogs;
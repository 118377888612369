import React, { useEffect, useState } from "react";
import { Button, Row, Col, Table, Modal, Form, InputGroup, ButtonGroup } from "react-bootstrap";
import DisplayHotspotName from "../../Helper/DisplayHotspotName";
import Container from "../../Layout/Container";
import moment from "moment";
import NiceDate from "../../Helper/NiceDate";
import StaticTalkgroupList from "../../Helper/StaticTalkgroupList";
import StaticTalkgroups from "./Hotspots/StaticTalkgroups";
import axios from "axios";
import toast from "react-hot-toast";
import ButtonSure from "../../Helper/ButtonSure";
import Panel from "../../Panel/Panel";
import DynamicTalkgroups from "./Hotspots/DynamicTalkgroups";
import ToggleSwitch from "../../../components/ToggleSwitch/ToggleSwitch";
import {
  faCaretRight,
  faCaretDown,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import isadmin from "../../../utils/isadmin";


function Hotspots({ hotspots, memberships, setHotspots, getUser, repeaterkeeper, user }) {
  const [thisPeer, setThisPeer] = useState();
  const [showConfiguration, setShowConfiguration] = useState(false);

  const [dmrid, setDmrid] = useState();
  const [hostspotNumber, setHotspotNumber] = useState();

  const [HSName, _setHSName] = useState([]);

  // State variable to keep track of all the expanded rows
  // By default, nothing expanded. Hence initialized with empty array.
  const [expandedRows, setExpandedRows] = useState([]);
  // State variable to keep track which row is currently expanded.
  const [expandState, setExpandState] = useState({});
  const handleEpandRow = (event, userId) => {
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(userId);

    let obj = {};
    isRowExpanded ? (obj[userId] = false) : (obj[userId] = true);
    setExpandState(obj);

    // If the row is expanded, we are here to hide it. Hence remove
    // it from the state variable. Otherwise add to it.
    const newExpandedRows = isRowExpanded ?
      currentExpandedRows.filter(id => id !== userId) :
      currentExpandedRows.concat(userId);

    setExpandedRows(newExpandedRows);
  }


  const handleCloseConfiguration = () => setShowConfiguration(false);
  const handleShowConfiguration = (p) => {
    setThisPeer(p)
    setShowConfiguration(true);
  }

  const setPeer = (_peer) => {
    let tmppeers = JSON.parse(JSON.stringify(hotspots));
    let index = tmppeers.findIndex(p => p.peer.id === _peer.id)
    tmppeers[index] = _peer
    setHotspots(tmppeers);
    hotspots = tmppeers;
  }

  const createPeerButton = (peer = {}) => {
    peer.user = user.id
    peer.repeater = repeaterkeeper
    peer.dmrid = dmrid
    peer.postfix = hostspotNumber
    let createPeer =
      axios.post(`${process.env.REACT_APP_API}/api/gui/v1/peers.json`, { peer })
        .then(({ data }) => {
          setHotspots([...hotspots, data])
        })
    toast.promise(createPeer, {
      loading: "Creating Hotspot",
      success: "Hotspot Created",
      error: "Could not create new Hotspot",
    })
  };

  const updatePeerPassword = (peer) => {
    let updatePeerP =
      axios.get(`${process.env.REACT_APP_API}/api/gui/v1/peer/${peer.id}/password.json`)
        .then(({ data }) => {
          setPeer(data)
          setThisPeer(data.peer)
        })
    toast.promise(updatePeerP, {
      loading: "Setting new password",
      success: "New Password set",
      error: "Could not set a new password",
    })
  }

  const updatePeer = (id, peer = {}) => {
    let updatePeerRecord =
      axios.post(`${process.env.REACT_APP_API}/api/gui/v1/peer/${id}.json`, { peer })
        .then(({ data }) => {
          setPeer(data)
        })
    toast.promise(updatePeerRecord, {
      loading: "Sending update...",
      success: "Hotspot Updated",
      error: "Could not update Hotspot",
    });
  }

  const blockedToggle = (id) => {
    let updateBlockToggle = axios.get(`${process.env.REACT_APP_API}/api/gui/v1/peer/${id}/block.json`)
      .then(({ data }) => {
        setPeer(data)
      })
    toast.promise(updateBlockToggle, {
      loading: "Updating Block status",
      success: "Block Updated",
      error: "Could not upadte Block status",
    });
  }

  const deletePeerButton = (id) => {
    let deletePeer =
      axios.delete(`${process.env.REACT_APP_API}/api/gui/v1/peer/${id}.json`)
        .then(({ data }) => {
          setHotspots(hotspots.filter(s => s.id !== id));
        })
    toast.promise(deletePeer, {
      loading: "Deleting...",
      success: "Hotspot Deleted",
      error: "Failed to Delete Hotspot",
    });
  }

  const copyAPIToClipboard = (key) => {
    navigator.clipboard.writeText(key);
    toast.success("Key Copied to clipboard");
  }

  const updatePeerKey = (p) => {
    let updatePeerAPIKey =
      axios.get(`${process.env.REACT_APP_API}/api/gui/v1/peer/${p.id}/update_key.json`)
        .then(({ data }) => {
          setPeer(data)
        })
    toast.promise(updatePeerAPIKey, {
      loading: "Creating new API Key...",
      success: "API Key Created",
      error: "Could not create API Key",
    });
  }

  const setStatic_talkgroups = (s) => {
    console.log("Static", s);

  }

  const setDynamic_talkgroups = (s) => {
    console.log("Dynamic", s);
  }

  function pad(n) {
    return (n < 10) ? ("0" + n) : n;
  }

  const nicefreq = num => num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';

  function setHSName(hs, editing, value) {
    let _HSName = [...HSName];
    let mi = _HSName.findIndex(l => l.hs === hs);
    if (mi === -1) {
      _HSName.push({ hs: hs, editing: editing, name: value })
    } else {
      _HSName[mi] = { hs: hs, editing: editing, name: value }
    }
    _setHSName(_HSName)
  }

  function getHSName(hs) {
    let mi = HSName.findIndex(l => l.hs === hs);
    return HSName[mi] ? HSName[mi] : -1;
  }

  function saveHSName(hs) {
    let _name = getHSName(hs).name
    updatePeer(hs, { name: _name })
    setHSName(hs, false, _name)
  }

  useEffect(() => {
    let _HSName = [...HSName];
    for (let i = 0; i < hotspots.length; i++) {
      let mi = _HSName.findIndex(l => l.hs === hotspots[i].peer.id);
      if (mi === -1) {
        _HSName.push({ hs: hotspots[i].peer.id, editing: false, name: hotspots[i].peer.name })
      } else {
        let _edit = _HSName[mi].editing
        if (!_edit) {
          _HSName[mi] = { hs: hotspots[i].peer.id, editing: _edit, name: hotspots[i].peer.name }
        }
      }
    }
    _setHSName(_HSName)
  }, [hotspots]);

  return (
    <Container>

      {repeaterkeeper ?
        <p>You have been authorised to add your repeaters to the RAYNET-UK DMR service. The repeater must have your callsign set as the trustee.</p>
        :
        <p>These are your hotspots. To connect your hotspots to this service, you must add at least one DMRID and create is below. Each hotspot has a unique password and should not be shared.</p>
      }
      <Row className="justify-content-md-center">
        <Col md={8}>
          <InputGroup>
            {user && user.dmrids && user.dmrids.length > 0 ?
              <>
                <Form.Select aria-label="Select DMRID" onChange={(e) => setDmrid(parseInt(e.target.value))}>
                  <option>-- Select DMRID --</option>
                  {repeaterkeeper
                    ? user.repeaters ? user.repeaters.filter(d => d.id.toString().length === (repeaterkeeper ? 6 : 7)).map((dmrid) => (<option key={dmrid.id} value={dmrid.id}>{dmrid.callsign} ({dmrid.id})</option>)) : ''
                    : user.dmrids ? user.dmrids.filter(d => d.id.toString().length === (repeaterkeeper ? 6 : 7)).map((dmrid) => (<option key={dmrid.id} value={dmrid.id}>{dmrid.callsign} ({dmrid.id})</option>)) : ''
                  }
                </Form.Select>
                {!repeaterkeeper ?
                  <Form.Select aria-label="Select Hotspot Number" onChange={(e) => setHotspotNumber(e.target.value)} disabled={!dmrid}>
                    <option>-- Select Hotspot Number --</option>
                    {Array.from({ length: 99 }, (_, i) => i + 1).map(i => pad(i.toString())).filter(l => !user.peers.filter(d => d.peer.dmrid.id === dmrid).map((d) => d.peer.id.toString().substring(7)).includes(l)).map((d) => (<option key={d} value={d}>{d}</option>))}
                  </Form.Select>

                  : null
                }
                <Button variant="primary" onClick={() => createPeerButton()} disabled={!(dmrid && hostspotNumber) && !(dmrid && repeaterkeeper)} >Add {repeaterkeeper ? 'Repeater' : 'Hotspot'}</Button>
              </>
              : null}
          </InputGroup></Col></Row>
      <br />
      <Table className="table" id="peers">
        <thead><tr>
          <th></th>
          <th>Callsign (DMRID)<br />Location</th>
          <th valign="middle">Name</th>
          <th valign="middle">Type</th>
          <th valign="middle">Connected ({moment.tz(JSON.parse(localStorage.getItem('user')).timezone).zoneAbbr()})</th>
          <th valign="middle">Last Seen ({moment.tz(JSON.parse(localStorage.getItem('user')).timezone).zoneAbbr()})</th>
          <th valign="middle">IP</th>
        </tr>
        </thead>
        <tbody>
          {hotspots && hotspots.length > 0 &&
            <>{hotspots.filter(h => h.peer.repeater === repeaterkeeper).map((h, index) => (
              <React.Fragment key={index}>
                <tr key={h.peer.id}>
                  <td><span size="sm" variant="info" onClick={event => handleEpandRow(event, h.peer.id)}>{expandState[h.peer.id] ?
                    <FontAwesomeIcon
                      fixedWidth
                      icon={faCaretDown}
                      className="mr-2 text-gray-600"
                    />
                    : <FontAwesomeIcon
                      fixedWidth
                      icon={faCaretRight}
                      className="mr-2 text-gray-600"
                    />
                  }</span></td>
                  <td><DisplayHotspotName hs={h} /></td>
                  <td>{h.peer.name}</td>
                  <td>{h.peer.duplex ? 'Duplex' : 'Simplex'}</td>
                  <td>{h.peer.online ? <NiceDate date={h.peer.connected} /> : <strong>Disconnected</strong>}</td>
                  <td><NiceDate date={h.peer.last_ping} /></td>
                  <td>{h.peer.ip}</td>
                </tr>
                <>
                  {expandedRows.includes(h.peer.id) &&
                    <>
                      <tr>
                        <td colSpan="7">
                          <Panel>
                            <Container>
                              <Row>
                                <Col>
                                  <Table borderless size="sm">
                                    <thead>
                                      <tr><th colSpan="2" className="text-center">{h.peer.repeater ? 'Repeater' : 'Hotspot'} Details</th></tr>
                                    </thead>
                                    <tbody>
                                      <tr><td align="right"><strong>Name </strong></td><td><Col md={8}>
                                        <InputGroup>
                                          <Form.Control type="text" placeholder="Hotspot Name"
                                            value={getHSName(h.peer.id).name ? getHSName(h.peer.id).name : ''}
                                            onChange={(e) => setHSName(h.peer.id, true, e.target.value)}
                                            disabled={!getHSName(h.peer.id).editing} />
                                          {getHSName(h.peer.id).editing ?
                                            <>
                                              <Button onClick={(e) => saveHSName(h.peer.id)} size="sm" variant="warning">Save</Button>
                                              <Button onClick={(e) => setHSName(h.peer.id, false, h.peer.name)} size="sm" variant="primary">Cancel</Button>
                                            </>
                                            : <Button onClick={(e) => setHSName(h.peer.id, true, h.peer.name)} size="sm" variant="danger">Edit</Button>
                                          }</InputGroup>
                                      </Col></td></tr>
                                      <tr><td align="right"><strong>CCS7/DMR ID </strong></td><td>{h.peer.id}</td></tr>
                                      <tr><td align="right"><strong>Callsign </strong></td><td>{h.peer.callsign}</td></tr>
                                      <tr><td align="right"><strong>Location </strong></td><td>{h.peer.location}</td></tr>
                                      <tr><td align="right"><strong>Country </strong></td><td>{h.peer.description}</td></tr>
                                      <tr><td align="right"><strong>Latitude </strong></td><td>{h.peer.latitude}</td></tr>
                                      <tr><td align="right"><strong>Longitude</strong></td><td>{h.peer.longitude}</td></tr>
                                      <tr><td align="right"><strong>Height</strong></td><td>{h.peer.height}</td></tr>
                                      <tr><td align="right"><strong>Type </strong></td><td>{h.peer.duplex ? 'Duplex' : 'Simplex'}</td></tr>
                                      <tr><td align="right"><strong>Version </strong></td><td>{h.peer.software}</td></tr>
                                      <tr><td align="right"><strong>Modem </strong></td><td>{h.peer.package}</td></tr>
                                      <tr><td align="right"><strong>RX Freq </strong></td><td>{nicefreq(h.peer.RX_Freq)}</td></tr>
                                      <tr><td align="right"><strong>TX Freq </strong></td><td>{nicefreq(h.peer.TX_Freq)}</td></tr>
                                      <tr><td align="right"><strong>Power</strong></td><td>{h.peer.TX_Power}</td></tr>
                                      <tr><td align="right"><strong>Colour Code </strong></td><td>{h.peer.colorcode}</td></tr>
                                      <tr><td align="right"><strong>Paging Frequency </strong></td><td>{h.peer.pager.freq}</td></tr>
                                      <tr><td align="right"><strong>Paging software </strong></td><td>{h.peer.pager.software}</td></tr>
                                      <tr><td align="right"><strong>URL </strong></td><td>{h.peer.url}</td></tr>
                                    </tbody>
                                  </Table>
                                </Col>
                                <Col>
                                  <Row>
                                    <Table borderless size="sm">
                                      <thead>
                                        <tr><th colSpan="2" className="text-center"> Connection Status</th></tr>
                                      </thead>
                                      <tbody>
                                        <tr><td align="right"><strong>Created </strong></td><td><NiceDate date={h.peer.created_at} /></td></tr>

                                        <tr><td colSpan="2">
                                          <Table borderless size="sm">
                                            <thead>
                                              <tr><th colSpan="2" className="text-center">{h.peer.repeater ? 'Repeater' : 'Hotspot'}</th>
                                                <th colSpan="2" className="text-center">Pager</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr><td align="right"><strong>Connected Since </strong></td><td>{h.peer.online ? <NiceDate date={h.peer.connected} /> : <strong>Disconnected</strong>}</td>
                                                <td align="right"><strong>Connected Since </strong></td><td>{h.peer.pager.online ? <NiceDate date={h.peer.pager.connected} /> : <strong>Disconnected</strong>}</td>
                                              </tr>
                                              <tr><td align="right"><strong>Last Seen </strong></td><td><NiceDate date={h.peer.last_ping} /></td>
                                                <td align="right"><strong>Last Seen </strong></td><td><NiceDate date={h.peer.pager.last_ping} /></td>
                                              </tr>
                                              <tr><td align="right"><strong>Ping Count </strong></td><td>{h.peer.pings_received}</td>
                                                <td align="right"><strong>Ping Count </strong></td><td>{h.peer.pager.ping_count}</td>
                                              </tr>
                                              <tr><td align="right"><strong>Authentication Count </strong></td><td>{h.peer.authcount}</td>
                                                <td colSpan="2"></td>
                                              </tr>
                                              <tr><td align="right"><strong>Repeated Auth Count </strong></td><td>{h.peer.authreqcount}</td>
                                                <td colSpan="2"></td></tr>
                                              <tr><td align="right"><strong>IP Address </strong></td><td>{h.peer.ip}</td>
                                                <td align="right"><strong>IP Address </strong></td><td>{h.peer.pager.ip}</td></tr>
                                              <tr><td align="right"><strong>UDP Port </strong></td><td>{h.peer.port}</td>
                                                <td align="right"><strong>UDP Port </strong></td><td>{h.peer.pager.port}</td></tr>

                                              <tr><td align="right"><strong>API Key </strong></td><td><div className="btn-group">{h.peer.token &&
                                                <Button onClick={() => { copyAPIToClipboard(h.peer.token) }} size="sm" variant="success">Copy</Button>
                                              }
                                                <ButtonSure onClick={() => updatePeerKey(h.peer)} size="sm" variant="danger" actionName="New Key">Creating a new key will invalidate the previous one</ButtonSure>
                                              </div></td></tr>
                                            </tbody>
                                          </Table>
                                        </td></tr>

                                        <tr><td align="right"><strong>Notify When off-line</strong></td><td><ToggleSwitch id={`notify-${h.peer.id}`} checked={h.peer.notify} onChange={(e) => updatePeer(h.peer.id, { notify: e })} /></td></tr>
                                        <tr><td align="right"><strong>Blocked</strong></td><td><ToggleSwitch id={`blocked-${h.peer.id}`} disabled={!isadmin()} checked={h.peer.blocked} onChange={(e) => blockedToggle(h.peer.id)} /></td></tr>
                                      </tbody>
                                    </Table>
                                  </Row>
                                  <Row>
                                    <ButtonGroup>
                                      <Button onClick={() => handleShowConfiguration(h.peer)} variant="success">Hotspot Configuration</Button>
                                      <ButtonSure
                                        onClick={() => deletePeerButton(h.peer.id)}
                                        actionName={<FontAwesomeIcon fixedWidth icon={faTrash} />}
                                        tooltip="Delete Hotspot"
                                      >
                                        <p>Deleting this hotspot will prevent further communications from the physical device.</p>
                                        <p>Rember to remove the configuration from your physical hotspot device as well.</p>
                                      </ButtonSure>
                                    </ButtonGroup>
                                  </Row>
                                </Col>
                              </Row>
                            </Container>
                            <Container>
                              <h1>Static Talkgroups</h1>
                              <Row className="justify-content-md-center">
                                <Col md={8}><StaticTalkgroupList groups={memberships} peer={h.peer} setPeer={setPeer} /></Col>
                              </Row>
                              <StaticTalkgroups static_talkgroups={h.peer.static_talkgroups} setStatic_talkgroups={setStatic_talkgroups} getUser={getUser} duplex={h.peer.duplex} />
                            </Container>
                            <Container>
                              <h1>Dynamic Talkgroups</h1>
                              <DynamicTalkgroups dynamic_talkgroups={h.peer.dynamic_talkgroups} setDynamic_talkgroups={setDynamic_talkgroups} getUser={getUser} />
                            </Container>
                          </Panel>
                        </td>
                      </tr>
                    </>
                  }
                </>
              </React.Fragment>
            ))
            }</>
          }
        </tbody>
      </Table>
      {
        thisPeer &&
        <Modal dialogClassName="peer-config-modal" show={showConfiguration} onHide={handleCloseConfiguration}>
          <Modal.Header closeButton>
            <Modal.Title>{repeaterkeeper ? 'Repeater' : 'Hotspot'} Configuration</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>For DMR, copy the lines on the left side below and paste at the bottom of the "DMR GW" entries under Configuration, Expert on your pi-star.</p>
            <p>For Paging, use the configuration lines on the right.</p>
            <p>Only Hotspots using DMRID's registered will be permitted access.</p>
            <p><Button variant="danger" onClick={() => updatePeerPassword(thisPeer)}>Generate New Password</Button></p>
            <table>
              <tbody>
              <tr>
                <td><strong>HostSpot Configuration</strong></td><td><strong>Pager Configuration</strong></td>
              </tr>
              <tr>
                <td>
                  <pre>{`
[DMR Network 4]
Enabled=1
Name=RAYNET-UK DMR
Address=raynet.m0guy.com
Port=62030
Password=${thisPeer.peer_password}
Debug=0
Id=${thisPeer.id}
TGRewrite0=1,5000000,1,5000000,99999
TGRewrite1=2,5000000,2,5000000,99999
PCRewrite0=1,5075599,1,5075599,1
PCRewrite1=2,5075599,2,5075599,1
Location=1
            `}</pre>
                </td>
                <td><pre>{`
{
  "DAPNET_ENABLED": false,
  "DAPNET_HOST": "",
  "DAPNET_CALLSIGN": "${thisPeer.callsign}",
  "DAPNET_AUTHKEY": "",
  "RAYNET_ENABLED": true,
  "RAYNET_HOST": "raynet.m0guy.com",
  "RAYNET_GWID": ${thisPeer.id},
  "RAYNET_CALLSIGN": "${thisPeer.callsign}",
  "RAYNET_AUTHKEY": "${thisPeer.peer_password}",
  "LOCAL_TIME": true,
  "TIMEZONE": "Europe/London",
  "MY_RIC": "01234567",
  "FREQUENCY": ""
}

                `}</pre>
                </td>
              </tr>
              </tbody>
            </table>
          </Modal.Body>
        </Modal>
      }

    </Container >
  )
}


export default Hotspots;

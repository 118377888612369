import React, { useState, useEffect } from "react";
import Table from "../../components/Table/Table";
import axios from "axios";
import LoadingSpinner from "../../components/Helper/LoadingSpinner";
import GroupAdmins from "../../components/CellRenderers/RaynetGroups/GroupAdmins";
import Container from "../../components/Layout/Container";
import Panel from "../../components/Panel/Panel";

function RaynetGroups({setPageTitle}) {
  const [groups, setGroups] = useState(null);

  setPageTitle("Groups");

  const getGroups = () => {
    axios
      .get(`${process.env.REACT_APP_API}/api/v1/raynet_groups.json`)
      .then(({ data }) => {
        setGroups(data.raynet_groups);
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    getGroups();
  }, []);

  if (!groups) { return ( <LoadingSpinner title="Raynet DMR | Groups" /> ); }

  const gridOptions = {
    getRowId(data) {
      return data.data.id;
    },
    paginationPageSize: 30,
    rowHeight: 50,
    columnDefs: [
      {
        headerName: "Name",
        field: "name",
        sort: "asc",
        cellRenderer: function(params) {
          return (<a href={`/group/${params.data.id}`}>{params.value}</a>)
        }
      }, {
        headerName: "Group Code",
        field: "groupcode",
      }, {
        headerName: "Number",
        field: "number",
      }, {
        headerName: "Member Count",
        field: "user_count",
      }, {
        headerName: "Prefix",
        field: "prefix",
      }, {
        headerName: "Group Controllers",
        field: "group_admins",
        filter: false,
        cellRenderer: GroupAdmins
      }
    ]
  }

  return (
    <div>
      <Container>
        <Panel>
          <Table
            gridOptions={gridOptions}
            rowData={groups}
          />
          </Panel>
      </Container>
    </div>
  );

}

export default RaynetGroups;

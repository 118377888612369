import React from "react";
import Moment from 'react-moment';


function DisplayTime(props) {

  if (props.value) {
    return (
      <Moment format={JSON.parse(localStorage.getItem('user')).timeformat } 
              tz={JSON.parse(localStorage.getItem('user')).timezone}>
        { props.value }
      </Moment>
    )
  }
}

export default DisplayTime;
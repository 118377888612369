import React from "react";
import moment from "moment";
import "./Dashboard.css";
import Table from "../../components/Table/Table";
import DisplayName from "../../components/CellRenderers/DisplayName";
import DisplayTime from "../../components/CellRenderers/DisplayTime";
import DisplayCallsign from "../../components/CellRenderers/DisplayCallsign";

import LoadingSpinner from "../../components/Helper/LoadingSpinner";

function OfflinePeers({ offlinePeers }) {

  if (!offlinePeers) { return (<LoadingSpinner title={`Raynet DMR | Dashboard`} />); }

  const gridOptions = {
    getRowId(data) {
      return data.data.peer.id;
    },
    paginationPageSize: 30,
    columnDefs: [
      {
        headerName: "Callsign (DMR ID)",
        field: "peer",
        sortable: false,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains'],
          textFormatter: (d) => { 
            return d.callsign ? `${d.callsign} ${d.id}`.toString().toLowerCase() : d.toString().toLowerCase()
          },
        },
        cellRenderer: DisplayCallsign
      }, {
        headerName: "Name",
        field: "peer",
        sortable: false,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains'],
          textFormatter: (d) => {
            return d.name ? d.name.toString().toLowerCase() : d
          },
        },
        cellRenderer: DisplayName
      }, {
        headerName: `Time (${moment.tz(JSON.parse(localStorage.getItem('user')).timezone).zoneAbbr()})`,
        field: "peer.last_ping",
        sort: "desc",
        cellRenderer: DisplayTime
      }, {
        headerName: "Offline Duration",
        cellRenderer: function (params) {
          return `${moment.utc(params.data.peer.last_ping).fromNow()}`
        }
      }, {
        headerName: "Notified",
        field: "peer.notified",
        cellRenderer: function (params) {
          return (params.value ? 'YES' : 'NO')
        }
      }
    ]
  }

  return (
    <div>
      <Table
        gridOptions={gridOptions}
        rowData={offlinePeers}
      />
    </div>
  );
}

export default OfflinePeers;

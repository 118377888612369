import React, { useState } from "react";

import AddByCallsign from "../../components/Pages/Users/AddByCallsign";
import RegisterByEmail from "../../components/Pages/Users/RegisterByEmail";
import WaitingEnrolment from "../../components/Pages/Users/WaitingEnrolment";
import Panel from "../../components/Panel/Panel";
import { Row, Col } from "react-bootstrap";

function UserEnrolment({ tokens, groupid, setTokens, getTokens }) {

  return (
    <>
        <Row>
          <Col>
            <RegisterByEmail groupid={groupid} getTokens={getTokens} />
          </Col>
          <Col>
            <AddByCallsign groupid={groupid} />
          </Col>
        </Row>
        <WaitingEnrolment tokens={tokens} setTokens={setTokens} groupid={groupid} />      
    </>
  );

}

export default UserEnrolment;
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Row, Col, Table, Tabs, Tab, Button, Modal } from "react-bootstrap";
import axios from "axios";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../components/Helper/LoadingSpinner";
import isadmin from "../../utils/isadmin";
import moment from "moment-timezone";
import Lastheard from "../../components/Pages/Talkgroups/Lastheard";
import DisplayGroupname from "../../components/Helper/DisplayGroupname";
import ToggleSwitch from "../../components/ToggleSwitch/ToggleSwitch";
import Acls from "../../components/Pages/Talkgroups/acl";
import StaticUsers from "../../components/Pages/Talkgroups/StaticUsers";
import DynamicUsers from "../../components/Pages/Talkgroups/DynamicUsers";
import isadminfor from "../../utils/isadminfor";
import toast from "react-hot-toast";
import Container from "../../components/Layout/Container";
import Panel from "../../components/Panel/Panel";
import Callogs from "../../components/Pages/Talkgroups/Calllog";
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css';

import dur from "../../utils/duration";

function ShowTalkgroup({ setPageTitle, cable }) {
  const [talkgroup, _setTalkgroup] = useState(null);
  const [lastheard, _setLastheard] = useState();
  const [lastUpdated, setLastUpdated] = useState(moment());
  const [show, setShow] = useState(false);
  const [talkgroupName, setTalkgroupName] = useState();
  const [thisTalkgroup, setThisTalkgroup] = useState();
  const [acls, setAcls] = useState();
  const [msg, setMsg] = useState();

  const lastheardRef = React.createRef();
  const talkgroupRef = React.createRef();

  const handleClose = () => setShow(false);
  const handleShow = (talkgroup) => {
    setThisTalkgroup(talkgroup);
    setTalkgroupName('');
    setShow(true);
  }

  let { id } = useParams();

  const setLastheard = data => {
    lastheardRef.current = data;
    _setLastheard(data);
  };
  const setTalkgroup = data => {
    talkgroupRef.current = data;
    _setTalkgroup(data);
  };

  
  const updateTalkgroup = (talkgroupobj, talkgroup = {}) => {
    let updateTG =
      axios.post(`${process.env.REACT_APP_API}/api/v1/talkgroup/${talkgroupobj.id}.json`, {
        talkgroup
      })
        .then(({ data }) => {
          setShow(false);
          getTalkgroup();
        })
    toast.promise(updateTG, {
      loading: "Sending update...",
      success: "Talkgroup Updated",
      error: "Could not update Talkgroup",
    });
  }

  const getTalkgroup = () => {
    axios
      .get(`${process.env.REACT_APP_API}/api/v1/talkgroup/${id}.json`)
      .then(({ data }) => {
        setTalkgroup(data.talkgroup);
        setLastheard(data.talkgroup.lastheard);
        setAcls(data.talkgroup.acls);
        setPageTitle(data.talkgroup.talkgroup.name)
      })
      .then(() => setLastUpdated(moment()))
      .catch((error) => console.error(error));
  }

  function GetIndexOfLatestDate(lh) {
    if (lh === null || lh.length === 0) {
      return null;
    }
    return lh.reduce((accum, value, index) => {
      const newDate = value.starttime;
      return newDate < accum.maxDate ? { index, maxDate: newDate } : accum;
    }, { index: 0, maxDate: lh[0].starttime }).index;
  }

  function updateLastheardInfo(_msg) {
    if ((talkgroup || talkgroupRef.current) && (lastheard || lastheardRef.current)) {
      let _lastheard = lastheardRef.current ? [...lastheardRef.current] : [...lastheard]
      for (let i = 0; i < _msg.length; i++) {
        if (_msg[i].talkgroup.id === parseInt(id, 10)) {
          setMsg(_msg)
          let mi = _lastheard.findIndex(l => l.id === _msg[i].id);
          if (mi === -1) {
            const last = GetIndexOfLatestDate(_lastheard)
            _lastheard[last] = _msg[i];
          } else {
            _lastheard[mi] = _msg[i];
          }
        }
      }
      setLastheard(_lastheard)
      let _tg = talkgroup ? talkgroup : talkgroupRef.current
      _tg.lastheard = _lastheard
      setTalkgroup(_tg)
    }
  }

  useEffect(() => {
    getTalkgroup();
    cable.subscriptions.create({ channel: `LastheardChannel` }, {
      received: (message) => updateLastheardInfo([JSON.parse(message.l)])
    })
  }, [cable.subscriptions]);

  useEffect(() => {
    const checkTimeSinceLastUpdate = () => {
      let now = moment();
      let duration = moment.duration(now.diff(lastUpdated));

      return parseInt(duration.asSeconds());
    };
    let refreshInterval = setInterval(() => {
      if (checkTimeSinceLastUpdate() >= 30) {
        getTalkgroup();
      }
    }, 100000);
    return () => clearInterval(refreshInterval);;
  });

  function refreshLastheardDuration(_lastheard) {
    if (_lastheard) {
      let tmplastheard = [..._lastheard];
      for (let i = 0; i < tmplastheard.length; i++) {
        if (!tmplastheard[i].endtime) {
          let entry = { ...tmplastheard[i] }
          entry.duration = dur(entry.starttime, new Date())
          tmplastheard[i] = entry
          setLastheard(tmplastheard);
        }
      }
    }
  };

  useEffect(() => {
    let refreshInterval = setInterval(() => {
      refreshLastheardDuration(lastheard);
    }, 1000);
    return () => clearInterval(refreshInterval);
  });


  if (!talkgroup) { return (<LoadingSpinner title={`Raynet DMR | Talkgroup - ${id}`} />); }

  return (
    <div>
      <Helmet title="Raynet DMR | Talkgroup" />
      <Container>
        <Panel>
          <h1>
            {talkgroup.talkgroup.tg_full_name}{' '}{isadmin() ? <Button onClick={() => handleShow(talkgroup)}>Rename</Button> : ''}
          </h1>

          <Row>
            <Col>
              <Table borderless>
                <thead>
                  <tr>
                    <th colSpan="2" className="text-center">Talkgroup Details</th>
                  </tr>
                </thead>
                <tbody>
                  <tr><td align="right"><strong>Group</strong></td><td><DisplayGroupname group={talkgroup.talkgroup.raynet_group} /></td></tr>
                  <tr><td align="right"><strong>Talkgroup Number</strong></td><td>{talkgroup.talkgroup.number}</td></tr>

                  <tr><td align="right">
                    <Tippy content="Enable/Disable talkgroup. When disabled no traffic will be passed on this talkgoup." >
                      <strong>Active</strong>
                    </Tippy></td><td>
                      <ToggleSwitch checked={talkgroup.talkgroup.active} id={`active`}
                        disabled={isadminfor(talkgroup.talkgroup.raynet_group.id) ? false : true}
                        onChange={(e) => updateTalkgroup(talkgroup.talkgroup, { active: e })} />
                    </td></tr>
                  <tr><td align="right">
                    <Tippy content="Set the Default ACLs for this talkgroup" >
                      <strong>Default ACL</strong>
                    </Tippy></td><td>
                      <div className="btn-group">
                        <Button size="sm" className={`btn btn-xs btn-${talkgroup.talkgroup.tx ? 'success' : 'danger'}`}
                          disabled={isadminfor(talkgroup.talkgroup.raynet_group.id) && !talkgroup.talkgroup.closed ? false : true}
                          onClick={() => updateTalkgroup(talkgroup.talkgroup, { tx: !talkgroup.talkgroup.tx })}
                        >TX</Button>
                        <Button size="sm" className={`btn btn-xs btn-${talkgroup.talkgroup.rx ? 'success' : 'danger'}`}
                          disabled={isadminfor(talkgroup.talkgroup.raynet_group.id) && !talkgroup.talkgroup.closed ? false : true}
                          onClick={() => updateTalkgroup(talkgroup.talkgroup, { rx: !talkgroup.talkgroup.rx })}
                        >RX</Button>
                      </div>

                    </td></tr>
                  <tr><td align="right">
                    <Tippy content="Lock Talkgroup. Set Default ACLs to disable TX and RX. Add all current members of the group to the ACL list." >
                      <strong>Locked</strong>
                    </Tippy></td><td>
                      <ToggleSwitch checked={talkgroup.talkgroup.closed}
                        disabled={isadminfor(talkgroup.talkgroup.raynet_group.id) ? false : true}
                        id={`closed`} onChange={(e) => updateTalkgroup(talkgroup.talkgroup, { closed: e })} />
                    </td></tr>
                  {talkgroup.talkgroup.closed &&
                    <tr><td align="right">
                      <Tippy content="Automatically add new members of the group to the ACL list and give permission to TX and RX." >
                        <strong>Auto ACL</strong>
                      </Tippy>
                    </td><td>
                        <ToggleSwitch checked={talkgroup.talkgroup.autoacl}
                          disabled={isadminfor(talkgroup.talkgroup.raynet_group.id) ? false : true}
                          id={`autoacl`} onChange={(e) => updateTalkgroup(talkgroup.talkgroup, { autoacl: e })} />
                      </td></tr>
                  }
                  <tr><td align="right">
                    <Tippy content="Permit traffic from non Raynet Members." >
                      <strong>Public Access</strong>
                    </Tippy></td><td>
                      <ToggleSwitch checked={talkgroup.talkgroup.allowall}
                        disabled={isadminfor(talkgroup.talkgroup.raynet_group.id) ? false : true}
                        id={`allowall`} onChange={(e) => updateTalkgroup(talkgroup.talkgroup, { allowall: e })} />
                    </td></tr>
                </tbody>
              </Table>
            </Col>
            <Col>
              <Table borderless>
                <thead><tr><th colSpan="2" className="text-center">Talkgroup Net Schedule</th></tr></thead>
                <tbody>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Panel>
      </Container>
      <Container><Panel>
        <Tabs defaultActiveKey="Lastheard" id="talkgrop-tab" className="">
          <Tab eventKey="Lastheard" title="Lastheard"><Panel><Lastheard lastheard={lastheard} /></Panel></Tab>
          {isadminfor(talkgroup.talkgroup.raynet_group.id) ? <Tab eventKey="ACL" title="ACL"><Panel><Acls acls={acls} talkgroup={talkgroup.talkgroup} setAcls={setAcls} /></Panel></Tab> : ''}
          {isadminfor(talkgroup.talkgroup.raynet_group.id) ? <Tab eventKey="StaticUsers" title="Static Users"><Panel><StaticUsers staticUsers={talkgroup.static_users} /></Panel></Tab> : ''}
          {isadminfor(talkgroup.talkgroup.raynet_group.id) ? <Tab eventKey="Dynamic Users" title="Dynamic Users"><Panel><DynamicUsers dynamicUsers={talkgroup.dynamic_users} /></Panel></Tab> : ''}
          <Tab eventKey="CallLog" title="Call Log"><Panel><Callogs id={talkgroup.talkgroup.id} msg={msg} /></Panel></Tab>
          <Tab eventKey="Net" title="Net"><Panel></Panel></Tab>
        </Tabs>
      </Panel></Container>
      {thisTalkgroup ?
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Talkgroup Name</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>You can change the talkgroup name. It is suggested to make the name short but meaningful. Different radios support different lengths.</p>
            <input value={talkgroupName ? talkgroupName : thisTalkgroup.talkgroup.name} onChange={(e) => setTalkgroupName(e.target.value)} />{' '}
            <Button onClick={() => updateTalkgroup(thisTalkgroup.talkgroup, { name: talkgroupName })}>Update Talkgroup</Button>
          </Modal.Body>
        </Modal>
        : ''}

    </div>
  );
}

export default ShowTalkgroup;

import React, { useState } from "react";
import UserEnrolment from "../Users/UserEnrolment";
import Table from "../../components/Table/Table";
import Panel from "../../components/Panel/Panel";
import LoadingSpinner from "../../components/Helper/LoadingSpinner";
import ToggleSwitch from "../../components/ToggleSwitch/ToggleSwitch";
import isadmin from "../../utils/isadmin";
import isadminfor from "../../utils/isadminfor";
import axios from "axios";
import toast from "react-hot-toast";

function Users({users, tokens, groupid, updateUsers, setTokens, getTokens }) {
  const groupAdminButton = (user_id) => {
    let groupAdmin =
      axios.post(`${process.env.REACT_APP_API}/api/v1/user/${user_id}/group_admin.json`, {
        user_id: user_id,
        group_id: groupid,
      })
        .then(({ data }) => {
          let tmpusers = JSON.parse(JSON.stringify(users));
          let rg = data.user.memberships.filter(m => m.raynet_group.id === groupid)[0]
          if (rg) {
            let index = tmpusers.findIndex(u => u.id === user_id)
            tmpusers[index].group_admin = rg.raynet_group.admin
          }
          updateUsers(tmpusers);
          users = tmpusers;
        })
    toast.promise(groupAdmin, {
      loading: "Toggling Group Admin...",
      success: "Group Admin Set",
      error: "Could not set Group Admin",
    });
  }

  const groupEnableButton = (user_id) => {
    let groupEnable =
      axios.post(`${process.env.REACT_APP_API}/api/v1/user/${user_id}/group_toggle.json`, {
        group_id: groupid,
      })
        .then(({ data }) => {
          let tmpusers = JSON.parse(JSON.stringify(users));
          let rg = data.user.memberships.filter(m => m.raynet_group.id === groupid)[0]
          if (rg) {
            let index = tmpusers.findIndex(u => u.id === user_id)
            tmpusers[index].group_active = rg.raynet_group.active
          }
          updateUsers(tmpusers);
          users = tmpusers;
        })
    toast.promise(groupEnable, {
      loading: "Toggling Group...",
      success: "Group Membership set",
      error: "Could not set Group Membership",
    });
  }

  const gridOptions = {
    getRowId(data) {
      return data.data.id;
    },
    paginationPageSize: 30,
    rowHeight: 50,
    columnDefs: [
      {
        headerName: "Name",
        width: 250,
        field: "name",
        sort: "asc",
        cellRenderer: function(params) {
          return (<a href={`/user/${params.data.id}`}>{params.value}</a>)
        }
      }, {
        headerName: "Callsign",
        field: "callsigns",
      }, {
        headerName: "E-Mail",
        field: "email",
        width: 250,
        cellRenderer: function(params) {
          return (<a href={`mailto:${params.value}`}>{params.value}</a>)
        }
      }, {
        headerName: "Active",
        field: "active",
        cellRenderer: function(params) {
          return (<ToggleSwitch id={`active-${params.data.id}`} 
            checked={ params.value }
            disabled={ true }
            onChange={(e) => {}}
            />)
        }
      }, {
        headerName: "Group Active",
        field: "group_active",
        cellRenderer: function(params) {
          return (<ToggleSwitch id={`group_active-${params.data.id}`} 
            checked={params.value}
            disabled={isadminfor(groupid) ? false : true }
            onChange={(e) => groupEnableButton(params.data.id)}
            />)
        }
      }, {
        headerName: "Group Admin",
        field: "group_admin",
        cellRenderer: function(params) {
          return (<ToggleSwitch id={`group_admin-${params.data.id}`}
            variant={params.value ? 'warning' : 'danger'}
            checked={params.value}
            disabled={isadmin() ? false : true }
            onChange={(e) => groupAdminButton(params.data.id)}
            />)
        }

      }
    ]
  }

  if (!users) { return ( <LoadingSpinner /> ); }

  return (
    <div className="container-fluid">
      <UserEnrolment tokens={tokens} groupid={groupid} setTokens={setTokens} getTokens={getTokens} />
      <Panel>
        <h3>Registered Users</h3>
          <Table
            gridOptions={gridOptions}
            rowData={users}
          />
      </Panel>

    </div>
  );

}

export default Users;
import Table from "../../components/Table/Table";
import Panel from "../../components/Panel/Panel";
import { Container } from "react-bootstrap";

function Radioids({radioids}) {

  const gridOptions = {
    getRowId(data) {
      return data.data.id;
    },
    paginationPageSize: 30,
    columnDefs: [
      {
        headerName: "Radio ID",
        field: "id",
      }, {
        headerName: "Firstname",
        field: "fname",
      }, {
        headerName: "Surname",
        field: "surname",
      }, {
        headerName: "Callsign",
        field: "callsign",
      }, {
        headerName: "Country",
        field: "country",
      }, {
        headerName: "State",
        field: "state",
      }, {
        headerName: "Remarks",
        field: "remarks",
      }
    ]
  }

  return (
    <Container>
      <Panel>
        <h1>Public DMRIDs</h1>
          <Table
            gridOptions={gridOptions}
            rowData={radioids}
          />
          </Panel>
    </Container>
  );
}

export default Radioids;
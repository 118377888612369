import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";


function ButtonSure(props) {
    const [modalShow, setModalShow] = useState(false);

    function doaction() {
        setModalShow(false);
        props.onClick();
    }

    return (
        <>
            <Tippy content={props.tooltip}>
                <Button variant={props.variant}
                    size={props.size}
                    disabled={props.disabled}
                    onClick={() => setModalShow(true)}
                >{props.actionName === "Delete"
                    ? <FontAwesomeIcon
                        icon={faTrash}
                    />
                    : props.actionName
                    }
                </Button>
            </Tippy>
            <Modal
                onHide={() => setModalShow(false)}
                show={modalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Are You Sure?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.message ? props.message : props.children}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModalShow(false)}>Cancel</Button>
                    <Button variant={props.variant} onClick={() => doaction()}>{props.actionName}</Button>
                </Modal.Footer>

            </Modal>
        </>
    )
}

ButtonSure.defaultProps = {
    disabled: false,
    variant: "danger",
    size: "sm",
    tooltip: "tool tip missing",
}

export default ButtonSure;
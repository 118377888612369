import React, { useState } from "react";
import toast from "react-hot-toast";
import ToastComponent from "../../components/Toast/ToastComponent";
import axios from "axios";
import { Button, Col, Row, Container, Form } from "react-bootstrap"
import PasswordStrengthBar from 'react-password-strength-bar';
import logo from "../../images/Raynet-logo.png";
import bg from "../../images/RAYNET-login-bg.png";
import "./Register.css";

function PasswordReset(props) {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('reset_password_token');
  const [password1, setPassword1] = useState();
  const [password2, setPassword2] = useState();
  const [updated, setUpdated] = useState(false);

  document.body.style.backgroundImage = `url(${bg}`;

  const submitForm = () => {
    let resetPromise = axios
      .patch(`${process.env.REACT_APP_API}/users/password.json`, {
        user:
        {
          password: password1,
          password_confirmation: password2,
          reset_password_token: token,
        }
      })
      .then(({ data }) => {
        setUpdated(true)
      });
    toast.promise(resetPromise, {
      loading: "Updating Password...",
      success: "Password Reset",
      error: "Failed to update Password",
    });
  };

  function detailscheck() {
    let disabled = true;
    if (password1 && password2) {
      if (password1 && password2 && password1 === password2 && password1.length > 9) {
        disabled = false;
      }
    }
    return disabled;
  }
  function passwordok() {
    if (password1 && password2 && password1 !== password2) {
      return 'Password must match'
    } else if (password1 && password1.length < 10) {
      return 'Passwords need to be over 10 characters in length'
    } else {
      return ''
    }
  }

  if (updated) {
    return (
      <div className="main-password">
        <ToastComponent />
        <Container>
          <img src={logo} className="border-radius-20 center-img" width="100" height="100" alt="Raynet-UK DMR Logo" />
          <p className="sign" align="center">Raynet-UK DMR</p>
          <p className="notes"> Your Password has been changed</p>
          <p className="notes">Please login</p>
          <Button onClick={() => window.location = "/"}>Login</Button>
        </Container>
      </div>
    )
  }


  return (
    <div className="main-password">
      <ToastComponent />
      <Container>
        <img src={logo} className="border-radius-20 center-img" width="100" height="100" alt="Raynet-UK DMR Logo" />
        <p className="sign" align="center">Raynet-UK DMR</p>
        <p className="notes">Update password</p>
        <Form>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3" className="pass-Label">
              Password
            </Form.Label>
            <Col sm="9">
              <Form.Control type="password" onChange={(e) => setPassword1(e.target.value)} />
              <PasswordStrengthBar password={password1} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm="3" className="pass-Label">
              Confirm Password
            </Form.Label>
            <Col sm="9">
              <Form.Control type="password" onChange={(e) => setPassword2(e.target.value)} />
            </Col>
          </Form.Group>
          <p className="error" align="center">{passwordok()} </p>
          <div align="right"><Button disabled={detailscheck()} onClick={() => submitForm()}>Reset Password</Button></div>
        </Form>

      </Container>
    </div>
  );

}

export default PasswordReset;
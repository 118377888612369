import { Button, Modal } from "react-bootstrap";
import React, { useState } from "react";
import axios from "axios";
import isadminfor from "../../utils/isadminfor";
import Table from "../../components/Table/Table";
import ToggleSwitch from "../../components/ToggleSwitch/ToggleSwitch";
import toast from "react-hot-toast";
import LoadingSpinner from "../../components/Helper/LoadingSpinner";

function GroupTalkgroups({ talkgroups, groupid, updateTalkgroupDetails }) {
  const [talkgroupName, setTalkgroupName] = useState();
  const [thisTalkgroup, setThisTalkgroup] = useState();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (talkgroup) => {
    setThisTalkgroup(talkgroup);
    setTalkgroupName('');
    setShow(true);
  }

  const updateTalkgroup = (talkgroupobj, talkgroup={}) => {
    let updateTG =
      axios.post(`${process.env.REACT_APP_API}/api/v1/talkgroup/${talkgroupobj.id}.json`, {
        talkgroup
      })
      .then(({ data }) => {
        let tmptalkgroups = [...talkgroups];
        let index = tmptalkgroups.findIndex(t => t.talkgroup.id === talkgroupobj.id)
        tmptalkgroups[index] = data;
        updateTalkgroupDetails(tmptalkgroups);
        talkgroups = tmptalkgroups;
        setShow(false);
      })
      toast.promise(updateTG, {
        loading: "Sending update...",
        success: "Talkgroup Updated",
        error: "Could not update Talkgroup",
      });
  }

  if (!talkgroups) { return (<LoadingSpinner title="Raynet DMR | Group" />); }

  const gridOptions = {
    getRowId(data) {
      return data.data.talkgroup.id;
    },
    paginationPageSize: 30,
    rowHeight: 50,
    columnDefs: [
      {
        headerName: "Name",
        field: "talkgroup.name",
        cellRenderer: function (params) {
          return (<a href={`/talkgroup/${params.data.talkgroup.id}`}>{params.value}</a>)
        }
      }, {
        headerName: "Number",
        field: "talkgroup.number",
        sort: "asc",
      }, {
        headerName: "Enabled",
        field: "talkgroup.active",
        cellRenderer: function (params) {
          return <ToggleSwitch 
              id={`status-${params.rowIndex}`} 
              checked={params.value} 
              onChange={(e) => updateTalkgroup(params.data.talkgroup, {active: e})} 
              disabled={isadminfor(groupid) ? false : true}
              />
        }
      }, {
        headerName: "Global ACL",
        cellRenderer: function (params) {
          return (<>
            <div className="btn-group">
              <Button size="sm" className={`btn btn-xs btn-${params.data.talkgroup.tx ? 'success' : 'danger'}`} 
                disabled={isadminfor(groupid) && !params.data.talkgroup.closed ? false : true}
                onClick={() => updateTalkgroup(params.data.talkgroup, {tx: !params.data.talkgroup.tx})}
              >TX</Button>
              <Button size="sm" className={`btn btn-xs btn-${params.data.talkgroup.rx ? 'success' : 'danger'}`}
                disabled={isadminfor(groupid) && !params.data.talkgroup.closed ? false : true}
                onClick={() => updateTalkgroup(params.data.talkgroup, {rx: !params.data.talkgroup.rx})}
              >RX</Button>
            </div>
          </>)
        }
      }, {
        headerName: "User Level ACLs",
        field: "talkgroup.acls",
        cellRenderer: function (params) {
          return (params.value ? 'Yes' : 'None')
        }

      }, {
        headerName: "",
        cellRenderer: function (params) {
          return isadminfor(groupid)
            ? <div className="btn-group">
              <Button size="sm" className={`btn btn-xs btn-${params.data.talkgroup.closed ? 'danger' : 'success'}`}
                disabled={isadminfor(groupid) ? false : true}
                onClick={() => updateTalkgroup(params.data.talkgroup, {closed: !params.data.talkgroup.closed})}
              >{params.data.talkgroup.closed ? 'Closed' : 'Open'}</Button>
              <Button size="sm"
                disabled={isadminfor(groupid) ? false : true}
                onClick={() => handleShow(params.data.talkgroup, params.rowIndex)}
              >Edit</Button>
            </div>
            :
            ''
        }
      }
    ]
  }


  return (
    <div>
        <Table
          gridOptions={gridOptions}
          rowData={isadminfor(groupid) ? talkgroups : talkgroups.filter(t => t.talkgroup.active)}
        />

{thisTalkgroup ? 
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Talkgroup Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>You can change the talkgroup name. It is suggested to make the name short but meaningful. Different radios support different lengths.</p>
          <input value={talkgroupName ? talkgroupName : thisTalkgroup.name} onChange={(e) => setTalkgroupName(e.target.value)} />
          <Button onClick={() => updateTalkgroup(thisTalkgroup, {name: talkgroupName})}>Update Talkgroup</Button>
        </Modal.Body>
      </Modal>
: ''}
    </div>


  );

}

export default GroupTalkgroups;